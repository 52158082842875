import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppColumn } from "src/app/backend/app-column";
import { CellGroup } from "src/app/models/cell-groups/cell-group";
import { Health } from "src/app/models/healths/health";
import { Agent } from "src/app/models/profiles/agent";
import { Availability } from "src/app/models/profiles/availability";
import { CreateUserModel } from "src/app/models/profiles/create-user-model";
import { Service } from "src/app/models/services/service";
import { AddressForm } from "../../share/forms/address-form";

export class CreateUserForm {
    formGroup: FormGroup;
    healths: Health[];
    cellGroups: CellGroup[];
    services: Service[];
    addresses: AddressForm[];
    availabilities: Availability[];
    columns: AppColumn[];
    agents: Agent[];


    constructor() {
        this.formGroup = new FormGroup({
            memberId: new FormControl(null, [Validators.required]),
            username: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required]),
            center: new FormControl(null, [Validators.required]),
            chineseName: new FormControl(null, [Validators.required]),
            englishName: new FormControl(null, [Validators.required]),
            gender: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
            smartPhone: new FormControl(false),
            phoneModel: new FormControl(null),
            birthday: new FormControl(null, [Validators.required]),
            simCard: new FormControl(null, [Validators.required]),
            literacy: new FormControl(null, [Validators.required]),
            educationLevel: new FormControl(null, [Validators.required]),
            contactPersonName: new FormControl(null),
            contactPersonPhone: new FormControl(null),
            remark: new FormControl(null) 
        });
        this.addresses = [new AddressForm()];
        this.healths = [];
        this.services = [];
        this.availabilities = [];
        this.cellGroups = [];
        this.agents = [];
        this.columns = [];
    }


    reset(): void {
        this.formGroup.reset();
        this.addresses = [new AddressForm()];
        this.healths = [];
        this.services = [];
        this.agents = [];
        this.cellGroups = [];
        this.availabilities = [];
        this.columns = [];
    }

    public getCreateUserModel(): CreateUserModel {
        let model: CreateUserModel = {
            username: this.formGroup.controls['username'].value,
            password: this.formGroup.controls['password'].value,
            memberId: this.formGroup.controls['memberId'].value.trim(),
            phoneNumber: this.formGroup.controls['phone'].value,
            profile: {
                chineseName: this.formGroup.controls['chineseName'].value,
                englishName: this.formGroup.controls['englishName'].value,
                dateOfBirth: (<Date>this.formGroup.controls['birthday'].value).toISOString(),
                gender: this.formGroup.controls['gender'].value,
                district: null,
                HKID: null,
                MarriageStatus: null,
                educationLevel: this.formGroup.controls['educationLevel'].value,
                literacy: this.formGroup.controls['literacy'].value,
                Career: null,
                Remark: this.formGroup.controls["remark"].value,
                HasSimCard: this.formGroup.controls['simCard'].value,
                MobilePhoneModel: this.formGroup.controls['phoneModel'].value,
                CenterCode: this.formGroup.controls['center'].value,
                addresses: this.addresses.map(address => address.getCreateAddressModel()),
                healthConditions: {
                    healthConditions: this.healths.map(health => health.uuId)
                },
                services: {
                    services: this.services.map(service => service.uuId)
                },
                availabilities: {
                    availabilities: this.availabilities
                },
                contactPersonName: this.formGroup.controls['contactPersonName'].value,
                contactPersonPhoneNumber: this.formGroup.controls['contactPersonPhone'].value,
                cellGroups: this.cellGroups.map(group => group.uuId),
                extraPersonJson: this.stringtifyColumns(this.columns),
            }
        }
        if (model.profile.addresses.length > 0) model.profile.addresses[0].isDefault = true;
        return model;
    }

    get valid(): boolean {
        return this.formGroup.valid && this.addresses.every(address => address.formGroup.valid);
    }

    addAddress(): void {
        this.addresses.push(new AddressForm());
    }

    protected stringtifyColumns(columns: AppColumn[]): string {
        var temp: any = {};
        columns.forEach(column => {
            temp[column.columnName] = column.input.value
        });
        return JSON.stringify(temp);
    }
}