<div class="tab-body">
    <div class="inline-flex-row align-items-center ml-4 text-lightgrey">
        <p translate>已選擇</p>
        <p class="text-lightblue ml-2">{{ selectedUsers.length }}</p>
        <p class="mr-2">/{{ vacancy }}</p>
        <p translate>位用戶參加該活動</p>

        <div class="flex-1"></div>

        <p class="text-red mr-8" *ngIf="selectedUsers.length >= vacancy">選擇人數已滿足，不可隨機抽選！</p>

        <button class="random-button" (click)="randomPick()" translate>隨機抽選</button>
    </div>

    <table class="user-table mt-8">
        <div class="user-table-row header">
            <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 4;">
                <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
            </div>
            <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                <p translate>會員編號</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>中文姓名</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>中心代碼</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                <p translate>性別</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                <p translate>年齡</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                <p translate>電話</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                <p translate>狀態</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>時分餘額</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>交易時分</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 13.1875rem;flex-grow: 19;">
                <p translate>操作</p>
            </div>
        </div>

        <cdk-virtual-scroll-viewport itemSize="36" style="height: 25rem;">
            <div class="user-table-row" *cdkVirtualFor="let user of users">
                <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 4;">
                    <input type="checkbox" [(ngModel)]="user.selected">
                </div>
                <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                    <p>{{ user.memberId }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{ user.chineseName }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{ user.center.centerCode }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                    <p>{{ user.gender | translate }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                    <p>{{ user.age }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                    <p>{{ user.phone }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                    <p class="text-yellow" *ngIf="user.state == 'Interested'" translate>接受邀請</p>
                    <p *ngIf="user.state == 'Unselected'" translate>未被選中</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{user.timeBalance}}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>-</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 13.1875rem;flex-grow: 19;">
                    <p class="disabled" translate>修改時分</p>
                    <p class="click-text-lightblue ml-8" (click)="remove(user.uuId)" translate>移除</p>
                    <p class="click-text-lightblue ml-8" [routerLink]="['/main/user/overview', user.uuId]" translate>檢視</p>
                </div>
            </div>

            <div class="user-table-row" *ngIf="users.length == 0">
                <div class="user-table-cell">
                    <p style="color: #4A4A4A;" translate>暫無接受邀請的用戶，請在等待回應的用戶中選擇</p>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </table>
</div>

<div class="footer">
    <button class="button outline" (click)="closeAction()" translate>返 回</button>
    <button class="button fill blue" style="margin-left: 11.5rem;" (click)="joinEvent()" translate>確認參加</button>
</div>