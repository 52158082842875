<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>後台設置</p>
        <p class="ml-4">/</p>
        <p class="text-grey ml-4" *ngIf="tab == 'data'" translate>資料設置</p>
        <p class="text-grey ml-4" *ngIf="tab == 'event'" translate>活動設置</p>
        <p class="text-grey ml-4" *ngIf="tab == 'organization'" translate>機構設置</p>
    </div>

    <p class="page-title mt-8" *ngIf="tab == 'data'" translate>資料設置</p>
    <p class="page-title mt-8" *ngIf="tab == 'event'" translate>活動設置</p>
    <p class="page-title mt-8" *ngIf="tab == 'organization'" translate>機構設置</p>

    <div class="container mt-5">
        <div class="container-header">
            <p class="container-header-tab" routerLink="organization" routerLinkActive="active" (click)="tab = 'organization'" translate>機構設置</p>
            <p class="container-header-tab" routerLink="data" routerLinkActive="active" (click)="tab = 'data'" translate>資料設置</p>
            <p class="container-header-tab" routerLink="event" routerLinkActive="active" (click)="tab = 'event'" translate>活動設置</p>
        </div>
        <div class="container-body">
            <div [@slide]="prepareRoute(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </div>
</div>