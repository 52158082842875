import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { defaultIfEmpty, finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Address } from 'src/app/models/addresses/address';
import { Room } from 'src/app/models/addresses/room';
import { EventType } from 'src/app/models/event-types/event-type';
import { Event } from 'src/app/models/events/event';
import { EventDay } from 'src/app/models/events/event-day';
import { Profile } from 'src/app/models/profiles/profile';
import { AddressForm } from 'src/app/modules/share/forms/address-form';
import { PortalAnimation } from 'src/app/portal-animation';
import { EventTypeService } from 'src/app/services/event-type/event-type.service';
import { EventService } from 'src/app/services/event/event.service';
import { ExportService } from 'src/app/services/export/export.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CreateEventDayForm } from '../../forms/create-event-day-form';

@Component({
  selector: 'app-event-detail-page',
  templateUrl: './event-detail-page.component.html',
  styleUrls: ['./event-detail-page.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class EventDetailPageComponent implements OnInit {

  profile!: Profile;
  event!: Event;
  eventTypes: EventType[] = [];
  lang: string = this.translateService.currentLang;

  toggle: boolean = true;
  minDate: Date = new Date();
  venueForms: AddressForm[] = [];
  newEventDayForm: CreateEventDayForm | null = null;

  NAaddress:Room=new Room("","");

  newCancelDetail: string="";

  constructor(
    private eventService: EventService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private popupMessageService: PopupMessageService,
    private eventTypeService: EventTypeService,
    private profileService: ProfileService,
    private exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
  }

  fetch(): void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      forkJoin({
        event: this.eventService.getEventByUUId(value.id),
        types: this.eventTypeService.getEventTypeList(),
        profile: this.profileService.getMyProfile(),
        NAaddress:this.eventService.getNAAddress()
      }).pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(v => {
          this.NAaddress=v.NAaddress;
          this.event = v.event;
          this.eventTypes = v.types;
          this.profile = v.profile;
        });
    });
  }

  terminate(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch()
      subscriber.unsubscribe();
    });

    this.route.params.subscribe(value => {
      this.popupMessageService.messageSignal.emit(PopupMessages.TerminateEventMessage(this.eventService.terminate(value.id, { CancelEventReason: this.event.cancelDetail })));
    })
  }
  saveCancelDetail():void{
   console.log(this.event.cancelDetail );

    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.eventService.terminate(value.id,
        { CancelEventReason: this.event.cancelDetail })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: () => {
            this.popupMessageService.messageSignal.emit(PopupMessages.UpdateEventSuccessMessage);
            this.fetch();
          }
        });
    })
  }
  endEvent(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.route.params.subscribe(value => {
      this.popupMessageService.messageSignal.emit(PopupMessages.EndEventMessage(this.eventService.complete(value.id)))
    });
  }

  addVenue(): void {
    this.venueForms.push(new AddressForm());
  }

  saveVenue(): void {
    if (this.venueForms.length == 0 || !this.venueForms.every(form => form.formGroup.valid)) {
      this.venueForms.forEach(form => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();

    forkJoin(this.venueForms.map(form => this.profileService.createAddress(this.profile?.uuId as string, form.getCreateAddressModel())))
      .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.venueForms = [];
        this.fetch();
      })
  }

  updateEvent(): void {
    if (this.event.form.formGroup.invalid || this.event.form.eventDays.some(ed => ed.formGroup.invalid)) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      this.event.form.formGroup.markAllAsTouched();
      this.event.form.eventDays.forEach(f => f.formGroup.markAllAsTouched());
      return;
    }

    if (this.event.form.eventDays.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.ZeroEventDayErrorMessage);
      return;
    }

    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.eventService.updateEvent(value.id, this.event.form.getUpdateEventModel())
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: () => {
            this.popupMessageService.messageSignal.emit(PopupMessages.UpdateEventSuccessMessage);
            this.fetch();
          }
        });
    })
  }

  dropVenue = (() => {
    this.venueForms = [];
  }).bind(this)

  addEventDay(): void {
    this.newEventDayForm = new CreateEventDayForm();
  }

  saveNewEventDay(): void {
    if (this.newEventDayForm != null)
      this.event.form.eventDays.push(this.newEventDayForm);
    this.newEventDayForm = null;
  }

  dropEventDay(index: number): void {
    if (this.event.form.eventDays.length == 1) {
      this.popupMessageService.messageSignal.emit(PopupMessages.DropEventDayFail)
      return;
    }
    this.event.form.eventDays.splice(index, 1);
  }

  reloadAction: () => void = (() => {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.eventService.getEventByUUId(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {
          this.event = value;
        });
    });
  }).bind(this)

  exportEvent(): void {
    this.exportService.exportEvent(this.event);
  }
  exportEventApplyOnly():void{
    this.exportService.exportEventApplyOnly(this.event);
  }

  get eventName(): AbstractControl {
    return this.event.form.formGroup.controls['eventName'];
  }

  get eventType(): AbstractControl {
    return this.event.form.formGroup.controls['eventType'];
  }

  get earning(): AbstractControl {
    return this.event.form.formGroup.controls['earning'];
  }

  get applyDeadline(): AbstractControl {
    return this.event.form.formGroup.controls['applyDeadline'];
  }

  get vacancy(): AbstractControl {
    return this.event.form.formGroup.controls['participantVacancy'];
  }

  get quota(): number {
    return this.event.participantVacancy - this.event.participants.filter(p => p.state == 'Confirmed').length;
  }

  get url(): AbstractControl {
    return this.event.form.formGroup.controls['url'];
  }

  get urlType(): AbstractControl {
    return this.event.form.formGroup.controls['urlType'];
  }

  openURL(): void {
    if (this.event.url == null)
      return;
    window.open(this.event.url);
  }

  openFormURL(): void {
    if (this.url.value == null)
      return;
    window.open(this.url.value);
  }

  editAddress(address: Address): void {
    this.loadingService.startLoading();
    this.profileService.editAddress(address.getUpdateAddressModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(() => this.fetch())
  }

  dropAddressAction = ((index: number) => {
    (this.profile as Profile).form.addresses[index-1].editing = false;
  }).bind(this);
}