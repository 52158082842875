import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { ISignUpSetting } from 'src/app/models/settings/sign-up-setting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    private httpService: HttpService
  ) { }

  GetSignUpSetting(): Observable<ISignUpSetting> {
    return this.httpService.get<ISignUpSetting>("settings/timebanksetting")
      .pipe(map(value => value.result));
  }

  SetSignUpSetting(setting: ISignUpSetting): Observable<any> {
    return this.httpService.post("settings/timebanksetting", setting);
  }

}
