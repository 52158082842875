import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAnimation } from 'src/app/portal-animation';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class SideNavComponent implements OnInit {

  role: string | null = "Staff";
  toggle: boolean = true;
  menu = {
    staff: false,
    user: false,
    announcement: false,
    system: false,
    donation: false,
    redeem: false,
    activity: false,
    trash: false
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('timebank_roles');
  }
}
