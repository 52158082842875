<div class="scheduler-box">
    <div class="scheduler-header">
        <p class="required flex-1" translate>日期規劃</p>
        <p class="click-text-blue" (click)="cancelAction()" translate>取消</p>
    </div>
    <form class="scheduler-body" [formGroup]="formGroup">
        <div class="input-row mt-0">
            <p class="title required" translate>舉辦週期</p>
            <div class="user-select">
                <select class="user-select" style="width: 4.5rem;" formControlName="type">
                    <option value="daily">每日</option>
                    <option value="weekly">每週</option>
                    <option value="monthly">每月</option>
                </select>
            </div>

            <ng-container *ngIf="type.value == 'weekly';then select_week_day"></ng-container>

            <ng-container *ngIf="type.value == 'monthly';then monthly_weekly"></ng-container>
        </div>

        <div class="input-row">
            <p class="title required" translate>舉辦時間</p>
            <app-time-picker [form]="start" [selfGenerateDate]="true"></app-time-picker>
        </div>

        <div class="input-row">
            <p class="title required" translate>結束時間</p>
            <app-time-picker [form]="end" [selfGenerateDate]="true"></app-time-picker>
        </div>

        <div class="input-row">
            <p class="title required" translate>開始日期</p>
            <input class="user-input md" type="text" [ngModel]="startDate.value | date: 'longDate': '': lang" (click)="picker.open()" [ngModelOptions]="{standalone: true}" placeholder="{{ '開始日期' | translate }}" readonly>
        </div>

        <div class="input-row">
            <p class="title required" translate>結束日期</p>
            <input class="user-input md" type="text" [ngModel]="endDate.value | date: 'longDate': '': lang" (click)="picker.open()" [ngModelOptions]="{standalone: true}" placeholder="{{ '結束日期' | translate }}" readonly>
        </div>

        <mat-date-range-input *ngIf="hasMinDate == true" style="display: none;" [rangePicker]="picker" [min]="minDate">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
        </mat-date-range-input>

        <mat-date-range-input *ngIf="hasMinDate == false" style="display: none;" [rangePicker]="picker">
            <input matStartDate formControlName="startDate">
            <input matEndDate formControlName="endDate">
        </mat-date-range-input>

        <mat-date-range-picker touchUi #picker></mat-date-range-picker>


        <ng-template #monthly_weekly>
            <ng-container *ngIf="selectByDate == false">
                <div class="user-select ml-4">
                    <select class="user-select" style="width: 5.5rem;" formControlName="ordinalWeek">
                        <option value=0 translate>第一週</option>
                        <option value=1 translate>第二週</option>
                        <option value=2 translate>第三週</option>
                        <option value=3 translate>第四週</option>
                    </select>
                </div>

                <ng-container *ngTemplateOutlet="select_week_day"></ng-container>
            </ng-container>

            <ng-container *ngIf="selectByDate == true">
                <div class="user-select ml-4">
                    <select class="user-select" style="width: 10rem;" formControlName="ordinalDay">
                        <option *ngFor="let a of arraycounter(31);let i = index" [value]="i+1">{{ '第日' | translate: {value: i+1 } }}</option>
                    </select>
                </div>
            </ng-container>

            <p class="click-text-blue ml-4" *ngIf="selectByDate == false" (click)="selectByDate = true" translate>由每月日期決定</p>
            <p class="click-text-blue ml-4" *ngIf="selectByDate == true" (click)="selectByDate = false" translate>由每月星期決定</p>
        </ng-template>

        <ng-template #select_week_day>
            <div tabindex="0" #week class="weekday-box ml-4" (focus)="toggle = true" (blur)="toggle = false">
                <p *ngIf="numberOfSelectedWeekDay == 0" translate>請選擇</p>
                <p *ngIf="numberOfSelectedWeekDay == 1" translate>{{ selectedWeekDay.name }}</p>
                <div class="inline-flex-row" *ngIf="numberOfSelectedWeekDay > 1 " translate>
                    <p translate>每週的</p>
                    <p class="ml-2 mr-2">{{ numberOfSelectedWeekDay }}</p>
                    <p translate>天</p>
                </div>
                <div class="weekday-dropdown" [@dropdown]="toggle ? 'on' : 'off'" (click)="$event.stopPropagation()">
                    <div class="inline-flex-column" style="padding: 0.5rem;">
                        <label class="mt-2 mr-2" *ngFor="let day of daysOfWeek" (click)="week.focus()">
                            <input type="checkbox" [(ngModel)]="day.selected" [ngModelOptions]="{standalone: true}">
                            <p class="flex-1 text-center">{{ day.name | translate }}</p>
                        </label>
                    </div>
                </div>
            </div>
        </ng-template>
    </form>
</div>

<button class="submit-button mt-4" (click)="confirm()" translate>確認日期規劃</button>