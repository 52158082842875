import { Component, Input, OnInit } from '@angular/core';
import { EventParticipant } from 'src/app/models/events/event-participant';

@Component({
  selector: 'app-event-detail-user-management-popup',
  templateUrl: './event-detail-user-management-popup.component.html',
  styleUrls: ['./event-detail-user-management-popup.component.scss']
})
export class EventDetailUserManagementPopupComponent implements OnInit {

  @Input() users: EventParticipant[] = [];
  @Input() reloadAction: () => void = () => { }
  @Input() format: string = "Staff"
  @Input() vacancy: number = 0;
  @Input() type: string = "Free";

  currentTabIndex: number = 0;
  display: boolean = false;

  filters: any = {
    searchInput: null
  }

  constructor() { }

  ngOnInit(): void {
  }

  closeAction: () => void = (() => {
    this.display = false;
  }).bind(this);

  changeTabAction: (index: number) => void = ((index: number) => {
    this.currentTabIndex = index;
  }).bind(this);

  searchAction: (value: string) => void = ((value: string) => {
    this.filters.searchInput = value;
  }).bind(this);


  open(): void {
    this.display = true;
  }

  get filteredUsers(): EventParticipant[] {
    return this.users.filter(user =>
    (this.filters.searchInput == null
      || user.chineseName.includes(this.filters.searchInput)
      || user.memberId.includes(this.filters.searchInput)))
  }

  get confirmedUsers(): EventParticipant[] {
    return this.filteredUsers.filter(user => user.state === 'Confirmed' || user.state === 'Absent');
  }

  get acceptedUsers(): EventParticipant[] {
    return this.filteredUsers.filter(user => user.state === 'Interested' || user.state === 'Unselected');
  }

  get pendingUsers(): EventParticipant[] {
    return this.filteredUsers.filter(user => user.state === 'Asked');
  }

  get rejectedUsers(): EventParticipant[] {
    return this.filteredUsers.filter(user => user.state === 'Rejected');
  }

}
