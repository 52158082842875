<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>獎勵兌換</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>獎勵詳情</p>
    </div>

    <p class="page-title mt-8" translate>獎勵詳情</p>

    <div class="container mt-5" *ngIf="product">
        <div class="container-header">
            <p class="flex-1" translate>{{ '記錄編號：' | translate: {value: product.id} }}</p>
            <p translate>{{ '創建時間：' | translate:{value: product.createdAt | date: 'yyyy-MM-dd HH:mm'} }}
        </div>

        <form class="container-body" [formGroup]="product.form.formGroup">
            <ng-container *ngTemplateOutlet="status_bar"></ng-container>

            <ng-container *ngTemplateOutlet="notice"></ng-container>

            <div class="input-row">
                <p class="title" matTooltip="{{ '項目狀態' | translate }}" [matTooltipPosition]="'above'" translate>項目狀態</p>
                <ng-container *ngIf="product.active">
                    <p class="status yellow text-sm mr-2 align-self-center" *ngIf="!product.isLaunched" translate>未發布</p>
                    <p class="status green text-sm mr-2 align-self-center" *ngIf="product.isLaunched && !product.isClosed" translate>進行中</p>
                    <p class="status red text-sm mr-2 align-self-center" *ngIf="product.isOutOfStock" translate>庫存不足</p>
                    <p class="status red text-sm mr-2 align-self-center" *ngIf="product.isPending" translate>等待抽籤</p>
                    <p class="status grey text-sm mr-2 align-self-center" *ngIf="product.isClosed" translate>已結束</p>
                </ng-container>

                <p class="status red text-sm mr-2 align-self-center" *ngIf="product.isPending" translate>等待抽籤</p>
                <p class="status grey text-sm mr-2 align-self-center" *ngIf="!product.active" translate>已下架</p>
                <p class="status blue text-sm align-self-center" *ngIf="product.isEdited" translate>已修改</p>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '獎勵標題' | translate }}" [matTooltipPosition]="'above'" translate>獎勵標題</p>
                <p *ngIf="editing == false || product.isLaunched; else product_name">{{ product.productName }}</p>

                <ng-template #product_name>
                    <input class="user-input xl" type="text" formControlName="productName" placeholder="{{ '請輸入獎勵項目標題' | translate }}">

                    <div class="zoomIn user-input-error" *ngIf="productName.touched && productName.errors">
                        <p class="error-text text-sm" *ngIf="productName.errors.required" translate>請輸入獎勵標題</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '先到先得' | translate }}" [matTooltipPosition]="'above'" translate>獎勵來源</p>
                <p *ngIf="editing == false || product.isLaunched;else product_brand">{{ product.brand }}</p>

                <ng-template #product_brand>
                    <input class="user-input xl" type="text" formControlName="brand" placeholder="{{ '請輸入來源（例如品牌，商家等）' | translate }}">

                    <div class="zoomIn user-input-error" *ngIf="brand.touched && brand.errors">
                        <p class="error-text text-sm" *ngIf="brand.errors.required" translate>請輸入獎勵來源</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '參與用戶' | translate }}" [matTooltipPosition]="'above'" translate>參與用戶</p>
                <p *ngIf="!product.isLaunched" translate>暫未發布，</p>
                <p *ngIf="product.stock == -1" translate>庫存充足</p>
                <ng-container *ngIf="product.stock != -1">
                    <p translate>庫存剩餘</p>
                    <p class="text-lightblue ml-2 mr-2">{{ product.stock }}</p>
                    <p translate>件</p>
                </ng-container>
                <p translate>，共有</p>
                <p class="text-lightblue ml-2 mr-2">{{ numberOfRedeemedUesrs }}</p>
                <p translate>位用戶已換領</p>
                <button type="button" class="button sm outline blue" style="margin-left: 1.5rem;" routerLink="management" translate>查看詳情</button>
            </div>

            <div class="input-row">
                <p class="title" matTooltip="{{ '宣傳圖片' | translate }}" [matTooltipPosition]="'above'" translate>宣傳圖片</p>
                <ng-container *ngIf="editing == false || product.isLaunched; else image_12345">
                    <p *ngIf="file == null" translate>沒有宣傳圖片</p>
                    <img class="image mr-4" [ngClass]="{hide: file == null}" #image>
                    <p>{{ filename }}</p>
                </ng-container>

                <ng-template #image_12345>
                    <button *ngIf="filename == null;else image_preview" type="button" class="button outline blue sm" (click)="image_input.open()" translate>上傳圖片</button>
                    <ng-template #image_preview>
                        <p *ngIf="file == null" translate>沒有宣傳圖片</p>
                        <img class="image mr-4" [ngClass]="{hide: file == null}" #image2>
                        <p class="mr-4">{{ filename }}</p>
                        <i class="material-icons-outlined click-text-lightblue" style="font-size: 1rem;" (click)="image_input.open(form.image)">edit</i>
                    </ng-template>
                </ng-template>

                <app-redeem-image-popup [action]="imageUploadAction" #image_input></app-redeem-image-popup>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '參與方式' | translate }}" [matTooltipPosition]="'above'" translate>參與方式</p>
                <ng-container *ngIf="editing == false || product.isLaunched; else format">
                    <p *ngIf="product.format == 'FirstComeFirstServed'" translate>先到先得</p>
                    <p *ngIf="product.format == 'StaffOpting'" translate>職員處理</p>
                </ng-container>

                <ng-template #format>
                    <label class="text-grey">
                        <input type="radio" formControlName="format" value="1">
                        <p class="ml-1" translate>先到先得</p>
                    </label>
                    <label class="text-grey ml-3">
                        <input type="radio" formControlName="format" value="0">
                        <p class="ml-1" translate>職員處理</p>
                    </label>

                    <div class="zoomIn input-error-text" *ngIf="format.touched && format.errors">
                        <p class="error-text text-sm" *ngIf="format.errors.required" translate>請輸入參與方式</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '兌換比率' | translate }}" [matTooltipPosition]="'above'" translate>兌換比率</p>
                <ng-container *ngIf="editing == false || product.isLaunched;else price">
                    <ng-container *ngIf="product.price > 0">
                        <p translate>單個獎勵須扣除</p>
                        <p class="text-blue ml-2 mr-2">{{ product.price }}</p>
                        <p translate>時分</p>
                    </ng-container>

                    <p *ngIf="product.price == 0" translate>免費</p>
                </ng-container>

                <ng-template #price>
                    <p class="text-grey" translate>單個獎勵須扣除</p>
                    <input class="user-input xxs ml-2 mr-2" type="number" formControlName="price">
                    <p class="text-grey mr-2" translate>時分</p>
                    <label class="text-grey">
                        <input class="mr-2" type="checkbox" formControlName="free">
                        <p translate>免費</p>
                    </label>

                    <div class="zoomIn user-input-error" *ngIf="price.touched && price.errors">
                        <p class="error-text text-sm" *ngIf="price.errors.required" translate>請輸入兌換比率</p>
                        <p class="error-text text-sm" *ngIf="price.errors.min" translate>無效兌換比率</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required" translate>庫存</p>
                <ng-container *ngIf="editing == false; else stock">
                    <p *ngIf="product.stock != -1" translate>{{ '件件' | translate: {value: product.stock} }}</p>
                    <p *ngIf="product.stock == -1" translate>庫存充足</p>
                </ng-container>
                <ng-template #stock>
                    <input class="user-input mr-2 xxs" type="number" formControlName="stock">
                    <p class="text-grey mr-2" translate>件</p>
                    <label class="text-grey">
                        <input class="mr-2" type="checkbox" formControlName="adequateStock">
                        <p translate>庫存充足</p>
                    </label>

                    <div class="zoomIn user-input-error" *ngIf="stock.touched && stock.errors">
                        <p class="error-text text-sm" *ngIf="stock.errors.required" translate>請輸入庫存</p>
                        <p class="error-text text-sm" *ngIf="stock.errors.min" translate>無效庫存</p>
                    </div>
                </ng-template>
            </div>


            <div class="input-row">
                <p class="title required" matTooltip="{{ '換領次限' | translate }}" [matTooltipPosition]="'above'" translate>換領次限</p>
                <ng-container *ngIf="editing == false; else limit">
                    <ng-container *ngIf="product.maximumQuantityPerPerson != -1">
                        <p translate>每個用戶最多可換領數量</p>
                        <p class="text-blue ml-2 mr-2">{{ product.maximumQuantityPerPerson }}</p>
                        <p translate>件</p>
                    </ng-container>

                    <p *ngIf="product.maximumQuantityPerPerson == -1" translate>不限次限</p>
                </ng-container>

                <ng-template #limit>
                    <p class="text-grey mr-1" translate>每個用戶最多可換領數量</p>
                    <input class="user-input mr-2" style="width: 4.3125rem;" type="number" formControlName="limit">
                    <label class="text-grey">
                        <input class="mr-2" type="checkbox" formControlName="noLimit">
                        <p translate>不限次限</p>
                    </label>

                    <div class="zoomIn input-error-text" *ngIf="limit.touched && limit.errors">
                        <p class="error-text text-sm" *ngIf="limit.errors.required" translate>請輸入換領次限</p>
                        <p class="error-text text-sm" *ngIf="limit.errors.min" translate>無效換領次限</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '發布時間' | translate }}" [matTooltipPosition]="'above'" translate>發布時間</p>
                <p *ngIf="editing == false || product.isLaunched; else launch">{{ product.launchDate | date : 'longDate' : '' : lang }} {{ product.launchDate | date : 'HH:mm' }}</p>
                <ng-template #launch>
                    <input matInput class="user-input" formControlName="launchDate" [matDatepicker]="picker2" (click)="picker2.open()" readonly placeholder="{{ '發布時間' | translate }}">

                    <app-time-picker [form]="launchDate" class="ml-9"></app-time-picker>

                    <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                    <label>
                        <input class="ml-4" type="checkbox" formControlName="launchImmediately">
                        <p class="ml-4" translate>即時發布</p>
                    </label>

                    <div class="user-input-error zoomIn" *ngIf="launchDate.touched && launchDate.errors">
                        <p *ngIf="closeDate.errors.required" translate>請輸入發布日期</p>
                        <p *ngIf="closeDate.errors.time" translate>請輸入發布時間</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '截止換領' | translate }}" [matTooltipPosition]="'above'" translate>截止換領</p>
                <ng-container *ngIf="editing == false;else close">
                    <p class="mr-4">{{ product.closeDate | date : 'longDate' : '' : lang }} {{ product.closeDate | date : 'HH:mm' }}</p>
                    <i class="info-icon material-icons-round" matTooltip="{{ '過了截止換領日期後會員不可再換領，但仍可在App中見到該項目，除非職員已下架該項目則該項目不可見' | translate }}" matTooltipPosition="above">error_outline</i>
                </ng-container>
                <ng-template #close>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row">
                            <input matInput class="user-input" formControlName="closeDate" [matDatepicker]="picker" (click)="picker.open()" readonly placeholder="{{ '截止換領' | translate }}">

                            <app-time-picker [form]="closeDate" class="ml-9"></app-time-picker>

                            <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
                        </div>

                        <div class="flex-row align-items-center mt-2">
                            <i class="info-icon material-icons-round mr-5">error_outline</i>
                            <p class="text-xs text-lightgrey" translate>過了截止換領日期後會員不可再換領，但仍可在App中見到該項目，除非職員已下架該項目則該項目不可見</p>
                        </div>

                        <div class="user-input-error zoomIn" *ngIf="closeDate.touched && closeDate.errors">
                            <p *ngIf="closeDate.errors.required" translate>請輸入截止換領日期</p>
                            <p *ngIf="closeDate.errors.time" translate>請輸入截止換領時間</p>
                            <p *ngIf="closeDate.errors.invalid" translate>截止換領必須在發布時間之後</p>
                        </div>
                    </div>
                </ng-template>
            </div>


            <div class="input-row">
                <p class="title align-self-start required" style="line-height: 2rem;" matTooltip="{{ '詳情介紹' | translate }}" [matTooltipPosition]="'above'" translate>詳情介紹</p>
                <p class="description" *ngIf="editing == false || product.isLaunched; else description">{{ product.description }}</p>
                <ng-template #description>
                    <textarea style="width: 22rem;height: 15.5rem;" formControlName="description" placeholder="{{ '請輸入最少一句話描述（例如獎勵介紹，規則及領取方式等）' | translate}}"></textarea>

                    <div class="input-error-text" *ngIf="description.touched && description.errors">
                        <p class="error-text" *ngIf="description.errors.required" translate>請輸入詳情介紹</p>
                    </div>
                </ng-template>
            </div>

            <div class="input-row mt-5">
                <p class="title align-self-start required" matTooltip="{{ '選擇中心' | translate }}" [matTooltipPosition]="'above'" translate>選擇中心</p>

                <ng-container *ngIf="editing == false">
                    <div class="inline-flex-column" *ngIf="product.centers.length > 0">
                        <div class="inline-flex-row align-items-center">
                            <p translate>已選擇</p>
                            <p class="ml-4 mr-4 text-lightblue">{{ product.centers.length}}</p>
                            <p translate>個中心</p>
                            <p class="text-lightgrey" translate>所選中心下所屬會員（包括後加入的新會員）都會見到該獎勵項目</p>
                        </div>
                        <div class="border-bottom mt-4 mb-5"></div>
                        <div class="flex-row align-items-center mt-2 mb-4" *ngFor="let center of product.centers">
                            <p style="flex: 0 0 3rem">{{ center.centerCode }}</p>
                            <p class="flex-1">{{ center.name }}</p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="editing">
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center">
                            <p translate>已選擇</p>
                            <p class="ml-4 mr-4 text-lightblue">{{ product.form.centers.length}}</p>
                            <p translate>個中心</p>
                            <p class="text-lightgrey" translate>所選中心下所屬會員（包括後加入的新會員）都會見到該獎勵項目</p>
                        </div>
                        <div class="border-bottom mt-4 mb-5"></div>
                        <div class="flex-row align-items-center mt-2 mb-4" *ngFor="let center of product.form.centers">
                            <p style="flex: 0 0 3rem">{{ center.centerCode }}</p>
                            <p class="flex-1">{{ center.name }}</p>
                        </div>
                        <div class="flex-row align-items-center" (click)="center_popup.open(product.form.centers)">
                            <p class="text-lightgrey mr-4" translate>新增中心</p>
                            <i class="add-icon material-icons-round">add_circle</i>
                        </div>
                    </div>
                </ng-container>

                <app-center-popup #center_popup [action]="centerPopupAction"></app-center-popup>
            </div>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '問題設置' | translate }}" [matTooltipPosition]="'above'" translate>問題設置</p>
                <ng-container *ngIf="editing == false || product.isLaunched; else question">
                    <p *ngIf="product.isAnswerQuestionRequired" translate>會員換領前須填寫問卷</p>
                    <p *ngIf="!product.isAnswerQuestionRequired" translate>無須填寫問卷</p>
                </ng-container>

                <ng-template #question>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row">
                            <label>
                                <input type="radio" formControlName="isAnswerQuestionRequired" [value]="true">
                                <p translate>會員換領前須填寫問卷</p>
                            </label>
                            <label style="margin-left: 1rem;">
                                <input type="radio" formControlName="isAnswerQuestionRequired" [value]="false">
                                <p translate>無須填寫問卷</p>
                            </label>
                        </div>
                        <div class="flex-row align-items-center mt-4">
                            <i class="info-icon material-icons-round mr-5">error_outline</i>
                            <p class="text-xs text-lightgrey" translate>在導航欄--獎勵兌換--問題設置中可設置通用的問題以及選項</p>
                        </div>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="isAnswerQuestionRequired.touched && isAnswerQuestionRequired.errors">
                        <p *ngIf="isAnswerQuestionRequired.errors.required" translate>請輸入問題設置</p>
                    </div>
                </ng-template>
            </div>
        </form>

        <div class="footer">
            <button class="button fill red" (click)="disableProduct()" *ngIf="editing == false && product.active" translate>下 架</button>
            <button class="button outline" (click)="editing = false" *ngIf="editing == true" translate>取 消</button>
            <button class="button outline blue" style="margin-left: 10rem;" (click)="toggleEdit()" *ngIf="product.isClosed == false && product.active && editing == false" translate>編 輯</button>
            <button class="button outline blue" style="margin-left: 10rem;" (click)="submit()" *ngIf="editing" translate>保 存</button>
        </div>
    </div>
</div>


<ng-template #status_bar>
    <div class="flex-row">
        <div class="stage-container">
            <p class="stage blue" *ngIf="product.isLaunched == false">1</p>
            <p *ngIf="product.isLaunched || product.isClosed || product.active == false" class="stage checked material-icons-round">done</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>等候發布</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey wrap-text" translate>該獎勵尚未發布</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': product.isLaunched, 'red': product.isOutOfStock}" *ngIf="product.isClosed == false && product.active == true">2</p>
            <p *ngIf="product.isClosed || product.active == false" class="stage checked material-icons-round">done</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>進行中</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey wrap-text" translate>該獎勵已發布，換領進行中</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': product.isClosed}" *ngIf="product.active == true">3</p>
            <p *ngIf="product.active == false" class="stage checked material-icons-round">done</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>換領結束</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey wrap-text" translate>該獎勵已結束換領，14日後自動下架</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" [ngClass]="{'blue': product.active == false}">4</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已完結</p>
                </div>
                <p class="mt-2 text-lightgrey wrap-text" translate>該獎勵已下架</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #notice>
    <div class="inline-flex-column align-self-start align-items-stretch">
        <div class="notice" *ngIf="numberOfRedeemedUesrs > 0 && notices[0]">
            <p class="flex-1" translate>有會員參與了該獎勵兌換項目，請在“參與用戶”中查看</p>
            <i class="material-icons-round align-self-start text-lightgrey text-sm ml-1 mt-3" (click)="notices[0] = false">close</i>
        </div>

        <div class="notice red mt-4" *ngIf="product.isOutOfStock && notices[1]">
            <p class="flex-1" translate>庫存不足，如有需要，可點擊“編輯”增加庫存</p>
            <i class="material-icons-round align-self-start text-lightgrey text-sm ml-1 mt-3" (click)="notices[1] = false">close</i>
        </div>

        <div class="notice red mt-4" *ngIf="product.isPending  && notices[2]">
            <p class="flex-1" translate>已過截止兌換日期，已兌換人數仍少於庫存數量，請職員處理</p>
            <i class="material-icons-round align-self-start text-lightgrey text-sm ml-1 mt-3" (click)="notices[2] = false">close</i>
        </div>
    </div>
</ng-template>