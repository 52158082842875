import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, finalize, switchMap } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Address } from 'src/app/models/addresses/address';
import { Room } from 'src/app/models/addresses/room';
import { EventType } from 'src/app/models/event-types/event-type';
import { Profile } from 'src/app/models/profiles/profile';
import { AddressForm } from 'src/app/modules/share/forms/address-form';
import { PortalAnimation } from 'src/app/portal-animation';
import { EventTypeService } from 'src/app/services/event-type/event-type.service';
import { EventService } from 'src/app/services/event/event.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CreateEventDayForm } from '../../forms/create-event-day-form';
import { CreateEventRecordForm } from '../../forms/create-event-record-form';

@Component({
  selector: 'app-create-event-record-page',
  templateUrl: './create-event-record-page.component.html',
  styleUrls: ['./create-event-record-page.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class CreateEventRecordPageComponent implements OnInit {

  toggle: boolean = true;
  form: CreateEventRecordForm = this.eventService.eventRecordForm;
  venueForms: AddressForm[] = [];
  newEventDayForm: CreateEventDayForm | null = null;

  profile!: Profile;
  eventTypes: EventType[] = [];
  lang: string = this.translateService.currentLang;


  NAaddress:Room=new Room("","");
  
  constructor(
    private loadingService: LoadingService,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private profileService: ProfileService,
    private popupMessageService: PopupMessageService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetch();

    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      eventTypes: this.eventTypeService.getEventTypeList(),
      profile: this.profileService.getMyProfile(),
      NAaddress:this.eventService.getNAAddress()
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.eventTypes = value.eventTypes;
        this.profile = value.profile;
        this.NAaddress=value.NAaddress;
      });
  }

  addVenue(): void {
    this.venueForms.push(new AddressForm());
  }

  saveVenue(): void {
    if (this.venueForms.length == 0 || !this.venueForms.every(form => form.formGroup.valid)) {
      this.venueForms.forEach(form => form.formGroup.markAllAsTouched());
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    this.loadingService.startLoading();

    forkJoin(this.venueForms.map(form => this.profileService.createAddress(this.profile?.uuId as string, form.getCreateAddressModel())))
      .pipe(defaultIfEmpty(), finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.venueForms = [];
        this.fetch();
      })
  }

  dropVenue = (() => {
    this.venueForms = [];
  }).bind(this)

  reset(): void {
    this.form.reset();
  }

  submit(): void {
    this.form.giveAll.setValue(false);
    this.form.giveAll.updateValueAndValidity();
    if (this.form.formGroup.invalid || this.form.users.every(user => user.recordForm.valid) == false) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      this.form.formGroup.markAllAsTouched();
      this.form.users.forEach(user => user.recordForm.markAllAsTouched());
      return;
    }

    if (this.form.users.length < this.vacancy.value) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvitedParticipantsMustGreaterThanVacancy);
      return;
    }

    if (this.form.eventDays.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.EmptyEventDay);
      return;
    }

    if (this.closeDate.value > this.form.getEventStartDate()) {
      this.popupMessageService.messageSignal.emit(PopupMessages.DeadlineMustBeEarlierThanStartTime);
      return;
    }

    this.loadingService.startLoading();
    this.createEvent()
      .pipe(
        switchMap(value => concat(
          this.completeEvent(value.result.uuid),
          this.createUserTransactions(value.result.uuid),
          new Observable(
            subscriber => {
              this.loadingService.stopLoading();
              this.form.reset();
              this.popupMessageService.messageSignal.emit(PopupMessages.CreateEventRecordSuccessMessage(
                new Observable(subscriber => { this.router.navigate(['/main/activity/overview/event', value.result.uuid]) })
              ))
            }
          ))), finalize(() => this.loadingService.stopLoading())
      ).subscribe();
  }

  dropUser(index: number): void {
    this.form.users.splice(index, 1);
  }


  // Step 1
  private createEvent(): Observable<any> {
    return this.eventService.createEvent(this.form.getCreateEventModel());
  }

  // Step 2
  private completeEvent(eventUUId: string): Observable<any> {
    return this.eventService.complete(eventUUId);
  }

  //Step 3
  private createUserTransactions(eventUUId: string): Observable<any> {
    return this.eventService.createEventTransaction(eventUUId, {
      list: this.form.users.map(user => {
        return {
          userUUId: user.uuId,
          amount: user.recordForm.value
        }
      }),
      isCenterPaid: this.transactionType.value === 'UserEarn'
    });
  }


  get eventName(): AbstractControl {
    return this.form.formGroup.controls['eventName'];
  }

  get eventType(): AbstractControl {
    return this.form.formGroup.controls['eventType'];
  }

  get transactionType(): AbstractControl {
    return this.form.formGroup.controls['transactionType'];
  }

  get transactionFee(): AbstractControl {
    return this.form.formGroup.controls['transactionFee'];
  }

  get participationFormat(): AbstractControl {
    return this.form.formGroup.controls['participationFormat'];
  }

  get closeDate(): AbstractControl {
    return this.form.formGroup.controls['closeDate'];
  }

  get venue(): AbstractControl {
    return this.form.formGroup.controls['venue'];
  }

  get vacancy(): AbstractControl {
    return this.form.formGroup.controls['vacancy'];
  }

  get singleDayMode(): AbstractControl {
    return this.form.formGroup.controls['singleDayMode'];
  }

  get url(): AbstractControl {
    return this.form.formGroup.controls['url'];
  }

  get urlType(): AbstractControl {
    return this.form.formGroup.controls['urlType'];
  }

  openURL(): void {
    if (this.url.value == null)
      return;
    window.open(this.url.value);
  }

  print(value: any): void {
    this.form.eventDays = value;
  }

  cancelAction: () => void = (() => {
    this.singleDayMode.setValue(true);
  }).bind(this)

  clearAll(): void {
    this.form.eventDays = [];
  }

  addEventDay(): void {
    this.newEventDayForm = new CreateEventDayForm();
  }

  saveNewEventDay(): void {
    if (this.newEventDayForm == null) {
      return;
    }

    if (this.newEventDayForm?.formGroup.invalid) {
      this.newEventDayForm.formGroup.markAllAsTouched();
      return;
    }

    this.form.eventDays.push(this.newEventDayForm);
    this.newEventDayForm = null;
  }

  dropEventDay(index: number): void {
    this.form.eventDays.splice(index, 1);
  }

  editAddress(address: Address): void {
    this.loadingService.startLoading();
    this.profileService.editAddress(address.getUpdateAddressModel())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(() => this.fetch())
  }

  dropAddressAction = ((index: number) => {
    (this.profile as Profile).form.addresses[index - 1].editing = false;
  }).bind(this);
}
