import { EventType, IEventType } from "../event-types/event-type";
import { EventExportModel } from "../exports/event-export-model";
import { ITransaction } from "../transactions/transaction";
import { EventDay, IEventDay } from "./event-day";
import { EventParticipant } from "./event-participant";

export interface IEventListItem {
    id: number;
    uuId: string;
    eventName: string;
    eventType: IEventType;
    eventJoinType: string;
    eventStatus: string;
    eventDays: IEventDay[];
    eventPay: string;
    startTime: Date;
    participantVacancy: number;
    participants: IEventListItemParticipant[];
    deleteDate: Date;
    earning: string;
    remark: string;
    applyDeadline: Date;
    isCancelled: boolean;
    transactions:ITransaction[];
}

export class EventListItem {
    id: number;
    uuId: string;
    eventName: string;
    eventType: EventType;
    eventJoinType: string;
    eventStatus: string;
    eventDays: EventDay[];
    eventPay: string;
    startTime: Date;
    participantVacancy: number;
    participants: IEventListItemParticipant[];
    deleteDate: Date | null;
    earning: string;
    remark: string;
    applyDeadline: Date;
    isCancelled: boolean;
    transactions:ITransaction[];

    selected: boolean;

    constructor(event: IEventListItem) {
        this.id = event.id;
        this.uuId = event.uuId;
        this.eventName = event.eventName;
        this.eventType = new EventType(event.eventType);
        this.eventJoinType = event.eventJoinType;
        this.eventStatus = event.eventStatus;
        this.eventDays = event.eventDays.map(eventDay => new EventDay(eventDay));
        this.eventPay = event.eventPay;
        this.startTime = new Date(event.startTime + 'Z');
        this.participantVacancy = event.participantVacancy;
        this.participants = event.participants;
        this.deleteDate = event.deleteDate ? new Date(event.deleteDate) : null;
        this.selected = false;
        this.earning = event.earning;
        this.remark = event.remark;
        this.applyDeadline = new Date(event.applyDeadline);
        this.isCancelled = event.isCancelled;
        this.transactions=event.transactions;
    }

    get isOngoing(): boolean {
        let date = new Date()
        return this.startTime.getTime() <= date.getTime();
    }

    get numberOfParticipants(): number {
        return this.participants.filter(p => p.state === 'Confirmed').length;
    }

    public getEventStartDate(): Date | null {
        return this.eventDays.length > 0 ? this.eventDays.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0)[0].getStartTime() : null;
    }

    public getEventEndDate(): Date | null {
        if (this.eventDays.length == 0)
            return null;
        let date = this.eventDays.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)[0].getEndTime();
        if (date == null)
            return this.getEventStartDate();
        return date;
    }

    /** Return remaining date before deleted permanently */
    get deletedDays(): number {
        return Math.ceil((new Date().getTime() - (this.deleteDate as Date).getTime()) / 86400000);
    }

    getEventExportModel(): EventExportModel {
        const date: Date = new Date();

        var eventParticipants=this.participants.map(p=>({
            participants:p,
            transactions:this.transactions.filter(t=>(t.transferer!=null&&(t.transferer.uuId==p.uuId))||(t.transferee!=null&&t.transferee.uuId==p.uuId))
        }));
        if(this.uuId=="b25a2e84-f1f0-4732-af5b-2a1b136fa184"){
            console.log(this.participants,this.transactions,eventParticipants);
        }
        var amount=this.transactions.map(t=>t.amount).length==0?0:this.transactions.map(t=>t.amount).reduce((a,b)=>{return a+b});
        return {
            "紀錄編號": this.id.toString(),
            "活動名目": this.eventName,
            "活動類型": `${this.eventType.code} ${this.eventType.name}`,
            "交易類型": this.eventPay == "Free" ? "免費活動" : this.eventPay == "UserEarn" ? "用戶加分" : "用戶扣分",
            "參加類型": this.eventJoinType == "Staff" ? "職員處理" : "先到先得",
            "活動日期（YYYY-MM-DD）": toDateString(this.getEventStartDate()),
            "截止報名時間（YYYY-MM-DD HH：mm）": toDeadlineString(this.applyDeadline),
            "預計開始時間（HH：mm）": this.getEventStartDate()?.toTimeString().substr(0, 5),
            "預計結束時間（HH：mm）": this.getEventEndDate()?.toTimeString().substr(0, 5),
            "可參加人數": this.participantVacancy,
            "實際參加人數":eventParticipants.filter(ep=>ep.participants.state=="Confirmed"||ep.transactions.length>0).map(ep=>ep.participants.memberId).length,
            "預計參加的每位會員交易時分": this.eventPay == "Free" ? "0" : `${this.eventPay == "UserEarn" ? "+" : "-"}${this.earning}`,
            "總交易時分":this.eventPay == "UserSpend"?-amount:amount,
            "活動狀態": this.isCancelled ? "已終止" : this.getEventStartDate() ? this.getEventStartDate() as Date > date ? "等候報名" : (this.getEventEndDate() as Date) < date && this.eventStatus=="Complete"? "已完結" : "進行中" : "等候報名",
            "備註": "",
            "參加會員編號":  (this.getEventEndDate() as Date) < date || this.eventStatus=="Complete" ?eventParticipants.filter(ep=>ep.participants.state=="Confirmed"||ep.transactions.length>0).map(ep=>ep.participants.memberId).join(","):"",
            "參加會員":  (this.getEventEndDate() as Date) < date || this.eventStatus=="Complete"?eventParticipants.filter(ep=>ep.participants.state=="Confirmed"||ep.transactions.length>0).map(ep=>ep.participants.chineseName).join(","):""
        }
    }
}

function toDateString(date: Date | null) {
    if (date == null) {
        return "Missing Date";
    }
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
}

function toDeadlineString(date: Date) {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${date.toTimeString().substring(0, 5)}`
}

export interface IEventListItemParticipant {
    uuId: string;
    chineseName: string;
    memberId: string;
    state: string;
}

