import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { ISignUpSetting } from "src/app/models/settings/sign-up-setting";
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { SettingService } from 'src/app/services/setting/setting.service';

@Component({
  selector: 'app-sign-up-setting-page',
  templateUrl: './sign-up-setting-page.component.html',
  styleUrls: ['./sign-up-setting-page.component.scss']
})
export class SignUpSettingPageComponent implements OnInit {

  setting!: ISignUpSetting;
  form!: FormGroup;

  constructor(
    private settingService: SettingService,
    private loadingService: LoadingService,
    private popupMessageService: PopupMessageService,
    private snackBar: MatSnackBar,
    private translateSerivce: TranslateService
  ) { }


  ngOnInit(): void {
    this.fetch()
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.settingService.GetSignUpSetting()
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.setting = value;
        this.form = new FormGroup({
          openRegistration: new FormControl(this.setting.openRegistration),
          phone: new FormControl(this.setting.phone, [Validators.required]),
          title: new FormControl(this.setting.title, [Validators.required]),
          statement: new FormControl(this.setting.statement, [Validators.required])
        })

        if (this.openRegistration == true) {
          this.form.controls['title'].setValidators([Validators.required]);
          this.form.controls['statement'].setValidators([Validators.required]);
        }
        else {
          this.form.controls['title'].setValidators([]);
          this.form.controls['statement'].setValidators([]);
        }

        this.form.controls['openRegistration'].valueChanges.subscribe(value => {
          if (value == true) {
            this.form.controls['title'].setValidators([Validators.required]);
            this.form.controls['title'].updateValueAndValidity()
            this.form.controls['statement'].setValidators([Validators.required]);
            this.form.controls['statement'].updateValueAndValidity()
          }
          else {
            this.form.controls['title'].setValidators([]);
            this.form.controls['title'].updateValueAndValidity()
            this.form.controls['statement'].setValidators([]);
            this.form.controls['statement'].updateValueAndValidity()
          }
        })
      });

  }

  onSave(): void {
    if (this.form.invalid) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      this.form.markAllAsTouched();
      return;
    }

    this.loadingService.startLoading();
    this.settingService.SetSignUpSetting({
      uuId: this.setting.uuId,
      openRegistration: this.form.controls['openRegistration'].value,
      phone: this.form.controls['phone'].value,
      title: this.form.controls['title'].value,
      statement: this.form.controls['statement'].value,
      organizationName: this.setting.organizationName
    }).pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        forkJoin(
          {
            text: this.translateSerivce.get("資料已保存"),
            action: this.translateSerivce.get("關閉")
          }
        ).subscribe(translation => {
          this.snackBar.open(translation.text, translation.action, { horizontalPosition: 'right', duration: 5000 });
        });
      })
  }

  get openRegistration(): boolean {
    return this.form.controls['openRegistration'].value
  }

}
