<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>垃圾桶</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'request'" translate>義工服務</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'event'" translate>中心活動</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'user'" translate>用戶檔案</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'donation'" translate>捐分紀錄</p>
        <p class="ml-4 text-grey" *ngIf="tab == 'redeem'" translate>獎勵兌換</p>
    </div>

    <p class="page-title mt-8" translate>垃圾桶</p>

    <div class="container mt-5">
        <div class="container-header">
            <p class="container-header-tab" routerLink="request" routerLinkActive="active" (click)="tab = 'request'" translate>義工服務</p>
            <p class="container-header-tab" routerLink="event" routerLinkActive="active" (click)="tab = 'event'" translate>中心活動</p>
            <p class="container-header-tab" routerLink="user" routerLinkActive="active" (click)="tab = 'user'" translate>用戶檔案</p>
            <p class="container-header-tab" routerLink="donation" routerLinkActive="active" (click)="tab = 'donation'" translate>捐分紀錄</p>
            <p class="container-header-tab" routerLink="redeem" routerLinkActive="active" (click)="tab = 'redeem'" translate>獎勵兌換</p>
        </div>
        <div class="container-body">
            <div class="inline-flex-column" [@slide]="prepareRoute(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </div>

</div>