<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>通告</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>創建通告</p>
    </div>

    <p class="page-title mt-8" translate>創建通告</p>
    <div class="container mt-5">
        <form class="container-body" [formGroup]="form.formGroup">
            <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>

            <div class="input-row">
                <p class="title required" matTooltip="{{ '通告標題' | translate }}" [matTooltipPosition]="'above'" translate>通告標題</p>
                <input class="user-input xl" type="text" formControlName="title" placeholder="{{ '通告標題不超過18個字' | translate }}">
                <div class="user-input-error zoomIn" *ngIf="title.touched && title.errors">
                    <p *ngIf="title.errors.required" translate>請輸入通告標題</p>
                    <p *ngIf="title.errors.byte" translate>標題過長</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" translate>類型</p>
                <label style="margin-right: 1.75rem;">
                    <input class="mr-4" type="radio" value="Text" formControlName="type" (change)="form.enableURL()">
                    <p translate>純文本</p>
                </label>
                <label>
                    <input class="mr-4" type="radio" value="HyperLink" formControlName="type" (change)="form.enableURL()">
                    <p translate>超鏈接（用戶直接訪問網址）</p>
                </label>

                <div class="zoomIn user-input-error" *ngIf="type.touched && type.errors">
                    <p class="error-text text-sm" *ngIf="type.errors.required">請選擇類型</p>
                </div>
            </div>

            <ng-container *ngIf="type.value == 'HyperLink';then url_box"></ng-container>

            <ng-template #url_box>
                <div class="input-row ">
                    <p class="title" translate>超鏈接</p>
                    <input class="user-input xl" type="text" formControlName="url" placeholder="{{ '請輸入超鏈接' | translate }}">
                    <button type="button" class="button outline blue sm ml-6" type="button" (click)="openURL()" translate>瀏覽網址</button>

                    <div class="zoomIn user-input-error" *ngIf="url.touched && url.errors">
                        <p class="error-text text-sm" *ngIf="url.errors.required" translate>請輸入超鏈接</p>
                    </div>
                </div>
            </ng-template>


            <div class="input-row">
                <p class="title required" translate>發布時間</p>
                <input class="user-input xs" formControlName="announceDate" [matDatepicker]="picker_1" (click)="picker_1.open()" [min]="minDate" placeholder="{{ '發布時間' | translate }}" readonly>
                <mat-datepicker startView="multi-year" disabled="false" touchUi #picker_1></mat-datepicker>
                <app-time-picker [form]="announceDate" class="ml-9"></app-time-picker>
                <div class="user-input-error zoomIn" *ngIf="announceDate.touched && announceDate.errors">
                    <p *ngIf="announceDate.errors.required" translate>請輸入發布日期</p>
                    <p *ngIf="announceDate.errors.time" translate>請輸入發布時間</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title required" translate>有效期至</p>
                <input class="user-input xs" formControlName="expireDate" [matDatepicker]="picker_2" (click)="picker_2.open()" [min]="minDate" placeholder="{{ '有效期至' | translate }}" readonly>
                <mat-datepicker startView="multi-year" disabled="false" touchUi #picker_2></mat-datepicker>
                <app-time-picker [form]="expireDate" class="ml-9"></app-time-picker>
                <div class="user-input-error zoomIn" *ngIf="expireDate.touched && expireDate.errors">
                    <p *ngIf="expireDate.errors.required" translate>請輸入有效日期</p>
                    <p *ngIf="expireDate.errors.time" translate>請輸入有效時間</p>
                </div>
                <div class="user-input-error zoomIn" *ngIf="!expireDate.errors && form.invalidExpireDate">
                    <p translate>有效期必須在發佈日期後</p>
                </div>
            </div>

            <div class="input-row">
                <p class="title align-self-start" style="line-height: 2rem;" translate>通告詳情</p>
                <textarea style="width: 22rem;height: 17.0625rem;" formControlName="detail"></textarea>
            </div>

            <div class="input-row mb-8">
                <p class="title required align-self-start" style="line-height: 2rem;" matTooltip="{{ '通告對象' | translate }}" [matTooltipPosition]="'above'" translate>通告對象</p>
                <ng-container *ngIf="form.users.length == 0">
                    <div class="inline-flex-column">
                        <p class="text-lightgrey" style="line-height: 2rem;" translate>未指定，請在用戶列表中確認人選</p>
                        <button type="button" class="button blue outline sm" routerLink="user" translate>用戶列表</button>
                    </div>
                </ng-container>

                <ng-container *ngIf="form.users.length > 0">
                    <div class="inline-flex-column" style="width: 22rem;">
                        <div class="inline-flex-row align-items-center">
                            <p translate>已選擇</p>
                            <p class="ml-2 mr-2 text-blue">{{ form.users.length }}</p>
                            <p class="flex-1" translate>位用戶</p>
                            <button type="button" class="button blue outline sm" (click)="users.open()" translate>查看詳情</button>
                        </div>
                        <div class="inline-flex-row align-items-center mt-4">
                            <i class="material-icons-round info-icon mr-6 align-self-start">error_outline</i>
                            <p class="text-lightgrey text-xs" style="white-space: normal;">職員可查看/管理所屬權限內的會員，未被選擇的會員將不會收到通告，如有需要，職員可新增通告對象至通告對象列表</p>
                        </div>
                    </div>
                </ng-container>

            </div>

            <div class="input-row">
                <p class="title align-self-start" style="line-height: 2rem;" translate>備註</p>
                <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
            </div>

            <div class="inline-flex-row justify-content-center" style="margin-top: 6.3125rem;">
                <button type="button" class="button blue fill" (click)="submit()" translate>提 交</button>
            </div>
        </form>
    </div>
</div>

<app-announcement-create-users-popup #users></app-announcement-create-users-popup>