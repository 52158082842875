<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>會員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>申請審核列表</p>
    </div>

    <p class="page-title mt-8" *ngIf="user">{{ user.chineseName }} {{ user.englishName }}</p>

    <div class="container mt-5" *ngIf="user">
        <div class="container-header">
            <p class="flex-1">{{ '申請時間：' | translate: {value: user.signUpAt | date: 'yyyy-MM-dd HH:mm'} }}</p>
        </div>

        <div class="container-body">
            <ng-container *ngTemplateOutlet="status"></ng-container>

            <div class="message-box yellow" style="margin-top: 2rem;" *ngIf="user.approvedBy == null">
                <i class="material-icons-round info">info</i>
                <p class="ml-4" translate>點擊底部“開始審核”按鈕後可進行操作</p>
            </div>

            <div class="message-box blue" style="margin-top: 2rem;" *ngIf="user.approvedBy != null && client.userName != user.approvedBy && user.disapprovedBy == null">
                <i class="material-icons-round info-blue">info</i>
                <p class="ml-4">{{ '職員正在審核該資料中' | translate: { value: user.approvedBy } }}</p>
            </div>

            <div class="message-box red" style="margin-top: 2rem;" *ngIf="user.disapprovedBy">
                <i class="material-icons-round info-red">info</i>
                <p class="ml-4">{{ '職員審核後拒絕申請。理由：' | translate: { value: user.disapprovedBy, reason: user.disapprovedReason || 'null' } }}</p>
            </div>

            <form class="account-box" style="margin-top: 2rem;" [formGroup]="user.form">
                <div class="title-box">
                    <p class="title text-bold" translate>帳號信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員編號</p>
                    <input class="user-input xs" type="text" placeholder="{{ '編號' | translate }}" formControlName="memberId">
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入帳號</p>
                    <p>{{ user.username }}</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入密碼</p>
                    <p>********</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>中心編號</p>
                    <div class="user-select">
                        <select class="user-select lg" formControlName="center">
                            <option [ngValue]=null selected disabled translate>請選擇中心</option>
                            <option *ngFor="let center of centers" [ngValue]="center.uuId">{{ center.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>時分餘額</p>
                    <input class="user-input xs" type="number" placeholder="{{ '系統填寫' | translate }}" disabled>
                </div>

            </form>

            <div class="information-box">
                <div class="title-box">
                    <p class="title text-bold" translate>基本信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員姓名</p>
                    <p style="flex: 0 0 7.75rem;">{{ user.chineseName }}</p>
                    <p>{{ user.englishName }}</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>性別</p>
                    <p>{{ user.gender | translate }}</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>電話號碼</p>
                    <div class="inline-flex-column">
                        <p style="line-height: 2rem;">{{ user.phone }}</p>
                        <p style="line-height: 2rem;" class="mt-4">{{ user.mobilePhoneModel }}</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>出生日期</p>
                    <p style="flex: 0 0 7.75rem;">{{ user.dateOfBirth | date: 'longDate': '': lang }}</p>
                    <p translate>{{ '歲' | translate: {value: user.age} }}</p>
                </div>

                <div class="input-row">
                    <p class="title" translate>是否有流動數據</p>
                    <p *ngIf="user.hasSimCand" translate>有</p>
                    <p *ngIf="user.hasSimCand == false" translate>沒有</p>
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>細胞小組</p>
                    <p translate>未指定</p>
                    <i class="material-icons-round info-icon ml-8">help_outline</i>
                    <p class="ml-4 text-xs">同一細胞小組的會員在義工推薦時優先顯示</p>
                </div>


                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>代理人</p>
                    <p class="text-lightgrey" translate>未指定，請在用戶列表中確認人選</p>
                    <i class="material-icons-round info-icon ml-8">help_outline</i>
                    <p class="text-xs ml-3 text-lightgrey" translate>代理人帳號可代該用戶帳號申請義工服務和中心活動</p>
                </div>

                <div class="input-row">
                    <p class="title" translate>緊急聯絡人</p>
                    <p style="flex: 0 0 7.75rem;">{{ user.contactPersonName }}</p>
                    <p>{{ user.contactPersonPhoneNumber }}</p>
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>身體狀況</p>
                    <p translate>未設置</p>
                </div>

                <div class="input-row">
                    <p class="title" translate>教育程度</p>
                    <p style="flex: 0 0 7.75rem;" *ngIf="user.literacy == 'CanReadAndWrite'" translate>能讀能寫</p>
                    <p style="flex: 0 0 7.75rem;" *ngIf="user.literacy == 'CanRead'" translate>只能讀，不能寫</p>
                    <p style="flex: 0 0 7.75rem;" *ngIf="user.literacy == 'CanSpeak'" translate>不懂文字</p>
                    <p *ngIf="user.educationLevel == 'University'" translate>大學或以上</p>
                    <p *ngIf="user.educationLevel == 'Secondary'" translate>中學</p>
                    <p *ngIf="user.educationLevel == 'Primary'" translate>小學</p>
                    <p *ngIf="user.educationLevel == 'None'" translate>不適用</p>
                </div>
            </div>

            <div class="address-box">
                <div class="title-box">
                    <p class="title text-bold" translate>地址信息</p>
                    <p class="text-sm text-lightgrey" translate>居住條件選項可在後台設置中增加/編輯</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 1.25rem;">{{ '地址#' | translate: {value: 1} }}</p>
                    <div class="inline-flex-column">
                        <p style="line-height: 1.25rem;">{{ user.address.region.name }}，{{ user.address.district.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ user.address.subDistrict.name }}{{ user.address.street.name }}{{ user.address.streetNumber.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ user.address.estate.name }}，{{ user.address.block.name }}，</p>
                        <p style="line-height: 1.25rem;">{{ user.address.floor.name }}，{{ user.address.room.name }}</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;">{{ '居住條件 #' | translate: {value: 1} }}</p>
                    <p translate>不適用</p>
                </div>
            </div>

            <div class="volunteer-box">
                <div class="title-box">
                    <p class="title text-bold" translate>義工服務信息</p>
                    <p class="text-sm text-lightgrey" translate>義工會員須填寫，系統會根據以下信息在邀請義工時篩選適合的義工會員，非義工會員可不填</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>空閒時間</p>
                    <app-availability-table #availability [availabilities]="[]" [readonly]="true"></app-availability-table>
                </div>

                <div class="input-row">

                    <p class="title required align-self-start" style="line-height: 2rem;" translate>可提供服務</p>
                    <div class="inline-flex-row align-items-center" *ngIf="user.services.length == 0">
                        <p class="text-lightgrey" translate>未設置</p>
                    </div>

                    <ng-container *ngIf="user.services.length > 0">
                        <ng-container *ngTemplateOutlet="service_template"></ng-container>
                    </ng-container>

                </div>
            </div>

            <div class="inline-flex-row align-items-center justify-content-center" style="margin-bottom: 5.1875rem;">
                <ng-container *ngIf="user.signUpStatus != 'Disapproved'">
                    <button type="button" class="button fill blue" *ngIf="user.approvedBy == null" (click)="startApproval()" translate>開始審核</button>
                    <button type="button" class="button fill red" *ngIf="client.userName == user.approvedBy" (click)="disapprove.open()" translate>拒 絕</button>
                    <button type="button" class="button fill blue" style="margin-left: 12.75rem;" *ngIf="client.userName == user.approvedBy" (click)="approve()" translate>確認通過</button>
                </ng-container>
                <ng-container *ngIf="user.signUpStatus == 'Disapproved'">
                    <button type="button" class="button fill red" *ngIf="user.disapprovedBy" (click)="deleteUser()" translate>移 除</button>
                    <button type="button" class="button fill blue" style="margin-left: 12.75rem;" *ngIf="user.disapprovedBy" (click)="reapprove()" translate>重新審核</button>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #status>
    <div class="flex-row">
        <div class="stage-container">
            <p class="stage blue" *ngIf="user.signUpStatus == 'Pending'">1</p>
            <i class="material-icons-round stage blue" *ngIf="user.signUpStatus != 'Pending'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>等候審核</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>該申請正在等候審核</p>
            </div>
        </div>

        <div class="stage-container">
            <p class="stage" *ngIf="user.signUpStatus == 'Pending' || user.signUpStatus == 'Approving'" [ngClass]="{'blue': user.signUpStatus == 'Approving'}">2</p>
            <i class="material-icons-round stage blue" *ngIf="user.signUpStatus == 'Disapproved'">check</i>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>審核中</p>
                    <div class="flex-1 border-bottom ml-3"></div>
                </div>
                <p class="mt-2 text-lightgrey" translate>職員正在審核中</p>
            </div>
        </div>

        <div class="stage-container" *ngIf="user.signUpStatus != 'Disapproved'">
            <p class="stage">3</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已通過</p>
                </div>
                <p class="mt-2 text-lightgrey" translate>該申請已通過審核</p>
            </div>
        </div>

        <div class="stage-container" *ngIf="user.signUpStatus == 'Disapproved'">
            <p class="stage red">3</p>
            <div class="stage-box mt-2">
                <div class="stage-title">
                    <p translate>已拒絕</p>
                </div>
                <p class="mt-2 text-lightgrey" translate>該申請被拒絕</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #service_template>
    <div class="service-box">
        <div class="service-box-header mb-4">
            <p class="text-sm text-lightgrey" style="line-height: 2rem;">該會員可提供以下義工服務，系統根據可供服務和空閒時間進行義工推薦</p>
        </div>
        <div class="service-box-body">
            <div class="service-type-column">
                <div class="service-type-item" [ngClass]="{active: index == i}" (click)="index = i" *ngFor="let serviceType of selectedServiceTypes;let i = index">
                    <p class="flex-1">{{ serviceType.name }}</p>
                    <p class="text-sm text-lightgrey">{{ serviceType.selectedServices.length }}</p>
                </div>
            </div>
            <div class="service-column" *ngIf="selectedServiceTypes[index]">
                <div class="service-item" *ngFor="let service of selectedServiceTypes[index].selectedServices">
                    <p style="flex: 0 0 5.25rem;">{{ service.servicesCode }}</p>
                    <p style="flex: 0 0 7rem;">{{ service.name }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-sign-up-disapprove-popup (signal)="fetch()" #disapprove></app-sign-up-disapprove-popup>