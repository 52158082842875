<div class="popup-bg" *ngIf="display" (click)="closeAction()">
    <div class="user-management-popup zoomIn" (click)="$event.stopPropagation()">
        <p style="font-size: 1.5rem;line-height: 2.125rem;" translate>用戶管理</p>
        <app-search-input [action]="searchAction" [placeholder]="'請輸入姓名 或 會員編號'"></app-search-input>

        <div class="switch-box mt-2">
            <div class="switch-tab" (click)="currentTabIndex = 0" [ngClass]="{active: currentTabIndex == 0}">
                <p>{{ '確認參加()' | translate: {value: confirmedUsers.length} }}</p>
            </div>

            <div class="switch-tab" (click)="currentTabIndex = 1" [ngClass]="{active: currentTabIndex == 1}">
                <p>{{ '接受邀請()' | translate: {value: acceptedUsers.length} }}</p>
            </div>

            <div class="switch-tab" (click)="currentTabIndex = 2" [ngClass]="{active: currentTabIndex == 2}">
                <p>{{ '等待回應()' | translate: {value: pendingUsers.length} }}</p>
            </div>

            <div class="switch-tab" (click)="currentTabIndex = 3" [ngClass]="{active: currentTabIndex == 3}">
                <p translate>{{ '已拒絕()' | translate: {value: rejectedUsers.length} }}</p>
            </div>
        </div>

        <div class="switch">
            <div class="switch-body" [ngClass]="{'tab-1': currentTabIndex == 0,'tab-2': currentTabIndex == 1,'tab-3': currentTabIndex == 2,'tab-4': currentTabIndex == 3}">
                <div class="switch-page">
                    <app-event-detail-confirmed-user-tab [users]="confirmedUsers" [reloadAction]="reloadAction" [closeAction]="closeAction" [type]="type"></app-event-detail-confirmed-user-tab>
                </div>
                <div class="switch-page">
                    <app-event-detail-accepted-user-tab [users]="acceptedUsers" [reloadAction]="reloadAction" [closeAction]="closeAction" [changeTabAction]="changeTabAction" [vacancy]="vacancy"></app-event-detail-accepted-user-tab>
                </div>
                <div class="switch-page">
                    <app-event-detail-pending-user-tab [users]="pendingUsers" [reloadAction]="reloadAction" [closeAction]="closeAction" [changeTabAction]="changeTabAction" [format]="format"></app-event-detail-pending-user-tab>
                </div>
                <div class="switch-page">
                    <app-event-detail-rejected-user-tab [users]="rejectedUsers" [reloadAction]="reloadAction" [closeAction]="closeAction"></app-event-detail-rejected-user-tab>
                </div>
            </div>
        </div>
    </div>
</div>