export const AppSetting = {
    baseUrl: "https://skh.hincaretb.com/api/",

    timeoutValue: 60000,

    orgName: "將軍澳聖公會老人服務中心 · 織福計劃",
    centerUUId: "e9112794-a129-4f2b-8bcd-a85f3fceb89f",

    firebaseConfig: {
        apiKey: "AIzaSyBqjJG85ov4J31z83XmQNOnaBGCP-dpxRk",
        authDomain: "skhtimebank.firebaseapp.com",
        databaseURL: "https://skhtimebank.firebaseio.com",
        projectId: "skhtimebank",
        storageBucket: "skhtimebank.appspot.com",
        messagingSenderId: "194032182428",
        appId: "1:194032182428:web:146ccd03e298c42c80f959",
        measurementId: "G-EM4R9SFFEK"
    },

    tokenKey: "timebank_token",
    roleKey: "timebank_roles",
    remainDays: 14
}