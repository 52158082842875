export interface IDonationImportModel {
    "紀錄編號": string;
    "會員編號*": string;
    "會員中文姓名*": string;
    "捐分類型*": string;
    "捐分日期*（YYYY-MM-DD）": string;
    "交易時分*（捐出/受贈的時分）": string;
    "備註": string;
}

export class DonationImportModel {
    public row: number;
    public id: string;
    public userUUId: string | undefined;
    public donationDate: string | null;
    public isCenterPaid: boolean | null;
    public amount: number | null;
    public remark: string;

    constructor(donation: IDonationImportModel, index: number) {
        this.row = index;
        this.id = donation["紀錄編號"]?.trim();
        this.userUUId = donation["會員編號*"]?.trim();
        this.donationDate = donation["捐分日期*（YYYY-MM-DD）"] ? new Date(donation["捐分日期*（YYYY-MM-DD）"].trim()).toString() === "Invalid Date" ? null : donation["捐分日期*（YYYY-MM-DD）"] : null;
        this.isCenterPaid = donation["捐分類型*"]?.trim() == "中心捐分" ? true : donation["捐分類型*"]?.trim() == "用戶捐分" ? false : null;
        this.amount = donation["交易時分*（捐出/受贈的時分）"] ? Number.parseFloat(donation["交易時分*（捐出/受贈的時分）"].trim()) : null;
        this.remark = donation["備註"];
    }

    public selfValidate(): string | null {
        if (this.userUUId == null)
            return "第" + (this.row + 2) + "列: 編號" + this.id + "未輸入會員編號\n";
        if (this.donationDate == null)
            return "第" + (this.row + 2) + "列: 編號" + this.id + "未輸入捐分日期\n";
        if (this.amount == null)
            return "第" + (this.row + 2) + "列: 編號" + this.id + "未輸入捐分數量\n";
        if (this.isCenterPaid == null)
            return "第" + (this.row + 2) + "列: 編號" + this.id + "未輸入捐分類型\n";
        return null;
    }
}