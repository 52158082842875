<div class="inline-flex-column flex-1" style="margin-top: -1rem;">
    <div class="inline-flex-row align-items-center">
        <app-search-input class="flex-1" [placeholder]="'請輸入紀錄編號 或 活動名'" [action]="searchFilterAction"></app-search-input>

        <button class="icon-button yellow ml-5" (click)="openImportPopup()">
            <i class="material-icons-round mb-1">unarchive</i>
            <p translate>滙入紀錄</p>
        </button>

        <button class="icon-button yellow ml-5" (click)="exportDonations()">
            <i class="material-icons-round mb-1">archive</i>
            <p translate>匯出選項</p>
        </button>
    </div>


    <div class="inline-flex-row align-items-center mt-3">
        <app-service-date-range-filter [action]="dateRangeFilterAction"></app-service-date-range-filter>
        <app-donation-type-filter class="ml-4" [action]="typeFilterAction"></app-donation-type-filter>
    </div>

    <ng-container *ngTemplateOutlet="pagination"></ng-container>

    <div class="user-table mt-3">
        <div class="user-table-row header">
            <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 11;">
                <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                <p matTooltip="{{ '交易編號' | translate }}" [matTooltipPosition]="'above'" translate>交易編號</p>
                <div class="inline-flex-column" (click)="filters.idOrder = !filters.idOrder;filters.dateOrder = null">
                    <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.idOrder}">arrow_drop_up</i>
                    <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.idOrder}">arrow_drop_down</i>
                </div>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.5rem;flex-grow: 26;">
                <p matTooltip="{{ '捐分日期' | translate }}" [matTooltipPosition]="'above'" translate>捐分日期</p>
                <div class="inline-flex-column" (click)="filters.dateOrder = !filters.dateOrder;filters.idOrder = null">
                    <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.dateOrder}">arrow_drop_up</i>
                    <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.dateOrder}">arrow_drop_down</i>
                </div>
            </div>
            <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                <p translate>會員編號</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6rem;flex-grow: 24;">
                <p translate>中文姓名</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                <p translate>捐分類型</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 8.5rem;flex-grow: 34;">
                <p translate>交易時分</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 10.5rem;flex-grow: 42;">
                <p translate>操作</p>
            </div>
        </div>

        <div class="user-table-row" *ngFor="let donation of displayedDonations">
            <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 11;">
                <input type="checkbox" [(ngModel)]="donation.selected">
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                <p>{{ donation.id }}</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.5rem;flex-grow: 26;">
                <p>{{ donation.date | date: 'yyyy/MM/dd' }}</p>
            </div>
            <ng-container *ngIf="donation.transferee.uuId == centerUUId">
                <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                    <p>{{ donation.transferer.memberId }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6rem;flex-grow: 24;">
                    <p>{{ donation.transferer.chineseName }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                    <p translate>用戶捐分</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 8.5rem;flex-grow: 34;">
                    <p class="text-red">- {{ donation.amount }}</p>
                </div>
                <div class="user-table-cell justify-content-start" style="flex-basis: 10.5rem;flex-grow: 42;">
                    <p class="click-text-blue" style="margin-left: 1.875rem;" [routerLink]="['/main/user/overview', donation.transferer.uuId]" translate>檢視用戶</p>
                    <p class="click-text-blue" style="margin-left: 1.5rem;" (click)="deleteDonation(donation.uuId)" translate>移除</p>
                </div>
            </ng-container>
            <ng-container *ngIf="donation.transferer.uuId == centerUUId">
                <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                    <p>{{ donation.transferee.memberId }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6rem;flex-grow: 24;">
                    <p>{{ donation.transferee.chineseName }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 9.25rem;flex-grow: 37;">
                    <p translate>中心捐分</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 8.5rem;flex-grow: 34;">
                    <p>+ {{ donation.amount }}</p>
                </div>

                <div class="user-table-cell justify-content-start" style="flex-basis: 10.5rem;flex-grow: 42;">
                    <p class="click-text-blue" style="margin-left: 1.875rem;" [routerLink]="['/main/user/overview', donation.transferee.uuId]" translate>檢視用戶</p>
                    <p class="click-text-blue" style="margin-left: 1.5rem;" (click)="deleteDonation(donation.uuId)" translate>移除</p>
                </div>
            </ng-container>
        </div>

        <div class="user-table-row" *ngIf="displayedDonations.length === 0">
            <div class="user-table-cell">
                <p class="text-grey" translate>沒有紀錄</p>
            </div>
        </div>
    </div>
</div>


<ng-template #pagination>
    <div class="inline-flex-row align-items-center mt-5">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ selectedDonations.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: selectedDonations.length == 0}" (click)="deleteDonationList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ donations.length }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>