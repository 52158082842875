import { Address, IAddress } from "../addresses/address";

export interface ISignUpUserListItem {
    uuId: string;
    chineseName: string;
    gender: string;
    dateOfBirth: Date;
    phone: string;
    address: IAddress;
    signUpAt: Date;
    status: string;
}

export class SignUpUserListItem {
    uuId: string;
    chineseName: string;
    gender: string;
    dateOfBirth: Date;
    phone: string;
    address: Address;
    signUpAt: Date;
    status: string;

    selected: boolean;

    constructor(user: ISignUpUserListItem) {
        this.uuId = user.uuId;
        this.chineseName = user.chineseName;
        this.gender = user.gender;
        this.dateOfBirth = new Date(user.dateOfBirth + 'Z');
        this.phone = user.phone;
        this.address = new Address(user.address);
        this.signUpAt = new Date(user.signUpAt + 'Z');
        this.status = user.status;
        this.selected = false;
    }

    get age(): number {
        return new Date(Date.now() - (this.dateOfBirth as Date).getTime()).getUTCFullYear() - 1970;
    }

    get signUpOverTime(): boolean {
        return this.status == 'Pending' && (new Date().getTime() - this.signUpAt.getTime()) / 86400000 > 14;
    }
}