<div class="inline-flex-column flex-1">
    <div class="input-row">
        <p class="text-lightgrey text-sm" translate>欄目帶 * 號為必填項</p>
    </div>

    <div class="input-row">
        <p class="title required" matTooltip="{{ '指定交易會員' | translate }}" [matTooltipPosition]="'above'" translate>指定交易會員</p>
        <button class="button outline blue sm" type="button" routerLink="select-donees" translate>選擇用戶</button>
    </div>

    <ng-container *ngIf="form.users.length > 0">
        <div class="inline-flex-column" style="padding-left: 1.5rem;margin-bottom: 1.5rem;">
            <div class="inline-flex-row align-items-center">
                <input class="mr-4" type="checkbox" [formControl]="disabled">
                <p class="text-grey mr-4" translate>每個帳戶將會獲得</p>
                <input class="user-input mr-4 text-center" [formControl]="input" style="width: 4.3125rem;" type="number">
                <p class="text-grey" translate>時分</p>
            </div>

            <div class="inline-flex-row align-items-center mt-2 text-grey">
                <p translate>已選擇以下</p>
                <p class="text-lightblue ml-2 mr-2">{{ form.users.length }}</p>
                <p translate>位會員，請完善捐出時分</p>
            </div>

            <div class="agent-table mt-2">
                <div class="agent-table-row header">
                    <div class="agent-table-cell">
                        <p translate>會員編號</p>
                    </div>
                    <div class="agent-table-cell">
                        <p translate>中文姓名</p>
                    </div>
                    <div class="agent-table-cell">
                        <p translate>中心代碼</p>
                    </div>
                    <div class="agent-table-cell">
                        <p translate>電話</p>
                    </div>
                    <div class="agent-table-cell credit">
                        <p class="ml-4'" translate>用戶餘額</p>
                    </div>
                    <div class="agent-table-cell">
                        <p class="ml-4'" translate>交易時分</p>
                    </div>
                    <div class="agent-table-cell">
                    </div>
                </div>

                <div class="agent-table-row" *ngFor="let user of form.users; let index = index">
                    <div class="agent-table-cell">
                        <p>{{ user.memberId}}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p>{{ user.chineseName }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p>{{ user.centerCode}}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p>{{ user.phone }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <p [ngClass]="{'text-red': user.timeBalance < 0}">{{ user.timeBalance }}</p>
                    </div>
                    <div class="agent-table-cell">
                        <input class="user-input text-center" [formControl]="user.amount" style="width: 4rem;" type="number">
                    </div>
                    <div class="agent-table-cell" style="position: relative;">
                        <p class="click-text-blue text-xs" (click)="drop(index)">移除</p>
                        <p class="text-red zoomIn" style="position: absolute;left: 6rem;" *ngIf="user.amount.touched && (user.amount.errors?.required || user.amount.errors?.pattern)" translate>（請填寫有效數字）</p>
                    </div>
                </div>
            </div>

            <div class="inline-flex-row align-items-center mt-5 text-grey">
                <p translate>中心 總共支出</p>
                <p class="text-lightblue ml-2 mr-2">{{ sum }}</p>
                <p translate>時分 捐至所選會員帳戶</p>
            </div>

        </div>
    </ng-container>

    <form class="input-row" [formGroup]="form.formGroup">
        <p class="title align-self-start" style="line-height: 2rem;" translate>備註</p>
        <textarea style="width: 22rem;height: 8.625rem;" formControlName="remark"></textarea>
    </form>
    <div class="inline-flex-row justify-content-center" style="margin-top: 2rem;margin-bottom: 6rem;">
        <button class="button outline" type="button" (click)="reset()" translate>取消創建</button>
        <button class="button fill blue" type="button" style="margin-left: 9.375rem;" (click)="submit()" translate>提 交</button>
    </div>
</div>