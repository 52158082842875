<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>職員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>職員概覽</p>
    </div>

    <p class="page-title mt-8" translate>職員概覽</p>

    <div class="container mt-5">
        <div class="container-body">
            <div class="inline-flex-row align-items-center">
                <app-search-input class="flex-1" [placeholder]="'請輸入姓名 或 職員編號'" [action]="searchFilterAction"></app-search-input>

                <button class="icon-button orange" (click)="fetch()">
                    <i class="material-icons-round mb-1">refresh</i>
                    <p translate>重新整理</p>
                </button>

                <button class="icon-button orange ml-5" routerLink="/main/staff/create">
                    <i class="material-icons-round mb-1">person_add</i>
                    <p translate>創建職員</p>
                </button>

                <button class="icon-button yellow ml-5" (click)="export()">
                    <i class="material-icons-round mb-1">archive</i>
                    <p translate>匯出所有</p>
                </button>
            </div>

            <div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
                <app-center-filter [action]="centerFilterAction"></app-center-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>


            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>中心代碼</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 20.5rem;flex-grow: 41;">
                        <p translate>中心名稱</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>職員編號</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>中文姓名</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
                        <p translate>電話</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 11.5rem;flex-grow: 23;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let staff of displayedStaffs">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <div class="flex-row align-items-center">
                            <input type="checkbox" [(ngModel)]="staff.selected">
                        </div>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ staff.center.centerCode }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 20.5rem;flex-grow: 41;">
                        <p>{{ staff.center.name }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ staff.memberId }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ staff.chineseName }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
                        <p>{{ staff.phone }}</p>
                    </div>

                    <div class="list-table-cell" style="flex-basis: 11.5rem;flex-grow: 23;">
                        <p class="flex-0 click-text-lightblue mr-5" [routerLink]="['/main/staff/overview', staff.uuId]" translate>檢視/更改權限</p>
                        <p class="flex-0 click-text-lightblue" (click)="deleteStaff(staff.uuId)" translate>移除</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="displayedStaffs.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ selectedStaffs.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: selectedStaffs.length == 0}" (click)="deleteStaffList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ staffs.length }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>