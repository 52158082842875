import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Address, IAddress } from "../addresses/address";
import { IService, Service } from "../services/service";
import { IApproveSignUpUserModel } from "./approve-sign-up-user-model";

export interface ISignUpUser {
    userUUId: string;
    username: string;
    chineseName: string;
    englishName: string;
    gender: string;
    phone: string;
    hasSimCand: boolean;
    dateOfBirth: Date;
    contactPersonName: string;
    contactPersonPhone: string;
    educationLevel: string;
    literacy: string;
    address: IAddress;
    services: IService[];
    signUpAt: Date;
    signUpStatus: string;
    approvedBy: string;
    disapprovedBy: string;
    disapprovedAt: Date;
    disapprovedReason: string;
}

export class SignUpUser {
    userUUId: string;
    username: string;
    chineseName: string;
    englishName: string;
    gender: string;
    phone: string;
    hasSimCand: boolean;
    dateOfBirth: Date;
    contactPersonName: string;
    contactPersonPhone: string;
    educationLevel: string;
    literacy: string;
    address: IAddress;
    services: IService[];
    signUpAt: Date;
    signUpStatus: string;
    approvedBy: string;
    disapprovedBy: string;
    disapprovedAt: Date;
    disapprovedReason: string;

    form: FormGroup;

    constructor(user: ISignUpUser) {
        this.userUUId = user.userUUId;
        this.username = user.username;
        this.chineseName = user.chineseName;
        this.englishName = user.englishName;
        this.gender = user.gender;
        this.phone = user.phone;
        this.hasSimCand = user.hasSimCand;
        this.dateOfBirth = new Date(user.dateOfBirth + 'Z');
        this.contactPersonName = user.contactPersonName;
        this.contactPersonPhone = user.contactPersonPhone;
        this.educationLevel = user.educationLevel;
        this.literacy = user.literacy;
        this.address = new Address(user.address);
        this.services = user.services.map(s => new Service(s));
        this.signUpAt = new Date(user.signUpAt + 'Z');
        this.signUpStatus = user.signUpStatus;
        this.approvedBy = user.approvedBy;
        this.disapprovedBy = user.disapprovedBy;
        this.disapprovedAt = new Date(user.disapprovedAt + 'Z');
        this.disapprovedReason = user.disapprovedReason;
        this.form = new FormGroup({
            memberId: new FormControl(null, [Validators.required]),
            center: new FormControl(null, [Validators.required])
        });
    }

    get age(): number {
        return new Date(Date.now() - (this.dateOfBirth as Date).getTime()).getUTCFullYear() - 1970;
    }

    getApproveModel(): IApproveSignUpUserModel {
        return {
            userUUId: this.userUUId,
            memberId: this.form.controls['memberId'].value,
            centerUUId: this.form.controls['center'].value,
        }
    }
}

