<div class="fadeIn inline-flex-column">
    <div class="flex-row text-lightgrey">
        <p class="text-grey" translate>概覽</p>
    </div>

    <p class="page-title flex-1 mt-8" translate>概覽</p>


    <div class="inline-flex-row mt-8">
        <button class="icon-button red" [matBadge]="numberOfUrgentRequests" [routerLink]="['/main/overview/urgent']">
            <i class="material-icons-round mb-1">priority_high</i>
            <p translate>緊急狀況</p>
        </button>

        <button class="icon-button orange ml-5" routerLink="/main/user/create">
            <i class="material-icons-round mb-1">person_add</i>
            <p translate>創建用戶</p>
        </button>

        <button class="icon-button orange ml-5" [routerLink]="['/main/activity/create/request']">
            <i class="material-icons-round mb-1">inbox</i>
            <p translate>創建申請</p>
        </button>

        <button class="icon-button orange ml-5" [routerLink]="['/main/activity/event-record']">
            <i class="material-icons-round mb-1">note_add</i>
            <p translate>創建紀錄</p>
        </button>
    </div>

    <div class="container mt-5 mb-8" style="box-shadow: none;">
        <div class="container-header">
            <p class="container-header-tab" routerLink="request" routerLinkActive="active" (click)="tab = 'request'" translate>義工服務</p>
            <p class="container-header-tab" routerLink="event" routerLinkActive="active" (click)="tab = 'event'" translate>中心活動</p>
        </div>
        <div class="container-body">
            <div class="inline-flex-column" [@slide]="prepareRoute(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </div>
        </div>
    </div>

    <div class="inline-flex-row">
        <div class="inline-flex-column flex-1">
            <app-balance-analysis [form]="form"></app-balance-analysis>
        </div>
        <div class="inline-flex-column flex-1">
            <app-application-analysis class="ml-8" [form]="form"></app-application-analysis>
        </div>
        <div class="inline-flex-column flex-1">
            <app-success-rate-analysis class="ml-8" [form]="form"></app-success-rate-analysis>
        </div>
    </div>
</div>