import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { AppSetting } from 'src/app/backend/app-setting';
import { Notification } from 'src/app/models/notifications/notification';
import { Profile } from 'src/app/models/profiles/profile';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  orgName: string = AppSetting.orgName;
  profile: Profile | null = null;
  notifications: Notification[] = [];
  lang: string = this.translateService.currentLang;

  constructor(
    private profileService: ProfileService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private firebaseServiec: FirebaseService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.fetch();
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
      localStorage.setItem('lang', value.lang);
    })

    this.firebaseServiec.WebMessageSignal.subscribe((value: any) => {
      this.snackBar.open(value, "Close", { horizontalPosition: 'right', duration: 5000 });
      this.notificationService.getNotifications().subscribe(value => {
        this.notifications = value;
      });
    })
  }

  setLang(language: string): void {
    this.translateService.use(language).subscribe();
  }

  fetch(): void {

    forkJoin({
      profile: this.profileService.getMyProfile(),
      notifications: this.notificationService.getNotifications()
    }).subscribe(value => {
      this.profile = value.profile;
      this.notifications = value.notifications;
      console.log(this.notifications)
    });
  }

  public signOut(): void {
    this.authenticationService.signOut();
    this.router.navigate(['/login']);
  }

  public blur(): void {
    (document.activeElement as any).blur();
  }
}
