<div class="inline-flex-row align-items-center">
    <app-search-input class="flex-1" [placeholder]="'請輸入紀錄編號 或 中心活動名稱'" [size]="'xl'" [action]="searchFilterAction"></app-search-input>

    <button class="icon-button orange" (click)="fetch()">
        <i class="material-icons-round mb-1">refresh</i>
        <p translate>重新整理</p>
    </button>

    <button class="icon-button orange ml-5" [routerLink]="['/main/activity/create/event']">
        <i class="material-icons-round mb-1">inbox</i>
        <p translate>創建申請</p>
    </button>

    <button class="icon-button yellow ml-5" (click)="openImportPopup()">
        <i class="material-icons-round mb-1">unarchive</i>
        <p translate>滙入紀錄</p>
    </button>

    <button class="icon-button yellow ml-5" (click)="exportEventList()">
        <i class="material-icons-round mb-1">archive</i>
        <p translate>匯出所有</p>
    </button>
</div>

<div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
    <app-service-date-range-filter [action]="dateFilterAction"></app-service-date-range-filter>
    <app-event-type-filter [action]="eventTypeFilterAction" class="ml-4"></app-event-type-filter>
    <app-event-transaction-type-filter [action]="eventTransactionTypeFilterAction" class="ml-4"></app-event-transaction-type-filter>
    <app-event-status-filter [action]="statusFilterAction" class="ml-4"></app-event-status-filter>
</div>

<ng-container *ngTemplateOutlet="pagination"></ng-container>


<div class="list-table mt-4 mb-4">
    <div class="list-table-row header">
        <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
            <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p translate>紀錄編號</p>
            <div class="inline-flex-column" (click)="filters.idOrder = !filters.idOrder;filters.dateOrder = null;fetch()">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.idOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.idOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
            <p translate>活動日期</p>
            <div class="inline-flex-column" (click)="filters.dateOrder = !filters.dateOrder;filters.idOrder = null;fetch()">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.dateOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.dateOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p translate>活動代碼</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 13.5rem;flex-grow: 27;">
            <p translate>中心活動名稱</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p matTooltip="{{ '交易類型' | translate }}" [matTooltipPosition]="'above'" translate>交易類型</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
            <p translate>狀態</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 10rem;flex-grow: 20;">
            <p translate>操作</p>
        </div>
    </div>

    <div class="list-table-row" *ngFor="let event of events">
        <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
            <input type="checkbox" [(ngModel)]="event.selected" (change)="cacheEvent(event)">
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p>{{ event.id }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 7.5rem;flex-grow: 15;">
            <p matTooltip="{{event.getEventStartDate() | date: 'dd/MM/yyyy'}} - {{event.getEventEndDate() | date: 'dd/MM/yyyy'}}" matTooltipPosition="above">{{ event.getEventStartDate() | date: 'dd/MM/yyyy' }} - {{ event.getEventEndDate() | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p [matTooltip]="event.eventType.code" matTooltipPosition="above">{{ event.eventType.code }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 13.5rem;flex-grow: 27;">
            <p [matTooltip]="event.eventName" matTooltipPosition="above">{{ event.eventName }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
            <p>{{ event.eventPay | translate }}</p>
        </div>
        <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
            <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && !event.isOngoing">
                <p translate>等待參加</p>
            </div>
            <div class="status green mr-2" *ngIf="event.eventStatus === 'Open' && event.isOngoing">
                <p translate>進行中</p>
            </div>
            <div class="status grey mr-2" *ngIf="event.eventStatus === 'Complete'">
                <p translate>已完成</p>
            </div>
            <div class="status grey mr-2" *ngIf="event.eventStatus === 'Cancel'">
                <p translate>已終止</p>
            </div>
            <div class="status red mr-2" *ngIf="event.eventStatus === 'Open' && event.numberOfParticipants < event.participantVacancy">
                <p translate>人數不足</p>
            </div>
            <div class="status blue" *ngIf="event.lastEditedBy">
                <p translate>已修改</p>
            </div>
            <p></p>
        </div>
        <div class="list-table-cell" style="flex-basis: 10rem;flex-grow: 20;">
            <p class="click-text-lightblue flex-0" [routerLink]="event.uuId" translate>檢視</p>
            <p class="click-text-lightblue flex-0 ml-4" (click)="applyAgain(event)" translate>再次創建</p>
            <p class="click-text-lightblue flex-0 ml-4" (click)="deleteEvent(event.uuId)" translate>移除</p>
        </div>
    </div>

    <div class="list-table-row" *ngIf="events.length == 0">
        <div class="list-table-cell">
            <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
        </div>
    </div>
</div>


<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteEventList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>