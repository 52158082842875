<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>獎勵兌換</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>獎勵概覽</p>
    </div>

    <p class="page-title mt-8" translate>獎勵概覽</p>

    <div class="container mt-5">
        <div class="container-body">
            <div class="inline-flex-row align-items-center">
                <app-search-input class="flex-1" [placeholder]="'請輸入紀錄編號 或 姓名 或會員編號'" [size]="'xl'" [action]="searchFilterAction"></app-search-input>

                <button class="icon-button orange" (click)="fetch()">
                    <i class="material-icons-round mb-1">refresh</i>
                    <p translate>重新整理</p>
                </button>

                <button class="icon-button orange ml-5" routerLink="/main/redeem/create">
                    <i class="material-icons-round mb-1">person_add</i>
                    <p translate>發布獎勵</p>
                </button>

                <button class="icon-button yellow ml-5" (click)="exportProducts()">
                    <i class="material-icons-round mb-1">archive</i>
                    <p translate>匯出選項</p>
                </button>
            </div>

            <div class="inline-flex-row align-items-center mt-3" style="margin-bottom: 1.5rem;">
                <app-center-filter [action]="centerFilterAction"></app-center-filter>
                <app-redeem-type-filter class="ml-4" [action]="typeFilterAction"></app-redeem-type-filter>
                <app-announcement-date-range-filter class="ml-4" [action]="dateFilterAction"></app-announcement-date-range-filter>
                <app-redeem-status-filter class="ml-4" [action]="statusFilterAction"></app-redeem-status-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>
            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>獎勵編號</p>
                        <div class="inline-flex-column" (click)="filters.orderById = !filters.orderById;filters.orderByLaunchDate = null;filters.orderByCloseDate = null;fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.orderById}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.orderById}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 12.5rem;flex-grow: 15;">
                        <p translate>獎勵標題</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p matTooltip="{{ '參與類型' | translate }}" [matTooltipPosition]="'above'" translate>參與類型</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p matTooltip="{{ '發布日期' | translate }}" [matTooltipPosition]="'above'" translate>發布日期</p>
                        <div class="inline-flex-column" (click)="filters.orderByLaunchDate = !filters.orderByLaunchDate;filters.orderById = null;filters.orderByCloseDate = null;fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.orderByLaunchDate}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.orderByLaunchDate}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
                        <p translate>狀態</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>庫存</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6rem;flex-grow: 12;">
                        <p matTooltip="{{ '截止日期' | translate }}" [matTooltipPosition]="'above'" translate>截止日期</p>
                        <div class="inline-flex-column" (click)="filters.orderByCloseDate = !filters.orderByCloseDate;filters.orderById = null;filters.orderByLaunchDate = null;fetch()">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.orderByCloseDate}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.orderByCloseDate}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 9rem;flex-grow: 18;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let product of products">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [(ngModel)]="product.selected" (change)="cache(product)">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ product.id }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 12.5rem;flex-grow: 15;">
                        <p>{{ product.productName }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p *ngIf="product.format == 'FirstComeFirstServed'" matTooltip="{{ '先到先得' | translate }}" [matTooltipPosition]="'above'" translate>先到先得</p>
                        <p *ngIf="product.format != 'FirstComeFirstServed'" matTooltip="{{ '職員處理' | translate }}" [matTooltipPosition]="'above'" translate>職員處理</p>
                    </div>
                    <div class="list-table-cell pl-2" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ product.launchDate | date: 'yyyy/MM/dd' }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 17;">
                        <ng-container *ngIf="product.active">
                            <div class="status yellow mr-2 align-self-center" *ngIf="!product.isLaunched">
                                <p translate>未發布</p>
                            </div>
                            <div class="status green mr-2 align-self-center" *ngIf="product.isLaunched && !product.isClosed">
                                <p translate>進行中</p>
                            </div>
                            <div class="status grey mr-2 align-self-center" *ngIf="product.isClosed">
                                <p translate>已結束</p>
                            </div>
                            <div class="status red mr-2 align-self-center" *ngIf="product.isOutOfStock">
                                <p translate>庫存不足</p>
                            </div>
                        </ng-container>
                        <div class="status red mr-2 align-self-center" *ngIf="product.isPending">
                            <p translate>等待抽籤</p>
                        </div>
                        <div class="status blue mr-2 align-self-center" *ngIf="product.isEdited">
                            <p translate>已修改</p>
                        </div>
                        <div class="status grey mr-2 align-self-center" *ngIf="!product.active">
                            <p translate>已下架</p>
                        </div>

                        <p></p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p *ngIf="product.stock != -1">{{ product.stock }}</p>
                        <p *ngIf="product.stock == -1" matTooltip="{{ '庫存充足' | translate }}" [matTooltipPosition]="'above'" translate>庫存充足</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 6rem;flex-grow: 12;">
                        <p>{{ product.closeDate | date: 'yyyy/MM/dd' }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 9rem;flex-grow: 18;">
                        <p class="click-text-lightblue flex-0" [routerLink]="['/main/redeem/overview', product.uuId]" translate>檢視</p>
                        <p class="click-text-lightblue flex-0 ml-4" (click)="deleteProduct(product.uuId)" translate>移除</p>
                        <p class="click-text-lightblue flex-0 ml-4" (click)="apply(product)" translate>再次創建</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="products.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>
            <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>
    </div>
</div>


<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ caches.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-blue mr-8" [ngClass]="{disabled: caches.length == 0}" (click)="deleteProductList()" translate>批量移除</p>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ count }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1;changePage()">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page" (change)="changePage()">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1;changePage()">chevron_right</i>
    </div>
</ng-template>