import { FormControl, Validators } from '@angular/forms';
import { Center, ICenter } from '../centers/center';
import { EventParticipantExportModel } from '../exports/event-participant-export-model';
import { Transaction } from '../transactions/transaction';

export interface IEventParticipant {
    uuId: string;
    chineseName: string;
    phone: string;
    gender: string;
    dateOfBirth: Date;
    englishName: string;
    memberId: string;
    center: ICenter;
    state: string;
    lastReadEdited: string;
    timeBalance:Number;
}

export class EventParticipant {
    uuId: string;
    chineseName: string;
    phone: string;
    gender: string;
    dateOfBirth: Date;
    englishName: string;
    memberId: string;
    center: Center | null;
    state: string;
    lastReadEdited: Date;
    timeBalance:Number;

    transaction: Transaction | null;

    selected: boolean;
    editing: boolean;
    form: FormControl;

    constructor(participant: IEventParticipant, transactions?: Transaction[]) {
        this.uuId = participant.uuId;
        this.chineseName = participant.chineseName;
        this.phone = participant.phone;
        this.gender = participant.gender;
        this.dateOfBirth = typeof (participant.dateOfBirth) == 'string' ? new Date(participant.dateOfBirth + 'Z') : participant.dateOfBirth;
        this.englishName = participant.englishName;
        this.memberId = participant.memberId;
        this.center = participant.center ? new Center(participant.center) : null;
        this.state = participant.state;
        this.lastReadEdited = new Date(participant.lastReadEdited + 'Z');
        this.timeBalance=participant.timeBalance;
        var existTransaction = transactions ? transactions.find(t => t.transferee.uuId === this.uuId || t.transferer.uuId === this.uuId) : null;

        this.transaction = existTransaction ? existTransaction : null;

        if (this.transaction?.status == 'Completed' && this.state != 'Absent')
            this.state = 'Confirmed';

        this.selected = false;
        this.editing = false;

        this.form = new FormControl(null, Validators.required);
    }

    getExportModel(): EventParticipantExportModel {
        return {
            "會員編號": this.memberId,
            "中文姓名": this.chineseName,
            "英文姓名": this.englishName,
            "中心編號": this.center?.centerCode,
            "性別": this.gender == "M" ? "男" : this.gender == "F" ? "女" : "",
            "年齡": this.age.toString(),
            "電話": this.phone,
            "狀態": this.state == "Absent" ? "缺席" :
                this.state == "Rejected" ? "已拒絕" :
                    this.state == "Asked" ? "等待回應" :
                        this.state == "Interested" ? "接受邀請" :
                            this.state == "Unselected" ? "未被選中" :
                                this.state == "Confirmed" && this.transaction == null ? "確認參加" : "已完成",
            "交易時分": this.transaction ? this.transaction.amount.toString() : "暫無",
            "交易編碼": this.transaction ? this.transaction.id.toString() : "暫無",
            "完成時間": this.transaction ? this.transaction.date.toJSON().split('T')[0] + ' ' + this.transaction.date.toTimeString().substr(0, 5) : "暫無"
        }
    }

    get age(): number {
        return new Date(Date.now() - (this.dateOfBirth as Date).getTime()).getUTCFullYear() - 1970;
    }
}
