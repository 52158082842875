import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CellGroup } from 'src/app/models/cell-groups/cell-group';
import { Center } from 'src/app/models/centers/center';
import { Health } from 'src/app/models/healths/health';
import { Living } from 'src/app/models/livings/living';
import { Profile } from 'src/app/models/profiles/profile';
import { Service } from 'src/app/models/services/service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';

@Component({
  selector: 'app-select-requester-page',
  templateUrl: './select-requester-page.component.html',
  styleUrls: ['./select-requester-page.component.scss']
})
export class SelectRequesterPageComponent implements OnInit {

  count: number = 0;
  pages: number = 0;
  profiles: Profile[] = [];
  caches: string[] = [];

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    centers: [],
    districts: [],
    subDistricts: [],
    services: [],
    conditions: [],
    healths: [],
    genders: [],
    memberIdOrder: true,
    creditOrder: null,
    cellGroup:[]
  };

  constructor(
    private requestService: RequestService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });

    // this.route.params.subscribe(value => {
    //   this.caches = this.requestService.form.requester ? [this.requestService.form.requester] : [];
    // })
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.profileService.getProfileList(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.profiles = value.list;
          this.count = value.count;
          this.pages = Math.ceil(value.count / this.filters.itemsPerPage);
          this.profiles.forEach(profile => {
            if (this.caches.find(u => u == profile.uuId))
              profile.selected = true;
          });
        }
      });
  }

  cacheSelectedProfile(profile: Profile): void {
    if (profile.selected)
      this.caches.push(profile.uuId);
    else
      this.caches.splice(this.caches.findIndex(u => u == profile.uuId), 1);
  }

  changePage(): void {
    this.router.navigate(['/main/activity/create/request/select-requester'], { queryParams: { page: this.filters.page, itemsPerPage: this.filters.itemsPerPage } })
  }

  searchFilterAction = ((value: string | any) => {
    this.filters.searchInput = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  centerFilterAction = ((value: Center[] | any) => {
    this.filters.centers = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  conditionFilterAction = ((genders: string[] | any, healths: Health[] | any, livings: Living[] | any) => {
    this.filters.genders = genders;
    this.filters.healths = healths;
    this.filters.conditions = livings;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);


  private getQueryString(): string {
    return "?start=" + (this.filters.page - 1) * this.filters.itemsPerPage
      + "&limit=" + this.filters.itemsPerPage
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.centers.map((center: Center) => "&centers=" + center.uuId).join(""))
      + (this.filters.services.map((service: Service) => "&services=" + service.uuId).join(""))
      + (this.filters.districts.map((district: string) => "&districts=" + district).join(""))
      + (this.filters.subDistricts.map((subDistrict: string) => "&subDistricts=" + subDistrict).join(""))
      + (this.filters.conditions.map((condition: Living) => "&livingConditions=" + condition.uuId).join(""))
      + (this.filters.healths.map((health: Health) => "&healthConditions=" + health.uuId).join(""))
      + (this.filters.genders.map((gender: string) => "&gender=" + gender).join(""))
      + (this.filters.memberIdOrder != null ? "&orderByMemberId=" + String(this.filters.memberIdOrder) : "")
      + (this.filters.creditOrder != null ? "&orderByBalance=" + String(this.filters.creditOrder) : "")
      + (this.filters.cellGroup.map((cellGroup: CellGroup) => "&cellGroup=" + cellGroup.uuId).join(""))
  }

  cellGroupFilterAction = ((center: Center[]) => {
    this.filters.cellGroup=[];
    center.forEach(c=>c.cellGroups.forEach(cg=>cg.selected?this.filters.cellGroup.push(cg):null))
    console.log(this.filters.cellGroup);
    this.fetch();
  }).bind(this);

  submit(): void {
    if (this.caches.length == 0) {
      this.requestService.form.requester = null;
      this.router.navigate(['/main/activity/create/request']);
      return;
    }

    this.requestService.form.requester = this.caches[0];
    this.router.navigate(['/main/activity/create/request']);
  }

  back(): void {
    this.router.navigate(['/main/activity/create/request']);
  }

  get selectedUser(): string | null {
    return this.caches.length > 0 ? this.caches[0] : null;
  }
}
