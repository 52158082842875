<div class="address-box">
    <div class="address-box-header">
        <p class="required flex-1" translate>預設地址</p>
        <p class="click-text-lightblue" *ngIf="index > 1" (click)="dropAddress()" translate>移除</p>
    </div>

    <div class="address-box-body">
        <div class="inline-flex-row">
            <p class="title">{{ '地址#' | translate: {value: index} }}</p>
            <form class="address-form" [formGroup]="form.formGroup">
                <div class="user-select">
                    <select class="user-select lg" formControlName="region" (change)="district.reset()">
                        <option [ngValue]="null" selected disabled translate>區域*</option>
                        <option *ngFor="let region of regions" [ngValue]="region.name">{{ region.name | translate }}</option>
                    </select>
                </div>

                <div class="user-select">
                    <select class="user-select lg ml-4" formControlName="district">
                        <option [ngValue]="null" selected disabled translate>分區*</option>
                        <ng-container *ngIf="selectedRegion">
                            <option *ngFor="let district of selectedRegion.districts" [ngValue]="district.name">{{ district.name | translate }}</option>
                        </ng-container>
                    </select>
                </div>

                <input class="user-input lg mt-4" type="text" formControlName="subDistrict" placeholder="{{ '細區' | translate }}">
                <input class="user-input lg mt-4 ml-4" type="text" formControlName="street" placeholder="{{ '街道' | translate }}">
                <input class="user-input lg mt-4" type="text" formControlName="streetNumber" placeholder="{{ '街號' | translate }}">
                <input class="user-input lg mt-4 ml-4" type="text" formControlName="estate" placeholder="{{ '屋苑/屋邨*' | translate }}">
                <input class="user-input lg mt-4" type="text" formControlName="building" placeholder="{{ '大廈/期/座' | translate }}">
                <input class="user-input lg mt-4 ml-4" type="text" formControlName="floor" placeholder="{{ '樓層' | translate }}">
                <input class="user-input lg mt-4" type="text" formControlName="room" placeholder="{{ '單位' | translate }}">
            </form>
        </div>

        <div class="inline-flex-row mt-6">
            <p class="title" translate>{{ '居住條件 #' | translate: {value: index} }}</p>
            <div class="status-box">
                <div class="inline-flex-row" style="height: 2rem;" *ngFor="let living of form.livings; let index = index">
                    <div class="living-status align-self-center mr-4">
                        <p>{{ living.name }}</p>
                        <i class="material-icons-round close-icon" (click)="dropLiving(index)">close</i>
                    </div>
                </div>
                <i class="material-icons-round add-icon" (click)="living_popup.open(form.livings)">add_circle</i>
            </div>
        </div>

        <app-living-popup #living_popup [action]="livingPopupAction"></app-living-popup>
    </div>
</div>