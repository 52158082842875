<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>用戶</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>創建用戶</p>
    </div>

    <p class="page-title mt-8" translate>創建用戶</p>

    <div class="container mt-5">
        <form class="container-body" [formGroup]="form.formGroup">

            <div class="account-box">
                <div class="title-box">
                    <p class="title text-bold" translate>帳號信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員編號</p>
                    <input class="user-input xs" type="text" placeholder="{{ '編號' | translate }}" formControlName="memberId">
                    <div class="user-input-error zoomIn" *ngIf="memberId.touched && memberId.errors">
                        <p *ngIf="memberId.errors.required" translate>請輸入會員編號</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入帳號</p>
                    <input class="user-input xs" type="text" placeholder="{{ '登入帳號' | translate }}" formControlName="username">
                    <div class="user-input-error zoomIn" *ngIf="username.touched && username.errors">
                        <p *ngIf="username.errors.required" translate>請輸入登入帳號</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>登入密碼</p>
                    <input class="user-input pw" type="text" placeholder="{{ '至少4個字符，必須包含數字' | translate }}" formControlName="password">
                    <div class="user-input-error zoomIn" *ngIf="password.touched && password.errors">
                        <p *ngIf="password.errors.required" translate>請輸入帳號密碼</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>所屬中心</p>
                    <div class="user-select">
                        <select class="user-select lg" formControlName="center">
                            <option [ngValue]=null selected disabled translate>請選擇中心</option>
                            <option *ngFor="let center of centers" [ngValue]="center.uuId">{{ center.name }}</option>
                        </select>
                    </div>
                    <div class="user-input-error zoomIn" *ngIf="center.touched && center.errors">
                        <p *ngIf="center.errors.required" translate>請選擇所屬中心</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>時分餘額</p>
                    <input class="user-input xs" type="number" placeholder="{{ '系統填寫' | translate }}" disabled>
                </div>

            </div>

            <div class="information-box">
                <div class="title-box">
                    <p class="title text-bold" translate>基本信息</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required" translate>會員姓名</p>
                    <input class="user-input xs" type="text" placeholder="{{ '中文姓名*' | translate }}" formControlName="chineseName">
                    <input class="user-input xl ml-6" type="text" placeholder="{{ 'English Name' | translate }}" formControlName="englishName">
                    <div class="user-input-error zoomIn" *ngIf="chineseName.touched && chineseName.errors">
                        <p *ngIf="chineseName.errors.required" translate>請輸入中文姓名</p>
                    </div>
                    <div class="user-input-error zoomIn" *ngIf="!chineseName.errors && englishName.touched && englishName.errors">
                        <p *ngIf="englishName.errors.required" translate>請輸入英文姓名</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>性別</p>
                    <label class="align-items-center" style="margin-right: 2.75rem;">
                        <input type="radio" value="M" formControlName="gender">
                        <p translate>男</p>
                    </label>
                    <label class="align-items-center">
                        <input type="radio" value="F" formControlName="gender">
                        <p translate>女</p>
                    </label>
                    <div class="user-input-error zoomIn" *ngIf="gender.touched && gender.errors">
                        <p *ngIf="gender.errors.required" translate>請選擇性別</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>電話號碼</p>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center">
                            <input class="user-input xs" type="text" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話*' | translate }}">
                            <label class="align-items-center">
                                <input class="ml-6 mr-4" type="checkbox">
                                <p translate>智能電話</p>
                            </label>
                        </div>

                        <input class="user-input xl mt-4" type="text" formControlName="phoneModel" placeholder="{{ '電話型號' | translate }}">
                        <div class="user-input-error zoomIn" *ngIf="phone.touched && phone.errors">
                            <p *ngIf="phone.errors.required" translate>請輸入電話號碼</p>
                        </div>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" translate>出生日期</p>
                    <input class="user-input xs" [matDatepicker]="picker" formControlName="birthday" (click)="picker.open()" placeholder="{{ '出生日期' | translate }}" readonly>
                    <input class="user-input xs ml-3" [ngModel]="age" [ngModelOptions]="{standalone: true}" type="number" placeholder="{{ '自動推算年齡' | translate }}" readonly disabled>
                    <mat-datepicker startView="multi-year" disabled="false" touchUi #picker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                    <div class="user-input-error zoomIn" *ngIf="birthday.touched && birthday.errors">
                        <p *ngIf="birthday.errors.required" translate>請輸入出生日期</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title" translate>是否有流動數據</p>
                    <label class="align-items-center" style="margin-right: 2.75rem;">
                        <input type="radio" [value]="true" formControlName="simCard">
                        <p translate>有</p>
                    </label>
                    <label class="align-items-center">
                        <input type="radio" [value]="false" formControlName="simCard">
                        <p translate>沒有</p>
                    </label>

                    <div class="user-input-error zoomIn" *ngIf="simCard.touched && simCard.errors">
                        <p *ngIf="simCard.errors.required" translate>請選擇網卡配備</p>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>細胞小組</p>
                    <div class="inline-flex-row align-items-center" *ngIf="form.cellGroups.length == 0">
                        <p class="text-lightgrey" translate>未指定</p>
                        <i class="material-icons-round add-icon ml-4" (click)="cellGroup_popup.open(form.cellGroups)">add_circle</i>
                        <i class="material-icons-round info-icon" style="margin-left: 1.25rem;">help_outline</i>
                        <p class="ml-4 text-xs" translate>同一細胞小組的會員在義工推薦時優先顯示</p>
                    </div>

                    <div class="status-box" *ngIf="form.cellGroups.length > 0">
                        <div class="inline-flex-row" style="height: 2rem;" *ngFor="let cellGroup of form.cellGroups">
                            <div class="cell-group-status align-self-center mr-4">
                                <p>{{ cellGroup.name }}</p>
                            </div>
                        </div>
                        <i class="material-icons-round add-icon" (click)="cellGroup_popup.open(form.cellGroups)">add_circle</i>
                        <i class="material-icons-round info-icon" style="margin-left: 1.25rem;">help_outline</i>
                        <p class="ml-4 text-xs" translate>同一細胞小組的會員在義工推薦時優先顯示</p>
                    </div>
                </div>

                <app-cell-group-popup #cellGroup_popup [action]="cellGroupPopupAction"></app-cell-group-popup>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>代理人</p>
                    <div class="inline-flex-column" *ngIf="form.agents.length == 0">
                        <div class="inline-flex-row align-items-center" style="height: 2rem;">
                            <p class="text-lightgrey" translate>未指定，請在用戶列表中確認人選</p>
                            <i class="material-icons-round info-icon ml-8">help_outline</i>
                            <p class="text-xs ml-3 text-lightgrey" translate>代理人帳號可代該用戶帳號申請義工服務和中心活動</p>
                        </div>

                        <button type="button" class="button outline blue sm mt-4" routerLink="agent" translate>用戶列表</button>
                    </div>

                    <div class="inline-flex-column" *ngIf="form.agents.length > 0">
                        <div class="inline-flex-row align-items-center">
                            <button type="button" class="button outline blue sm" routerLink="agent">重新選擇</button>
                            <i class="material-icons-round info-icon ml-9">help_outline</i>
                            <p class="text-xs ml-4 text-lightgrey" translate>代理人帳號可代該用戶帳號申請義工服務</p>
                        </div>


                        <div class="inline-flex-row align-items-center mt-4">
                            <p translate>已選擇以下</p>
                            <p class="ml-2 mr-2 text-lightblue">{{ form.agents.length }}</p>
                            <p translate>位會員</p>
                        </div>

                        <div class="agent-table mt-2">
                            <div class="agent-table-row header">
                                <div class="agent-table-cell">
                                    <p translate>會員編號</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>中文姓名</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>中心代碼</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>電話</p>
                                </div>
                                <div class="agent-table-cell remark">
                                    <p class="ml-4'" translate>備註</p>
                                </div>
                            </div>
                            <div class="agent-table-row" *ngFor="let agent of form.agents; let index = index">
                                <div class="agent-table-cell">
                                    <p translate>{{ agent.memberId}}</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>{{ agent.chineseName }}</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>{{ agent.centerCode}}</p>
                                </div>
                                <div class="agent-table-cell">
                                    <p translate>{{ agent.phone }}</p>
                                </div>
                                <div class="agent-table-cell remark">
                                    <input class="user-input remark" type="text" [formControl]="agent.remark" placeholder="{{ '不超過6個字' | translate }}">
                                    <p class="click-text-blue text-xs" style="margin-left: 1.75rem;margin-right: 1rem;" (click)="dropAgent(index)">移除</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="input-row">
                    <p class="title" translate>緊急聯絡人</p>
                    <input class="user-input xs" type="text" formControlName="contactPersonName" placeholder="{{ '中文姓名' | translate }}">
                    <input class="user-input xl ml-6" type="text" formControlName="contactPersonPhone" placeholder="{{ '聯絡電話號碼' | translate }}">
                </div>

                <div class="input-row">
                    <p class="title align-self-start" style="line-height: 2rem;" translate>身體狀況</p>
                    <div class="inline-flex-row align-items-center" *ngIf="form.healths.length == 0">
                        <p class="text-lightgrey" translate>未設置</p>
                        <i class="material-icons-round add-icon ml-4" (click)="health_popup.open(form.healths)">add_circle</i>
                    </div>

                    <div class="status-box" *ngIf="form.healths.length > 0">
                        <div class="inline-flex-row" style="height: 2rem;" *ngFor="let health of form.healths">
                            <div class="health-status align-self-center mr-4">
                                <p>{{ health.name }}</p>
                                <i class="material-icons-round ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest">visibility</i>
                                <i class="material-icons-outlined ml-2" style="font-size: 1rem;" *ngIf="health.isShowInRequest == false">visibility_off</i>
                            </div>
                        </div>
                        <i class="material-icons-round add-icon" (click)="health_popup.open(form.healths)">add_circle</i>
                    </div>
                </div>

                <app-health-popup #health_popup [action]="healthPopupAction"></app-health-popup>

                <div class="input-row">
                    <p class="title" translate>教育程度</p>
                    <div class="user-select">
                        <select class="user-select xs" formControlName="literacy">
                            <option [ngValue]=null selected disabled>讀寫能力</option>
                            <option value="CanReadAndWrite" translate>能讀能寫</option>
                            <option value="CanRead" translate>只能讀，不能寫</option>
                            <option value="CanSpeak" translate>不懂文字</option>
                        </select>
                    </div>
                    <div class="user-select ml-4">
                        <select class="user-select xs" formControlName="educationLevel">
                            <option [ngValue]=null selected disabled>教育程度</option>
                            <option value="University" translate>大學或以上</option>
                            <option value="Secondary" translate>中學</option>
                            <option value="Primary" translate>小學</option>
                            <option value="None" translate>不適用</option>
                        </select>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="literacy.touched && literacy.errors">
                        <p *ngIf="literacy.errors.required" translate>請選擇讀寫能力</p>
                    </div>

                    <div class="user-input-error zoomIn" *ngIf="!literacy.errors && educationLevel.touched && educationLevel.errors">
                        <p *ngIf="educationLevel.errors.required" translate>請選擇教育程度</p>
                    </div>
                </div>       
                 <div class="input-row" style="margin-top: 2rem;margin-bottom: 2rem;">
                    <p class="title align-self-start" translate >備註</p>
                    <div>
                        <div class="text-sm text-lightgrey" translate style="margin-bottom: 0.5rem;">備註僅職員可見</div>
                        <textarea style="width: 22rem; height: 8.625rem;"  class="user-input" type="text" formControlName="remark" placeholder="{{ '備註' | translate }}"></textarea>
                   
                    </div>
                     <!-- <p *ngIf="readonly == true">{{profile.remark}}</p> -->
                </div>
            </div>

            <div class="address-box">
                <div class="title-box">
                    <p class="title text-bold" translate>地址信息</p>
                    <p class="text-sm text-lightgrey" translate>居住條件選項可在後台設置中增加/編輯</p>
                </div>

                <ng-container *ngFor="let address of form.addresses;let index = index">
                    <app-address-form class="ml-7" style="margin-top: 2rem;" [form]="address" [index]="index+1" [dropAction]="dropAddressAction"></app-address-form>
                </ng-container>

                <div class="add-address-button ml-7" (click)="form.addAddress()">
                    <p class="text-lightgrey" translate>增添地址</p>
                </div>
            </div>

            <div class="volunteer-box">
                <div class="title-box">
                    <p class="title text-bold" translate>義工服務信息</p>
                    <p class="text-sm text-lightgrey" translate>義工會員須填寫，系統會根據以下信息在邀請義工時篩選適合的義工會員，非義工會員可不填</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>空閒時間</p>
                    <ng-container *ngIf="form">
                        <app-availability-table #availability [availabilities]="form.availabilities"></app-availability-table>
                    </ng-container>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" style="line-height: 2rem;" translate>可提供服務</p>
                    <div class="inline-flex-row align-items-center" *ngIf="form.services.length == 0">
                        <p class="text-lightgrey" translate>未設置</p>
                        <i class="material-icons-round add-icon ml-4" (click)="service_popup.open(form.services)">add_circle</i>
                    </div>

                    <ng-container *ngIf="form.services.length > 0">
                        <ng-container *ngTemplateOutlet="service_template"></ng-container>
                    </ng-container>
                </div>

                <app-service-popup #service_popup [action]="servicePopupAction"></app-service-popup>
            </div>

            <div class="inline-flex-row align-items-center justify-content-center" style="margin-bottom: 5.1875rem;">
                <button type="button" class="button outline" (click)="reset()" translate>取消</button>
                <button type="submit" class="button fill blue" style="margin-left: 6.125rem;" (click)="submit()" translate>確認創建</button>
            </div>
        </form>

    </div>
</div>

<ng-template #service_template>
    <div class="service-box">
        <div class="service-box-header mb-4">
            <p class="text-sm text-lightgrey">該會員可提供以下義工服務，系統根據可供服務和空閒時間進行義工推薦</p>
        </div>
        <div class="service-box-body">
            <div class="service-type-column">
                <div class="service-type-item" [ngClass]="{active: index == i}" (click)="index = i" *ngFor="let serviceType of selectedServiceTypes;let i = index">
                    <p class="flex-1">{{ serviceType.name }}</p>
                    <p class="text-sm text-lightgrey">{{ serviceType.selectedServices.length }}</p>
                </div>
            </div>
            <div class="service-column">
                <div class="service-item" *ngFor="let service of selectedServiceTypes[index].selectedServices">
                    <p style="flex: 0 0 5.25rem;">{{ service.servicesCode }}</p>
                    <p style="flex: 0 0 7rem;">{{ service.name }}</p>
                    <i class="material-icons-round close-icon" (click)="dropService(service)">close</i>
                </div>
            </div>
        </div>
        <div class="inline-flex-row align-items-center mt-7">
            <p class="mr-3 text-lightgrey">新增服務</p>
            <i class="material-icons-round add-icon" (click)="service_popup.open(form.services)">add_circle</i>
        </div>
    </div>
</ng-template>