<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>會員</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>申請審核列表</p>
    </div>

    <p class="page-title mt-8" translate>帳號申請設置</p>

    <div class="container mt-5">
        <form class="container-body" *ngIf="form" [formGroup]="form">
            <div class="inline-flex-column ml-9">
                <div class="inline-flex-row align-items-center">
                    <p class="head-title" matTooltip="{{ '功能設置' | translate }}" [matTooltipPosition]="'above'" translate>功能設置</p>
                    <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                </div>

                <div class="input-row">
                    <p class="title required align-self-start" matTooltip="{{ '註冊功能' | translate }}" [matTooltipPosition]="'above'" translate>註冊功能</p>
                    <div class="inline-flex-column">
                        <div class="inline-flex-row align-items-center">
                            <p class="text-grey" translate>機構是否開啟會員帳號申請功能</p>
                            <i class="ml-4 material-icons-round info-icon">help_outline</i>
                            <p class="ml-4 text-xs text-grey" translate>該功能允許用戶可由App選擇想加入的機構並遞交會員申請資料，如職員審核通過，即默認成為機構的會員</p>
                        </div>
                        <div class="inline-flex-row mt-8">
                            <label>
                                <input type="radio" [value]="true" formControlName="openRegistration">
                                <p class="lh-8 ml-4 text-grey" translate>是，可在app中申請會員帳號</p>
                            </label>
                            <label style="margin-left: 2.75rem;">
                                <input type="radio" [value]="false" formControlName="openRegistration">
                                <p class="lh-8 ml-4 text-grey" translate>否，僅職員可在後台創建會員帳號</p>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="input-row">
                    <p class="title required" matTooltip="{{ '諮詢熱線' | translate }}" [matTooltipPosition]="'above'" translate>諮詢熱線</p>
                    <input type="text" class="user-input xs" minlength="8" maxlength="8" formControlName="phone" placeholder="{{ '電話*' | translate }}">
                    <i class="ml-4 material-icons-round info-icon">help_outline</i>
                    <p class="ml-4 text-xs text-grey" translate>該聯絡號碼</p>
                </div>

            </div>

            <ng-container *ngIf="openRegistration == true">
                <div class="border-bottom" style="margin-top: 2rem;margin-bottom: 1.5rem;"></div>

                <div class="inline-flex-column ml-9">
                    <div class="inline-flex-row align-items-center">
                        <p class="head-title" translate>申請條款</p>
                        <p class="text-sm text-lightgrey" translate>欄目帶 * 號為必填項</p>
                    </div>

                    <div class="input-row">
                        <p class="text-grey" style="margin-left: 0.875rem;">帳號申請前用戶必須瀏覽並同意以下條款才可開始填寫申請資料</p>
                    </div>

                    <div class="input-row">
                        <p class="title required" translate>條款標題</p>
                        <input type="text" class="user-input xl" formControlName="title" placeholder="{{ '請輸入規則標題' | translate }}">
                    </div>

                    <div class="input-row">
                        <p class="title required align-self-start lh-8" translate>條款詳情</p>
                        <textarea style="width: 22rem; height: 37.4375rem;" formControlName="statement" placeholder="{{ '請輸入機構介紹/會員申請資格說明/私隱收集聲明/其他相關條款' | translate }}"></textarea>
                    </div>
                </div>
            </ng-container>

            <div class="inline-flex-row justify-content-center" style="margin-top: 2.625rem;">
                <button class="button fill blue" (click)="onSave()" translate>儲 存</button>
            </div>
        </form>
    </div>
</div>