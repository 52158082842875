<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="member-box zoomIn" *ngIf="cellGroup" (click)="$event.stopPropagation()">

        <div class="member-box-header">
            <p class="member-title">{{ cellGroup.name }}</p>

            <div class="inline-flex-row align-items-center">
                <i class="material-icons add-icon">add_circle</i>
                <p class="member-add" [routerLink]="['/main/system', cellGroup.uuId, 'select-member']" translate>新增組員</p>
            </div>
        </div>

        <div class="member-box-body">
            <app-search-input [action]="searchAction" [placeholder]="'請輸入姓名 或 會員編號'"></app-search-input>
            <div class="inline-flex-row align-items-center" style="margin-top: 0.6875rem;">
                <p translate>共有</p>
                <p class="ml-4 mr-4 text-blue">{{ members.length }}</p>
                <p translate>位用戶屬於該小組</p>
            </div>

            <div class="user-table mt-3">
                <div class="user-table-row header">
                    <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 33;">
                        <p translate>會員編號</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                        <p translate>中文姓名</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                        <p translate>中心代碼</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                        <p translate>性別</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                        <p translate>年齡</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                        <p translate>電話</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                        <p translate>身分</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                        <p translate>時分</p>
                    </div>
                    <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="user-table-scroll-box">
                    <div class="user-table-row" *ngFor="let member of filteredMembers">
                        <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 33;">
                            <p>{{ member.memberId }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                            <p>{{ member.chineseName }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 22;">
                            <p>{{ member.center.centerCode }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                            <p>{{ member.gender | translate }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 4.125rem; flex-grow: 33;">
                            <p>{{ member.age }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                            <p>{{ member.phone }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                            <p *ngIf="member.isLeader" translate>組長</p>
                            <i *ngIf="member.isLeader" class="material-icons-round star">star_rate</i>
                            <p *ngIf="!member.isLeader" translate>組員</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                            <p>{{ member.timeBalance }}</p>
                        </div>
                        <div class="user-table-cell" style="flex-basis: 11rem;flex-grow: 88;">
                            <p class="click-text-blue ml-4" [routerLink]="['/main/user/overview', member.uuId]" translate>檢視</p>
                            <p class="click-text-blue ml-4" *ngIf="member.isLeader == false" (click)="addLeader(member)" translate>設為組長</p>
                            <p class="click-text-blue ml-4" *ngIf="member.isLeader == true" (click)="removeLeader(member)" translate>取消組長</p>
                            <p class="click-text-blue ml-4" (click)="removeMemberFromCellGroup(member.uuId)" translate>移出</p>
                        </div>
                    </div>
                </div>

                <div class="user-table-row" *ngIf="filteredMembers.length === 0">
                    <div class="user-table-cell">
                        <p class="text-grey" translate>沒有紀錄</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="member-box-footer">
            <button class="button outline" (click)="close()" translate>返回</button>
        </div>
    </div>
</div>