import { EventEmitter, Injectable } from '@angular/core';
import { concat, defer, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { ICellGroup } from 'src/app/models/cell-groups/cell-group';
import { ICenter } from 'src/app/models/centers/center';
import { IEventType } from 'src/app/models/event-types/event-type';
import { IHealth } from 'src/app/models/healths/health';
import { DonationImportModel, IDonationImportModel } from 'src/app/models/imports/donation-import-model';
import { EventImportModel, IEventImportModel } from 'src/app/models/imports/event-import-model';
import { IRequestImportModel, RequestImportModel } from 'src/app/models/imports/request-import-model';
import { IUserImportModel, UserImportModel } from 'src/app/models/imports/user-import-model';
import { ILiving } from 'src/app/models/livings/living';
import { IService } from 'src/app/models/services/service';
import { WorkBook, WorkSheet, read, utils, writeFile } from "xlsx";
import { AuthenticationService } from '../authenticate/authentication.service';
import { CellGroupService } from '../cell-group/cell-group.service';
import { CenterService } from '../center/center.service';
import { DonationService } from '../donation/donation.service';
import { ErrorHandleService } from '../error-handle/error-handle.service';
import { EventTypeService } from '../event-type/event-type.service';
import { EventService } from '../event/event.service';
import { HealthService } from '../health/health.service';
import { LivingService } from '../living/living.service';
import { ProfileService } from '../profile/profile.service';
import { RequestService } from '../request/request.service';
import { ServiceService } from '../service/service.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  public refreshSignal: EventEmitter<any> = new EventEmitter();

  currentTask: { rate: number; text: string | null; } = {
    rate: 0,
    text: null
  };


  importPopupSignal: EventEmitter<any> = new EventEmitter();
  importTaskSignal: EventEmitter<Observable<any>> = new EventEmitter();
  public interceptorSignal: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private httpService: HttpService,
    private profileService: ProfileService,
    private serviceService: ServiceService,
    private livingService: LivingService,
    private healthService: HealthService,
    private centerService: CenterService,
    private cellGroupService: CellGroupService,
    private authService: AuthenticationService,
    private requestService: RequestService,
    private errorHandleService: ErrorHandleService,
    private eventTypeService: EventTypeService,
    private eventService: EventService,
    private donationService: DonationService
  ) { }

  uploadExcel(file: File): Observable<any> {
    const fileData = new FormData();
    fileData.append('file', file, `${file.name}_${new Date().toISOString()}`);

    return this.httpService.upload("ExcelBackup", fileData);
  }

  parseUserExcel(file: File): Observable<UserImportModel[]> {
    return new Observable<any>(subscriber => {
      //Register Reader
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {

        /* read workbook */
        const binaryString: string = (e.target as FileReader).result as string;
        const workBook: WorkBook = read(binaryString, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = workBook.SheetNames[0];
        const workSheet: WorkSheet = workBook.Sheets[workSheetName];

        /* read sheet */
        var users: IUserImportModel[] = utils.sheet_to_json(workSheet, { raw: false, range: 5, blankrows: false });
        subscriber.next(users.map((user, index) => new UserImportModel(user, index)));
        subscriber.complete();
      };

      reader.onerror = (e: any) => {
        subscriber.error(e);
      };

      reader.readAsBinaryString(file);
    });
  }

  processUserData(models: UserImportModel[]): Observable<any> {
    return new Observable(subscriber => {
      var services: IService[] = [];
      var healths: IHealth[] = [];
      var livingConditions: ILiving[] = [];
      var centers: ICenter[] = [];
      var cellGroups: ICellGroup[] = [];

      this.currentTask.text = "正在處理用戶數㨿中。";

      forkJoin({
        u: this.profileService.getUUIdByMemberId({ list: models.map(m => m.memberId) }),
        s: this.serviceService.getServiceTypeList(),
        h: this.healthService.getHealthConditionList(),
        l: this.livingService.getLivingConditionList(),
        c: this.centerService.getCenters(),
        cellGroups: this.cellGroupService.getCellGroupList()
      }).subscribe(
        response => {

          //Replace Service, LivingCondition, CenderCode, Health with UUID
          services = response.s.map(st => st.services).reduce((pre, cur) => pre.concat(cur), []);
          healths = response.h;
          livingConditions = response.l;
          centers = response.c;
          cellGroups = response.cellGroups;

          models.forEach((user, index) => {
            user.uuId = response.u[index];
            var center = centers[centers.findIndex(c => c.centerCode === user.profile.CenterCode)];
            user.profile.CenterCode = center ? center.uuId : null;
            user.profile.services.services = (user.profile.services.services as string[])
              .map(service => services[services.findIndex(s => s.name === service)])
              .map(s => s ? s.uuId : null);
            user.profile.healthConditions.healthConditions = (user.profile.healthConditions.healthConditions as string[])
              .map(health => healths[healths.findIndex(h => h.name === health)])
              .map(h => h ? h.uuId : null);
            user.profile.cellGroups = (user.profile.cellGroups as string[])
              .map(group => cellGroups[cellGroups.findIndex(cg => cg.name === group)])
              .map(group => group ? group.uuId : null);
            user.profile.addresses[0].livingConditions = (user.profile.addresses[0].livingConditions as string[])
              .map(livingCondition => livingConditions[livingConditions.findIndex(lv => lv.name === livingCondition)])
              .map(lv => lv ? lv.uuId : null);
          });

          //Validate User Before Sending to Server
          var errors: (string | null)[] = models.map(user => user.selfValidate());
          if (errors.some(error => error != null)) {
            subscriber.error({ status: "IMPORT_ERROR", error_message: errors.join('') });
            subscriber.complete();
            return;
          }

          //Turn off Interceptor
          this.interceptorSignal.emit(false);

          //Import Data to Server
          this.importUsers(models)
            .pipe(finalize(() => this.interceptorSignal.emit(true)))
            .subscribe({
              next: value => { },
              error: value => subscriber.error(value),
              complete: () => subscriber.complete()
            });
        },
        (error) => subscriber.error({ status: "IMPORT_ERROR", error_message: "匯入時出現錯誤，請重新嘗試。" }),
        () => { }
      );
    });
  }

  importUsers(models: UserImportModel[]): Observable<any> {
    let finishedTask: number = 0;
    let errorModels: any[] = [];

    return concat(...models.map(model => model.uuId === null ?
      defer(() => {
        this.currentTask.text = "正在註冊" + model.profile.chineseName + "的帳號。";
        return this.authService.register(model.getCreateUserModel())
          .pipe(catchError((error) => {
            (model as any)["錯誤"] = error.error_message;
            errorModels.push(model);
            return EMPTY;
          }),
            finalize(() => {
              finishedTask += 1;
              this.currentTask.rate = finishedTask / models.length * 100;
            }))
      }) :
      defer(() => {
        this.currentTask.text = "正在更新" + model.profile.chineseName + "的帳號。";
        return forkJoin({
          update: this.profileService.createAddress(model.uuId, model.profile.addresses[0])
            .pipe(switchMap(value => {
              model.profile.addresses[0].uuId = value.result.uuid;
              return this.profileService.updateProfile(model.uuId, model.getUpdateUserModel());
            })),
          updateUsernamePassword: model.password == null ? of(true) : this.authService.changeUsernameAndPassword({ userUUId: model.uuId, username: model.username, password: model.password })
        }).pipe(
          catchError((error) => {
            (model as any)["錯誤"] = error.error_message;
            errorModels.push(model);
            return EMPTY;
          }),
          finalize(() => {
            finishedTask += 1;
            this.currentTask.rate = finishedTask / models.length * 100;
          }));
      })
    )).pipe(finalize(() => {
      if (errorModels.length > 0) {
        this.exportErrorData(errorModels);
        this.errorHandleService.errorSignal.emit({ status: "IMPORT ERROR", error_message: "滙入資料錯誤，已將錯誤項目滙出" });
      }
    }));
  }

  parseRequestExcel(file: File): Observable<RequestImportModel[]> {
    return new Observable(subscriber => {

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const binaryString: string = (e.target as FileReader).result as string;
        const workBook: WorkBook = read(binaryString, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = workBook.SheetNames[0];
        const workSheet: WorkSheet = workBook.Sheets[workSheetName];
      
        /* save data */
        var requests: IRequestImportModel[] = utils.sheet_to_json(workSheet, { raw: false, range: 5, blankrows: false });
        
        requests.forEach(request=>{
          console.log()
            if((request['紀錄編號*']?.trim() == "" || request['紀錄編號*']?.trim() == null)&&request["申請人編號*"]?.trim()!=""&&request["申請人編號*"]?.trim()!=null){
              request['紀錄編號*']="error";
            }
        });
        try {
          subscriber.next(
              requests.filter(
                  request => request['紀錄編號*']?.trim() != "" && request['紀錄編號*']?.trim() != null)
                  .map((request, index) => new RequestImportModel(request, index)));
        }
        catch (error) {
          subscriber.error(error);
        }
        subscriber.complete();
      };

      reader.onerror = (e: any) => {
        subscriber.error(e);
      }

      reader.readAsBinaryString(file);
    })
  }

  processRequestData(models: RequestImportModel[]): Observable<any> {
    return new Observable(subscriber => {
      var users: { uuId: string, memberId: string }[] = [];
      var services: IService[] = [];

      this.currentTask.text = "正在處理數㨿中。";

      forkJoin({
        users: this.profileService.getProfileList("?start=0&limit=100000"),
        services: this.serviceService.getServiceTypeList(),
      }).subscribe(
        response => {
          users = response.users.list.map(u => { return { uuId: u.uuId, memberId: u.memberId } });
          services = response.services.map(st => st.services).reduce((pre, cur) => pre.concat(cur), []);

          if(users.length==0||services.length==0)
            subscriber.error({ status: "IMPORT_ERROR", error_message: "無法載入用戶或服務資料，請重新整理版面" });

          models.forEach(request => {
            request.requesterUUId = users.find(u => u.memberId === request.requesterUUId)?.uuId;
            request.serviceUUId = services[services.findIndex(s => s.name === request.serviceUUId)]?.uuId;
            request.volunteers.forEach(v => v.uuId = users.find(u => u.memberId === v.uuId)?.uuId)
          })
          //Validate Date Before Sending to Server
          var errors: (string | null)[] = models.map(request => request.selfValidate());
          if (errors.some(error => error != null)) {
            subscriber.error({ status: "IMPORT_ERROR", error_message: errors.join('') });
            subscriber.complete();
            return;
          }
          //Turn off Interceptor
          this.interceptorSignal.emit(false);
          
          //Import Data to Server
          this.importRequests(models)
            .pipe(finalize(() => this.interceptorSignal.emit(true)))
            .subscribe({
              next: value => { },
              error: value => subscriber.error(value),
              complete: () => subscriber.complete()
            });
        },
        (error) => subscriber.error({ status: "IMPORT_ERROR", error_message: "匯入時出現錯誤，請重新嘗試。" }),
        () => { }
      )
    })
  }

  importRequests(models: RequestImportModel[]): Observable<any> {
    var finishedTask: number = 0;
    var errorModels: RequestImportModel[] = []
    return concat(...models.map(model => {
      return defer(() => {
        this.currentTask.text = "正在處理編號" + model.id + "的義工請求。"
        return this.requestService.ImportRequest(model)
          .pipe(
            catchError((error) => {
              (<any>model).error = error.error_message;
              errorModels.push(model);
              return EMPTY;
            }),
            finalize(() => {
              finishedTask += 1;
              this.currentTask.rate = finishedTask / models.length * 100;
            })
          );
      });
    })).pipe(finalize(() => {
      if (errorModels.length > 0) {
        this.exportErrorData(errorModels);
        this.errorHandleService.errorSignal.emit({ status: "IMPORT ERROR", error_message: "滙入資料錯誤，已將錯誤項目滙出" });
      }
    }));
  }

  parseEventExcel(file: File): Observable<EventImportModel[]> {
    return new Observable(subscriber => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const binaryString: string = (e.target as FileReader).result as string;
        const workBook: WorkBook = read(binaryString, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = workBook.SheetNames[0];
        const workSheet: WorkSheet = workBook.Sheets[workSheetName];

        /* save data */
        var dataset: IEventImportModel[] = utils.sheet_to_json(workSheet, { raw: false, range: 5, blankrows: false });
        var events: EventImportModel[] = [];
        try {
          dataset.forEach((data, index) => {

            var event = events.find(e => e.id == data["紀錄編號"]);

            if (event == null) {
              event = new EventImportModel(data, index)
              events.push(event);
            }

            event.paritcipants.list.push({
              userUUId: data["會員編號*"],
              amount: Number.parseFloat(data["實際交易時分*（實際時分請以該欄目為準）"])
            });

          })
        }
        catch (e) {
          subscriber.error();
        }
        subscriber.next(events);
        subscriber.complete();
      };

      reader.onerror = (e: any) => {
        subscriber.error(e);
      }
      reader.readAsBinaryString(file);
    })
  }

  public processEventData(models: EventImportModel[]): Observable<any> {
    return new Observable(subscriber => {
      var users: { uuId: string, memberId: string }[] = [];
      var eventTypes: IEventType[] = [];

      this.currentTask.text = "正在處理數㨿中。";

      forkJoin({
        users: this.profileService.getProfileList("?start=0&limit=100000"),
        eventTypes: this.eventTypeService.getEventTypeList()
      }).subscribe(
        response => {
          users = response.users.list.map(u => { return { uuId: u.uuId, memberId: u.memberId } });
          eventTypes = response.eventTypes;

          models.forEach(event => {
            event.eventTypeUuid = eventTypes[eventTypes.findIndex(s => s.name === event.eventTypeUuid)]?.uuId;
            event.paritcipants.list.forEach(p => p.userUUId = users.find(u => u.memberId === p.userUUId)?.uuId)
          })

          //Validate User Before Sending to Server
          var errors: (string | null)[] = models.map(event => event.selfValidate());
          if (errors.some(error => error != null)) {
            subscriber.error({ status: "IMPORT_ERROR", error_message: errors.join('') });
            subscriber.complete();
            return;
          }

          //Turn off Interceptor
          this.interceptorSignal.emit(false);

          //Import Data to Server
          this.importEvents(models)
            .pipe(finalize(() => this.interceptorSignal.emit(true)))
            .subscribe({
              next: value => { },
              error: value => subscriber.error(value),
              complete: () => subscriber.complete()
            });
        },
        (error) => subscriber.error({ status: "IMPORT_ERROR", error_message: "匯入時出現錯誤，請重新嘗試。" }),
        () => { }
      )
    })
  }

  public importEvents(models: EventImportModel[]): Observable<any> {
    var finishedTask: number = 0;
    var errorModels: EventImportModel[] = []
    return concat(...models.map(model => defer(() => {
      this.currentTask.text = "正在處理" + model.eventName + "的活動紀錄。";
      return this.eventService.importEvent(model)
        .pipe(
          catchError((error) => {
            (<any>model).error = error.error_message;
            errorModels.push(model);
            return EMPTY;
          }),
          finalize(() => {
            finishedTask += 1;
            this.currentTask.rate = finishedTask / models.length * 100;
          })
        )
    })))
      .pipe(finalize(() => {
        if (errorModels.length > 0) {
          this.exportErrorData(errorModels);
          this.errorHandleService.errorSignal.emit({ status: "IMPORT ERROR", error_message: "滙入資料錯誤，已將錯誤項目滙出" });
        }
      }));
  }

  parseDonationExcel(file: File): Observable<DonationImportModel[]> {
    return new Observable(subscriber => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const binaryString: string = (e.target as FileReader).result as string;
        const workBook: WorkBook = read(binaryString, { type: 'binary' });

        /* grab first sheet */
        const workSheetName: string = workBook.SheetNames[0];
        const workSheet: WorkSheet = workBook.Sheets[workSheetName];

        /* save data */
        var dataset: IDonationImportModel[] = utils.sheet_to_json(workSheet, { raw: false, range: 2, blankrows: false });
        try {
          subscriber.next(dataset.map((data, index) => new DonationImportModel(data, index)))
        }
        catch (e) {
          subscriber.error(e);
        }
        subscriber.complete();
      };
      reader.onerror = (e: any) => {
        subscriber.error(e);
      }
      reader.readAsBinaryString(file);
    })
  }

  public processDonationData(models: DonationImportModel[]): Observable<any> {
    return new Observable(subscriber => {
      var users: { uuId: string, memberId: string }[] = [];

      this.currentTask.text = "正在處理數㨿中。";

      forkJoin({
        users: this.profileService.getProfileList("?start=0&limit=100000"),
      }).subscribe(
        response => {
          users = response.users.list.map(u => { return { uuId: u.uuId, memberId: u.memberId } });

          models.forEach(donation => {
            donation.userUUId = users.find(u => u.memberId === donation.userUUId)?.uuId;
          })

          //Validate User Before Sending to Server
          var errors: (string | null)[] = models.map(donation => donation.selfValidate());
          if (errors.some(error => error != null)) {
            subscriber.error({ status: "IMPORT_ERROR", error_message: errors.join('') });
            subscriber.complete();
            return;
          }

          //Turn off Interceptor
          this.interceptorSignal.emit(false);

          //Import Data to Server
          this.ImportDonations(models)
            .pipe(finalize(() => this.interceptorSignal.emit(true)))
            .subscribe({
              next: value => { },
              error: value => subscriber.error(value),
              complete: () => subscriber.complete()
            });
        },
        (error) => subscriber.error({ status: "IMPORT_ERROR", error_message: "匯入時出現錯誤，請重新嘗試。" }),
        () => { }
      )
    })
  }

  public ImportDonations(models: DonationImportModel[]): Observable<any> {
    var finishedTask: number = 0;
    var errorModels: DonationImportModel[] = [];
    return concat(...models.map(model => defer(() => {
      this.currentTask.text = "正在處理" + model.id + "的捐分紀錄。";
      return this.donationService.importDonation(model)
        .pipe(
          catchError((error) => {
            (<any>model).error = error.error_message;
            errorModels.push(model);
            return EMPTY;
          }),
          finalize(() => {
            finishedTask += 1;
            this.currentTask.rate = finishedTask / models.length * 100;
          })
        )
    }))).pipe(finalize(() => {
      if (errorModels.length > 0) {
        this.exportErrorData(errorModels);
        this.errorHandleService.errorSignal.emit({ status: "IMPORT ERROR", error_message: "滙入資料錯誤，已將錯誤項目滙出" });
      }
    }));
  }

  exportErrorData(data: any): void {
    this.saveAsExcelFile(data, [], "Data Import Error Report");
  }

  private saveAsExcelFile(json: any, headers: string[], fileName: string) {
    const workSheet: WorkSheet = utils.aoa_to_sheet([
      headers
    ]);
    utils.sheet_add_json(workSheet, json, { origin: headers ? 1 : 0 });
    const workBook: WorkBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, fileName);
    /* save to file */
    writeFile(workBook, fileName + ".xlsx");
  }
}
