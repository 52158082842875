import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/backend/http.service';
import { Center, CenterLink, ICenter, ICenterLink } from 'src/app/models/centers/center';
import { CreateCenterModel } from 'src/app/models/centers/create-center-model';
import { UpdateCenterModel } from 'src/app/models/centers/update-center-model';
import { UpdateCenterLinkModel } from 'src/app/models/centers/update-center-link-model';

@Injectable({
  providedIn: 'root'
})
export class CenterService {

  centerCreatedEvent: EventEmitter<any> = new EventEmitter();
  centerUpdatedEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private httpService: HttpService
  ) { }

  public getCenters(): Observable<Center[]> {
    return this.httpService.get<ICenter[]>("center")
      .pipe(map(value => value.result.map(center => new Center(center)).sort(centerSorting)))
  }

  getRedeemCenters(): Observable<Center[]> {
    return this.httpService.get<ICenter[]>("redeem/admin/centers")
      .pipe(map(value => value.result.map(center => new Center(center)).sort(centerSorting)))
  }

  public createCenter(model: CreateCenterModel): Observable<any> {
    return this.httpService.post<any, CreateCenterModel>("center", model)
      .pipe(tap(() => this.centerCreatedEvent.emit()));
  }

  public updateCenter(model: UpdateCenterModel): Observable<any> {
    return this.httpService.put<any, UpdateCenterModel>("center", model)
      .pipe(tap(() => this.centerUpdatedEvent.emit()));
  }

  setRedeemCenters(centers: string[]): Observable<any> {
    return this.httpService.post<any, { CenterUUIds: string[] }>("redeem/admin/centers", { CenterUUIds: centers });
  }

  public centerLinkVolunteer(): Observable<CenterLink[]> {
    return this.httpService.get<ICenterLink[]>("center/CenterVolunteer")
      .pipe(map(value => value.result.map(centerLink => new CenterLink(centerLink.center,centerLink.canFindCenters))))
  }
  public setCenterLinkVolunteer(model:UpdateCenterLinkModel): Observable<any> {
    return this.httpService.put<any,UpdateCenterLinkModel>("center/CenterVolunteer", model);
  }
}

function centerSorting(a: Center, b: Center): number {
  return a.centerCode.localeCompare(b.centerCode, 'en', { numeric: true })
}