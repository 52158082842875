<div class="inline-flex-column" style="min-width: 100vw;">
    <app-top-bar></app-top-bar>
    <div class="main-container">
        <app-side-nav style="display: flex;align-items: stretch;"></app-side-nav>
        <div class="outlet-container">

            <router-outlet></router-outlet>

            <div class="align-self-center" style="margin-top: 3.125rem;">
                <!-- <div class="flex-row align-items-center">
                    <p class="text-lightgrey" translate>幫助</p>
                    <p class="text-lightgrey" style="margin-left: 2.5rem;" translate>私隱</p>
                    <p class="text-lightgrey" style="margin-left: 2.5rem;" translate>條款</p>
                </div> -->
                <p class="text-lightgrey text-center text-sm mt-4" translate>copyright © 2019 香港大學</p>
                <div class="flex-row text-center " style="justify-content: center;"><div class="text-lightgrey" style="font-size: xx-small;">v1.3.0 202211w47.1</div></div>
            </div>

        </div>
    </div>
</div>

<app-import-popup></app-import-popup>

<app-import-status-popup></app-import-status-popup>