import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PopupMessages } from 'src/app/backend/popup-message';
import { EventDay } from 'src/app/models/events/event-day';
import { PortalAnimation } from 'src/app/portal-animation';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { CreateEventDayForm } from '../../forms/create-event-day-form';

@Component({
  selector: 'app-event-day-scheduler',
  templateUrl: './event-day-scheduler.component.html',
  styleUrls: ['./event-day-scheduler.component.scss'],
  animations: [PortalAnimation.DropDown]
})
export class EventDaySchedulerComponent implements OnInit {

  @Output() valueSignal: EventEmitter<CreateEventDayForm[]> = new EventEmitter();

  @Input() cancelAction: () => void = () => { };
  @Input() hasMinDate: boolean = true;

  minDate: Date = new Date();
  lang: string = this.translateService.currentLang;

  selectByDate: boolean = false;
  formGroup: FormGroup = new FormGroup({
    type: new FormControl('daily'),
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null, [Validators.required]),
    ordinalWeek: new FormControl(0),
    ordinalDay: new FormControl(1),
  })

  toggle: boolean = false;

  public daysOfWeek = [
    { name: '星期日', value: 0, selected: false },
    { name: '星期一', value: 1, selected: false },
    { name: '星期二', value: 2, selected: false },
    { name: '星期三', value: 3, selected: false },
    { name: '星期四', value: 4, selected: false },
    { name: '星期五', value: 5, selected: false },
    { name: '星期六', value: 6, selected: false },
  ];

  constructor(
    private translateService: TranslateService,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
  }

  confirm(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    if (this.start.value > this.end.value || this.startDate > this.endDate) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidTimeMessage);
      return;
    }

    if (this.type.value == 'daily') {
      this.valueSignal.emit(this.processDailyPlan());
      return;
    }
    if (this.type.value == 'weekly') {
      this.valueSignal.emit(this.processWeeklyPlan());
      return;
    }
    if (this.type.value == 'monthly' && !this.selectByDate)
      this.valueSignal.emit(this.processMonthlyWeekPlan());
    else {
      this.valueSignal.emit(this.processMonthlyDayPlan());
    }
  }

  private processDailyPlan(): CreateEventDayForm[] {
    var beginDate: Date = new Date(this.startDate.value);
    var eventDays: EventDay[] = [];
    while (beginDate <= this.endDate.value) {
      eventDays.push(new EventDay({
        uuId: null,
        startTime: this.start.value,
        endTime: this.end.value,
        date: new Date(beginDate),
        isCancelled: false,
        numOfAttendance:null
      }));
      beginDate.setDate(beginDate.getDate() + 1);
    };

    return eventDays.map(ed => new CreateEventDayForm(ed));
  }

  private processWeeklyPlan(): CreateEventDayForm[] {
    var beginDate: Date = new Date(this.startDate.value);
    var beginWeek: Date[] = [];
    var eventDays: EventDay[] = [];
    for (let i of [0, 1, 2, 3, 4, 5, 6]) {
      if (this.daysOfWeek[beginDate.getDay()].selected)
        beginWeek.push(new Date(beginDate));
      beginDate.setDate(beginDate.getDate() + 1);
    }
    while (beginWeek.length > 0 && beginWeek[0].getTime() <= (this.endDate.value as Date).getTime()) {
      beginWeek.forEach(x => {
        if (x.getTime() <= (this.endDate.value as Date).getTime()) {
          eventDays.push(new EventDay({
            uuId: null,
            startTime: this.start.value,
            endTime: this.end.value,
            date: new Date(x),
            isCancelled: false,
            numOfAttendance:null
          }));
          x.setDate(x.getDate() + 7);
        }
      })
    }
    return eventDays.map(ed => new CreateEventDayForm(ed));
  }

  private processMonthlyWeekPlan(): CreateEventDayForm[] {
    var firstDay: Date = new Date((this.startDate.value as Date).getFullYear(), (this.startDate.value as Date).getMonth(), 1);
    var ordinalWeek: Date[] = [];
    var eventDays: EventDay[] = [];
    for (let i of [0, 1, 2, 3, 4, 5, 6]) {
      if (this.daysOfWeek[(firstDay.getDay() + i) % 7].selected)
        ordinalWeek.push(new Date((this.startDate.value as Date).getFullYear(), (this.startDate.value as Date).getMonth(), this.ordinalWeek.value * 7 + i + 1));
    }

    while (ordinalWeek.length > 0 && ordinalWeek[0].getTime() <= (this.endDate.value as Date).getTime()) {
      ordinalWeek.forEach(x => {
        if (x.getTime() > (this.startDate.value as Date).getTime() && x.getTime() <= (this.endDate.value as Date).getTime()) {
          eventDays.push(new EventDay({
            uuId: null,
            startTime: this.start.value,
            endTime: this.end.value,
            date: new Date(x),
            isCancelled: false,
            numOfAttendance:null
          }));
        }
        if (x.getDate() % 7 + 28 < new Date(x.getFullYear(), x.getMonth() + 1, 0).getDate())
          x.setDate(x.getDate() + 35);
        else
          x.setDate(x.getDate() + 28);
      });
    }

    return eventDays.map(ed => new CreateEventDayForm(ed));
  }

  private processMonthlyDayPlan(): CreateEventDayForm[] {
    var beginDay: Date | null = null
    var eventDays: EventDay[] = [];

    if ((this.startDate.value as Date).getDate() <= this.ordinalDay.value)
      beginDay = new Date((this.startDate.value as Date).getFullYear(), (this.startDate.value as Date).getMonth(), this.ordinalDay.value);
    else
      beginDay = new Date((this.startDate.value as Date).getFullYear(), (this.startDate.value as Date).getMonth() + 1, this.ordinalDay.value);

    if (beginDay.getDate() != this.ordinalDay.value) {
      beginDay.setDate(this.ordinalDay.value);
    }

    while (beginDay.getTime() <= (this.endDate.value as Date).getTime()) {
      eventDays.push(new EventDay({
        uuId: null,
        startTime: this.start.value,
        endTime: this.end.value,
        date: new Date(beginDay),
        isCancelled: false,
        numOfAttendance:null
      }));
      beginDay.setMonth(beginDay.getMonth() + 1);
      if (beginDay.getDate() != this.ordinalDay.value) {
        beginDay.setDate(this.ordinalDay.value);
      }
    }

    return eventDays.map(ed => new CreateEventDayForm(ed));
  }

  get start(): AbstractControl {
    return this.formGroup.controls['start'];
  }

  get end(): AbstractControl {
    return this.formGroup.controls['end'];
  }

  get startDate(): AbstractControl {
    return this.formGroup.controls['startDate'];
  }

  get endDate(): AbstractControl {
    return this.formGroup.controls['endDate'];
  }

  get type(): AbstractControl {
    return this.formGroup.controls['type'];
  }

  get numberOfSelectedWeekDay(): number {
    return this.daysOfWeek.filter(x => x.selected).length;
  }

  get selectedWeekDay(): any {
    return this.daysOfWeek.filter(x => x.selected)[0];
  }

  public arraycounter(length: number): any[] {
    return new Array(length);
  }

  get ordinalDay(): AbstractControl {
    return this.formGroup.controls['ordinalDay'];
  }

  get ordinalWeek(): AbstractControl {
    return this.formGroup.controls['ordinalWeek'];
  }
}
