<div class="fadeIn">
    <div class="flex-row text-lightgrey">
        <p translate>請求/訂單</p>
        <p class="ml-4">/</p>
        <p class="ml-4" translate>創建請求</p>
        <p class="ml-4">/</p>
        <p class="ml-4 text-grey" translate>用戶請求</p>
    </div>

    <p class="page-title mt-8" translate>義工列表</p>

    <div class="container mt-5">
        <div class="container-body">
            <app-search-input [placeholder]="'請輸入姓名 或 會員編號'" [action]="searchFilterAction"></app-search-input>

            <div class="inline-flex-row align-items-center mt-8" style="margin-bottom: 1.5rem;">
                <app-center-filter [action]="centerFilterAction"></app-center-filter>
                <app-address-filter [action]="addressFilterAction" class="ml-4"></app-address-filter>
                <app-condition-filter [action]="conditionFilterAction" class="ml-4"></app-condition-filter>
                <app-cell-group-filter [action]="cellGroupFilterAction" class="ml-4"></app-cell-group-filter>
            </div>

            <ng-container *ngTemplateOutlet="pagination"></ng-container>

            <table class="list-table mt-4 mb-4">
                <div class="list-table-row header">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [ngModel]="isSelectedPage" (click)="selectPage()">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p matTooltip="{{ '推薦排名' | translate }}" [matTooltipPosition]="'above'" translate>推薦排名</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>用戶編號</p>
                        <div class="inline-flex-column" (click)="filters.memberIdOrder = !filters.memberIdOrder;filters.timeBalanceOrder = null">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.memberIdOrder}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.memberIdOrder}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>中文姓名</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>性別</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>年齡</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p translate>中心</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p translate>電話</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 19;">
                        <p translate>地址</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p matTooltip="{{ '時分' | translate }}" [matTooltipPosition]="'above'" translate>時分</p>
                        <div class="d-flex flex-column" (click)="filters.timeBalanceOrder = !filters.timeBalanceOrder;filters.memberIdOrder = null">
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.timeBalanceOrder}">arrow_drop_up</i>
                            <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.timeBalanceOrder}">arrow_drop_down</i>
                        </div>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p translate>操作</p>
                    </div>
                </div>

                <div class="list-table-row" *ngFor="let volunteer of displayedVolunteers">
                    <div class="list-table-cell justify-content-center" style="flex-basis: 2rem;flex-grow: 4;">
                        <input type="checkbox" [(ngModel)]="volunteer.selected" [disabled]="volunteer.disabled">
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;overflow: visible;">
                        <p class="flex-auto" *ngIf="volunteer.isRecommended">{{ volunteer.recommendNum + 1 }}</p>
                        <app-volunteer-score-popup [hinDetail]="volunteer.hinDetail" [hinRemark]="volunteer.hinRemark"></app-volunteer-score-popup>
                        <p></p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ volunteer.memberId }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p>{{ volunteer.chineseName }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ volunteer.gender | translate }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p>{{ volunteer.age }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 4.5rem;flex-grow: 9;">
                        <p *ngIf="volunteer.center">{{ volunteer.center.name }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 7rem;flex-grow: 14;">
                        <p style="flex-basis: 5rem;width: 5rem;">{{ volunteer.phone }}</p>
                        <img src="/v2/assets/mobile.svg" *ngIf="!volunteer.hasMobilePhone">
                        <p></p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 8.5rem;flex-grow: 19;">
                        <p [matTooltip]="volunteer.defaultAddress" matTooltipPosition="above">{{ volunteer.defaultAddress }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p [ngClass]="{'text-red': volunteer.timeBalance < 0}">{{ volunteer.timeBalance }}</p>
                    </div>
                    <div class="list-table-cell" style="flex-basis: 5.5rem;flex-grow: 11;">
                        <p class="click-text-blue" [routerLink]="['/main/user/overview', volunteer.userUUId]" translate>檢視</p>
                    </div>
                </div>

                <div class="list-table-row" *ngIf="displayedVolunteers.length == 0">
                    <div class="list-table-cell">
                        <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
                    </div>
                </div>
            </table>

            <div class="inline-flex-row align-items-center justify-content-center" style="margin-top: 1.25rem;">
                <button class="button outline" (click)="back()" translate>返回</button>
                <button class="button fill blue" style="margin-left: 9.375rem;" (click)="submit()" translate>確認人選</button>
            </div>
        </div>
    </div>
</div>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ selectedVolunteers.length }}</p>
            <p translate>位義工</p>
            <input class="ml-6" [ngModel]="isSelectedAll" (click)="selectAll()" type="checkbox">
            <p class="ml-4" translate>勾選全部</p>
            <input class="ml-6" [(ngModel)]="filters.matchedBefore" type="checkbox">
            <p class="ml-4" translate>只顯示曾經配對過</p>
        </div>

        <div class="inline-flex-row" style="margin-right: 1.5rem;">
            <p translate>合計</p>
            <p class="ml-2 mr-2">{{ volunteers.length }}</p>
            <p translate>項</p>
        </div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1;changePage()">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>