<div class="popup-bg" *ngIf="user">
    <div class="redeem-popup zoomIn" (click)="$event.stopPropagation()">

        <div class="redeem-header">
            <p translate>已選擇該會員，請先完善換領資料</p>
        </div>

        <div class="agent-table" style="margin-top: 1.625rem;width: 100%;">
            <div class="agent-table-row header">
                <div class="agent-table-cell">
                    <p translate>會員編號</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>中文姓名</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>中心代碼</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>電話</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>用戶餘額</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>換領數量</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>交易時分</p>
                </div>
            </div>
            <div class="agent-table-row">
                <div class="agent-table-cell">
                    <p>{{ user.memberId}}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ user.chineseName }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ user.center.centerCode }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ user.phone }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ user.timeBalance | number }}</p>
                </div>
                <div class="agent-table-cell">
                    <input class="user-input xxs" style="width: 4rem; text-align: center;" type="number" [formControl]="quantity">
                </div>
                <div class="agent-table-cell">
                    <p *ngIf="product">{{ product.price }}</p>
                </div>
            </div>
        </div>

        <div class="inline-flex-column mt-4">
            <ng-container *ngFor="let question of questions;let index = index">
                <ng-container *ngIf="question.mode == 'Single' then single else multi"></ng-container>
                <ng-template #single>
                    <div class="mb-8">
                        <p translate>{{ '（單選）' | translate: { value_1: index, value_2: question.question} }}</p>
                        <div class="inline-flex-row flex-wrap align-items-center">
                            <label class="mt-4 mb-4 mr-8" *ngFor="let option of question.options">
                                <input type="checkbox" class="mr-4" [(ngModel)]="option.selected" [disabled]="question.isOptionDisabled(option)">
                                <p>{{ option.option }}</p>
                            </label>
                        </div>
                    </div>
                </ng-template>
                <ng-template #multi>
                    <div class="mb-8">
                        <p translate>{{ '（多選，資料僅供研究用途）' | translate: { value_1: index, value_2: question.question} }}</p>
                        <div class="inline-flex-row flex-wrap align-items-center">
                            <label class="mt-4 mb-4 mr-8" *ngFor="let option of question.options">
                                <input type="checkbox" class="mr-4" [(ngModel)]="option.selected">
                                <p>{{ option.option }}</p>
                            </label>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <div class="flex-1"></div>

        <div class="flex-row justify-content-center mt-8">
            <button class="button outline" style="margin-right: 7.5rem;" (click)="close()" translate>返 回</button>
            <button class="button fill blue" (click)="submit()" translate>確 認</button>
        </div>
    </div>
</div>