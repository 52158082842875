import { UpdateEventForm } from "src/app/modules/event/forms/update-event-form";
import { Address, IAddress } from "../addresses/address";
import { EventType, IEventType } from "../event-types/event-type";
import { ITransaction, Transaction } from "../transactions/transaction";
import { EventDay, IEventDay } from "./event-day";
import { EventParticipant, IEventParticipant } from "./event-participant";

export interface IEvent {
    id: number;
    uuId: string;
    eventName: string;
    venue: IAddress;
    startTime: Date;
    endTime: Date;
    isUnknownEndTime: boolean;
    createdDate: Date;
    participantVacancy: number;
    volunteerVacancy: number;
    earning: number;
    spending: number;
    remark: string;
    detail: string;
    lastEditedTime: Date;
    lastEditedBy: string;
    participants: IEventParticipant[];
    transactions: ITransaction[];
    eventType: IEventType;
    eventPay: string;
    eventJoinType: string;
    applyDeadline: Date;
    eventDays: IEventDay[];
    isCancelled: boolean;
    cancelledTime: Date;
    cancelledBy: string;
    cancelDetail: string;
    eventStatus: string;
    isFull: boolean;
    extraEventJson: any;
    deleteDate: Date;
    url: string;
}

export class Event {
    id: number;
    uuId: string;
    eventName: string;
    venue: IAddress | null;
    startTime: Date;
    endTime: Date;
    createdDate: Date;
    participantVacancy: number;
    volunteerVacancy: number;
    earning: number;
    spending: number;
    remark: string;
    detail: string;
    lastEditedTime: Date | null;
    lastEditedBy: string;
    participants: EventParticipant[];
    transactions: Transaction[];
    eventType: EventType;
    eventPay: string;
    eventJoinType: string;
    applyDeadline: Date;
    eventDays: EventDay[];
    isCancelled: boolean;
    cancelledTime: Date;
    cancelledBy: string;
    cancelDetail: string;
    eventStatus: string;
    isFull: boolean;
    extraEventJson: any;
    deleteDate: Date | null;
    url: string;

    selected: boolean;
    editing: boolean;

    form: UpdateEventForm;

    constructor(event: IEvent) {
        this.id = event.id;
        this.uuId = event.uuId;
        this.eventName = event.eventName;
        this.venue = event.venue ? new Address(event.venue) : null;
        this.startTime = new Date(event.startTime + 'Z');
        this.endTime = new Date(event.endTime + 'Z');
        this.createdDate = new Date(event.createdDate + 'Z');
        this.participantVacancy = event.participantVacancy;
        this.volunteerVacancy = event.volunteerVacancy;
        this.earning = event.earning;
        this.spending = event.spending;
        this.remark = event.remark;
        this.detail = event.detail;
        this.lastEditedTime = event.lastEditedTime ? new Date(event.lastEditedTime + 'Z') : null;
        this.lastEditedBy = event.lastEditedBy;
        this.isCancelled = event.isCancelled;
        this.cancelledTime = new Date(event.cancelledTime);
        this.cancelledBy = event.cancelledBy;
        this.cancelDetail = event.cancelDetail;
        this.eventStatus = event.eventStatus;
        this.isFull = event.isFull;
        this.deleteDate = event.deleteDate ? new Date(event.deleteDate) : null;
        this.extraEventJson = this.extraEventJson ? JSON.parse(event.extraEventJson) : null;
        this.eventPay = event.eventPay;
        this.eventType = new EventType(event.eventType);
        this.eventJoinType = event.eventJoinType;
        this.applyDeadline = new Date(event.applyDeadline + 'Z');
        this.eventDays = event.eventDays.map(d => new EventDay(d));
        this.transactions = event.transactions.map(transaction => new Transaction(transaction));
        this.participants = event.participants.map(p => new EventParticipant(p, this.transactions));
        this.editing = false;
        this.selected = false;
        this.url = event.url;

        this.participants.forEach(p => p.form.setValue(this.earning));

        this.form = new UpdateEventForm(this);
    }

    public getEventStartDate(): Date {
        return this.eventDays.map(ed => ed.startTime).sort((a, b) => a > b ? 1 : a < b ? -1 : 0)[0];
    }

    public getEventEndDate(): Date {
        return this.eventDays.map(ed => ed.endTime as Date).sort((a, b) => a > b ? -1 : a < b ? 1 : 0)[0];
    }

    get isOngoing(): boolean {
        let date = new Date()
        return this.startTime.getTime() <= date.getTime() && this.endTime.getTime() >= date.getTime();
    }

    get numberOfParticipants(): number {
        return this.participants.filter(p => p.state === 'Confirmed').length;
    }

    get numberOfApplication(): number {
        return this.participants.filter(p => p.state === 'Interested').length;
    }

    get numberOfInvited(): number {
        return this.participants.length;
    }

    get full(): boolean {
        return this.participants.filter(p => p.state === 'Confirmed').length >= this.participantVacancy;
    }
}