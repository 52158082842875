<ng-container *ngTemplateOutlet="pagination"></ng-container>

<div class="user-table mt-6 mb-6">
    <div class="user-table-row header">
        <div class="user-table-cell" style="flex-basis: 2.75rem">
            <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
        </div>
        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
            <p translate>紀錄編號</p>
            <div class="inline-flex-column" (click)="filters.idOrder = !filters.idOrder;filters.dateOrder = null">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.idOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.idOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
            <p translate>捐分日期</p>
            <div class="inline-flex-column" (click)="filters.dateOrder = !filters.dateOrder;filters.idOrder = null">
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': !filters.dateOrder}">arrow_drop_up</i>
                <i class="material-icons-round sort-icon" [ngClass]="{'text-lightgrey': filters.dateOrder}">arrow_drop_down</i>
            </div>
        </div>
        <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
            <p translate>會員編號</p>
        </div>
        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
            <p translate>中文姓名</p>
        </div>
        <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
            <p translate>捐分類型</p>
        </div>
        <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
            <p translate>交易時分</p>
        </div>
        <div class="user-table-cell" style="flex-basis: 13.0625rem;flex-grow: 19;">
            <p translate>操作</p>
        </div>
    </div>

    <div class="user-table-row" *ngFor="let transaction of displayedTransactions">
        <div class="user-table-cell" style="flex-basis: 2.75rem">
            <input type="checkbox" [(ngModel)]="transaction.selected">
        </div>
        <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
            <p>{{ transaction.id }}</p>
        </div>
        <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
            <p>{{ transaction.date | date: 'dd-MM-yyyy' }}</p>
        </div>
        <ng-container *ngIf="transaction.transferer.uuId == userId">
            <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
                <p>{{ transaction.transferer.memberId }}</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p>{{ transaction.transferer.chineseName }}</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
                <p class="text-red" translate>用戶捐分</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                <p class="text-red">-{{ transaction.amount }}</p>
            </div>
        </ng-container>
        <ng-container *ngIf="transaction.transferee.uuId == userId">
            <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
                <p>{{ transaction.transferee.memberId }}</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p>{{ transaction.transferee.chineseName }}</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 8.9375rem;flex-grow: 13;">
                <p class="text-green" translate>中心捐分</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                <p class="text-green">+{{ transaction.amount }}</p>
            </div>
        </ng-container>

        <div class="user-table-cell" style="flex-basis: 13.0625rem;flex-grow: 19;">
            <p class="click-text-lightblue ml-8" (click)="deleteDonation(transaction.uuId)" translate>移除</p>
        </div>
    </div>

    <div class="user-table-row" *ngIf="displayedTransactions.length == 0">
        <div class="user-table-cell">
            <p class="text-lightgrey ml-4" translate>沒有紀錄</p>
        </div>
    </div>
</div>

<ng-container *ngTemplateOutlet="pagination"></ng-container>

<ng-template #pagination>
    <div class="inline-flex-row align-items-center">
        <div class="inline-flex-row align-items-center flex-1">
            <p translate>已選擇</p>
            <p class="ml-2 mr-2 text-orange">{{ selectedTransactions.length }}</p>
            <p translate>條紀錄</p>
        </div>

        <p class="click-text-lightblue mr-8" translate>匯出選項</p>
        <p class="click-text-lightblue mr-8" [ngClass]="{disabled: selectedTransactions.length == 0}" (click)="deleteDonationList()" translate>移除選項</p>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>

        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>