import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CellGroup, ICellGroup } from "../cell-groups/cell-group";
import { UpdateCenterLinkModel } from "./update-center-link-model";
import { UpdateCenterModel } from "./update-center-model";

export interface ICenter {
    uuId: string;
    name: string;
    centerCode: string;
    phoneNumber: string;
    cellGroups: ICellGroup[];
    redeemEnabled: boolean;
    canFindCenters:ICenter[];
}

export interface ICenterLink{
    center:Center;
    canFindCenters:Center[];
}
export class CenterLink{
    center:Center;
    canFindCenters:Center[];
    constructor(center: Center,canFindCenters:Center[]) {
        this.center=center;
        this.canFindCenters=canFindCenters;
        this.center.canFindCenters=canFindCenters;
    }
    getUpdateCenterLinkModel(): UpdateCenterLinkModel {
        return {
            targetCenter:this.center.uuId,
            centersList:this.canFindCenters.map(c=>c.uuId)
        }
    }
}

export class Center {
    uuId: string;
    name: string;
    centerCode: string;
    phoneNumber: string;
    cellGroups: CellGroup[];

    selected: boolean;
    editing: boolean;
    redeemEnabled: boolean;
    form: FormGroup;

    canFindCenters: Center[];

    constructor(center: ICenter) {
        this.uuId = center.uuId;
        this.name = center.name;
        this.centerCode = center.centerCode;
        this.phoneNumber = center.phoneNumber;
        this.selected = false;
        this.editing = false;
        this.redeemEnabled = center.redeemEnabled;
        this.cellGroups = center.cellGroups ? center.cellGroups.map(cellGroup => new CellGroup(cellGroup)).sort(CellGroupSorting) : [];
        this.form = new FormGroup({
            code: new FormControl(this.centerCode, [Validators.required]),
            phone: new FormControl(this.phoneNumber, [Validators.required]),
            name: new FormControl(this.name, [Validators.required])
        })
        this.canFindCenters=center.canFindCenters ?center.canFindCenters.map(c=>new Center(c)):[];
    }

    getUpdateCenterModel(): UpdateCenterModel {
        return {
            uuId: this.uuId,
            centerCode: this.form.controls['code'].value,
            phoneNumber: this.form.controls['phone'].value,
            name: this.form.controls['name'].value
        }
    }
}

function CellGroupSorting(a: CellGroup, b: CellGroup) {
    return a.name.localeCompare(b.name, 'en', { numeric: true })
}
