<div class="popup-bg" *ngIf="display" (click)="close()">
    <div class="redeem-popup zoomIn" (click)="$event.stopPropagation()">
        <div class="redeem-header">
            <p class="flex-1" translate>換領詳情</p>
            <p translate>{{ '換領編碼：' | translate: {value: redeem.redeemCode} }}</p>
        </div>
        <div class="inline-flex-column">
            <div class="inline-flex-row timetxt">
                <div translate>申請兌換時間：</div>
                <div *ngIf="redeem.redeemDate==null" translate>等候處理中</div>
                <div *ngIf="redeem.redeemDate">{{ redeem.redeemDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>
            <div class="inline-flex-row timetxt" *ngIf="redeem.redeemDate" >
                <div translate>領取時間：</div>
                <div *ngIf="redeem.deliveredDate==null" translate>兌換中</div>
                <div *ngIf="redeem.deliveredDate">{{ redeem.deliveredDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>
        </div>
        <div class="agent-table" style="margin-top: 1.625rem;width: 100%;">
            <div class="agent-table-row header">
                <div class="agent-table-cell">
                    <p translate>會員編號</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>中文姓名</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>中心代碼</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>電話</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>用戶餘額</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>換領數量</p>
                </div>
                <div class="agent-table-cell">
                    <p translate>交易時分</p>
                </div>
            </div>
            <div class="agent-table-row">
                <div class="agent-table-cell">
                    <p>{{ redeem.memberId}}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.chineseName }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.centerCode }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.phone }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.timeBalance | number }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.quantity }}</p>
                </div>
                <div class="agent-table-cell">
                    <p>{{ redeem.price }}</p>
                </div>
            </div>
        </div>

        <div class="inline-flex-column flex-1" style="margin-top: 1.625rem">
            <ng-container *ngIf="redeem.redeemReason">
                <div style="margin-bottom: 2.1875rem" *ngFor="let reason of redeem.redeemReason; let index = index">
                    <p>{{ index }}. {{ reason.question }}</p>
                    <div class="inline-flex-row flex-wrap align-items-center">
                        <ng-container *ngIf="isMultiChoices(reason.answer) == false">
                            <label class="mt-4 mb-4 flex-1">
                                <input type="checkbox" [disabled]="true" checked>
                                <p>{{ reason.answer }}</p>
                            </label>
                        </ng-container>
                        <ng-container *ngIf="isMultiChoices(reason.answer)">
                            <label class="mt-4 mb-4 flex-1" *ngFor="let ans of reason.answer">
                                <input type="checkbox" [disabled]="true" checked>
                                <p>{{ ans }}</p>
                            </label>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="inline-flex-row justify-content-center">
            <button class="button outline" (click)="close()" translate>返 回</button>
        </div>
    </div>
</div>