<div class="container">

    <div class="inline-flex-row align-items-center mb-8">
        <app-search-input [placeholder]="'請輸入姓名 或 會員編號'" [action]="searchFilterAction"></app-search-input>

        <app-center-filter class="ml-6" [action]="centerFilterAction"></app-center-filter>

        <div class="flex-1"></div>

        <div class="inline-flex-row align-items-center" style="cursor: pointer;" [routerLink]="['/main/redeem/overview', product.uuId, 'select-redeem-user']">
            <i class="material-icons-round add-icon mr-1">add_circle</i>
            <p translate>新增用戶</p>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="pagination"></ng-container>

    <div class="user-table mt-4 mb-4">
        <div class="user-table-row header">
            <div class="user-table-cell" style="flex-basis: 2.75rem;">
                <input type="checkbox" [ngModel]="isPageSelectedAll" (click)="selectPage()">
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>會員編號</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>中文姓名</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>中心代碼</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
                <p translate>性別</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
                <p translate>年齡</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                <p translate>電話</p>
            </div>

            <!-- <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>換領時間</p>
            </div> -->
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>狀態</p>
            </div> 

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
                <p translate>數量</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p translate>交易時分</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 8.5rem;flex-grow: 68;">
                <p translate>操作</p>
            </div>
        </div>
        <div class="user-table-row" *ngFor="let redeem of redeems">
            <div class="user-table-cell" style="flex-basis: 2.75rem;">
                <input type="checkbox" [(ngModel)]="redeem.selected">
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p>{{ redeem.memberId }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p>{{ redeem.chineseName }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p>{{ redeem.centerCode }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
                <p translate *ngIf="redeem.gender == 'M'">男</p>
                <p translate *ngIf="redeem.gender == 'F'">女</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;">
                <p>{{ redeem.age }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 55;">
                <p>{{ redeem.phone }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <!-- <div class="inline-flex-column flex-1 align-items-center">
                    <p>{{ redeem.redeemDate | date: 'hh:mm:ss'}}</p>
                    <p>{{ redeem.redeemDate | date: 'yyyy-MM-dd'}}</p>
                </div> -->
                <div  class="inline-flex-column flex-1 align-items-center" [ngClass]="{Redeemed:redeem.redeemState=='Redeemed'}" translate>{{redeem.redeemState}}</div>
            </div>

            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 33;" [ngClass]="{'editing': redeem.editing, 'invalid': redeem.quantityInput.invalid}">
                <p *ngIf="redeem.editing == false">{{ redeem.quantity }}</p>
                <input *ngIf="redeem.editing" class="quantity-input" type="number" [formControl]="redeem.quantityInput" (click)="$event.target.select()">
            </div>

            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 44;">
                <p>{{ redeem.price }}</p>
            </div>

            <div class="user-table-cell" style="flex-basis: 8.5rem;flex-grow: 68;">
                <p class="click-text-lightblue" *ngIf="redeem.editing == false" (click)="redeem.editing = true" translate>修改</p>
                <p class="click-text-lightblue" *ngIf="redeem.editing == true" (click)="updateRedeem(redeem)" translate>確認</p>
                <p class="click-text-lightblue ml-8" (click)="redeem_popup.open(redeem)" translate>詳情</p>
                <p class="click-text-lightblue ml-8" (click)="cancelRedeem(redeem)" translate>取消</p>
            </div>
        </div>

        <div class="user-table-row" *ngIf="displayedRedeems.length == 0">
            <div class="user-table-cell">
                <p translate>暫無成功換領該獎勵的會員</p>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="pagination"></ng-container>


    <div class="inline-flex-column align-items-center" style="margin-top: 2.5rem;">
        <div class="inline-flex-row">
            <button class="button nofill blue align-self-center mar5_25" (click)="exportProduct()" translate>匯出列表</button>
            <button class="button fill blue align-self-center mal5_25" (click)="deliveredProduct()" translate>確認領取</button>
        </div>
        <p class="text-lightgrey mt-4" translate>可匯出已兌換的全部會員列表，</p>
        <p class="text-lightgrey" translate>如勾選會員後點擊，可匯出所選會員列表</p>
    </div>
</div>

<app-redeem-popup #redeem_popup></app-redeem-popup>

<ng-template #pagination>

    <div class="flex-row align-items-center">
        <p translate="">已選擇</p>
        <p class="ml-2 mr-2 text-yellow">{{ numberOfSelectedUsers }}</p>
        <p translate>位會員，</p>
        <ng-container *ngIf="product.stock != -1">
            <p translate>庫存</p>
            <p class="ml-2 mr-2 text-lightblue">{{ product.stock }}</p>
            <p translate>件</p>
        </ng-container>
        <p *ngIf="product.stock == -1" translate>庫存充足</p>
        <p class="mr-3" translate>{{ '位會員已換取該獎勵項目並扣除相應時分' | translate: {value: numberOfRedeemUsers} }}</p>
        <label class="text-grey">
            <input class="mr-2" type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
            <p translate>勾選全部</p>
        </label>

        <div class="flex-1"></div>

        <div class="pagination mr-4">
            <select class="pagination" [(ngModel)]="filters.itemsPerPage" (change)="filters.page = 1">
                <option [ngValue]=5 translate>5 項 / 頁</option>
                <option [ngValue]=10 translate>10 項 / 頁</option>
                <option [ngValue]=20 translate>20 項 / 頁</option>
                <option [ngValue]=30 ranslate>30 項 / 頁</option>
                <option [ngValue]=50 translate>50 項 / 頁</option>
            </select>
        </div>



        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page <= 1}" (click)="filters.page = filters.page - 1">chevron_left</i>
        <input type="number" class="page-input" [(ngModel)]="filters.page">
        <p class="ml-3 mr-3 text-grey">/</p>
        <p class="page-number">{{ pages }}</p>
        <i class="material-icons-round page-icon" [ngClass]="{'disabled': filters.page >= pages}" (click)="filters.page = +filters.page +1">chevron_right</i>
    </div>
</ng-template>