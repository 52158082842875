import { IAddressComponent } from "../addresses/address";
import { CellGroup } from "../cell-groups/cell-group";
import { Center, ICenter } from "../centers/center";

export interface IVolunteerChoice {
    userUUId: string;
    chineseName: string;
    gender: string;
    birthday: Date;
    district: string;
    hasMobilePhone: boolean;
    center: ICenter;
    memberId: string;
    phone: string;
    timeBalance: number;
    defaultDistrict: IAddressComponent;
    defaultRegion: IAddressComponent;

    isRecommended: boolean;
    recommendNum: number;
    isMatched: boolean;
    hinRemark: IHINRemark;
    hinDetail: IHINDetail;
    cellGroups:CellGroup[];
    defaultAddress:string;
}

export class VolunteerChoice {
    userUUId: string;
    chineseName: string;
    gender: string;
    birthday: Date;
    district: string;
    hasMobilePhone: boolean;
    center: Center;
    memberId: string;
    phone: string;
    timeBalance: number;
    defaultDistrict: IAddressComponent;
    defaultRegion: IAddressComponent;
    defaultAddress:string;
    selected: boolean;
    disabled: boolean;

    isRecommended: boolean;
    recommendNum: number;
    isMatched: boolean;
    hinRemark: IHINRemark;
    hinDetail: IHINDetail;
    cellGroups:CellGroup[];


    constructor(volunteer: IVolunteerChoice) {
        this.userUUId = volunteer.userUUId;
        this.chineseName = volunteer.chineseName;
        this.gender = volunteer.gender;
        this.birthday = new Date(volunteer.birthday + 'Z');
        this.district = volunteer.district;
        this.hasMobilePhone = volunteer.hasMobilePhone;
        this.center = new Center(volunteer.center);
        this.memberId = volunteer.memberId;
        this.phone = volunteer.phone;
        this.timeBalance = volunteer.timeBalance;
        this.defaultDistrict = volunteer.defaultDistrict;
        this.defaultRegion = volunteer.defaultRegion;
        this.isMatched = volunteer.isMatched;
        this.isRecommended = volunteer.isRecommended;
        this.recommendNum = volunteer.recommendNum;
        this.hinRemark = volunteer.hinRemark;
        this.hinDetail = volunteer.hinDetail;
        this.selected = false;
        this.disabled = false;
        this.cellGroups=volunteer.cellGroups;
        this.defaultAddress=volunteer.defaultAddress;
    }

    get age(): number {
        return new Date(Date.now() - this.birthday.getTime()).getUTCFullYear() - 1970;
    }
}

export interface IHINRemark {
    userIsNotProvidingRequiredService: boolean;
    userAddressIsFarAwayFromRequester: boolean;
    userHasContradictingCondition: boolean;
    userReceivedNegativeFeedbackFromRequester: boolean;
    userIsNotAvailableDuringRequestedStartTime: boolean;
    userIsNotAvailableDuringRequestedEndTime: boolean;
}

export interface IHINDetail {
    service: number;
    availability_start: number;
    availability_end: number;
    address: number;
    condition: number;
    completed: number;
    failed: number;
}