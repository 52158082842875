import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { Address, IAddress } from "../addresses/address";
import { IAgent, Agent } from "../profiles/agent";
import { IService, Service } from "../services/service";
import { ITransaction, Transaction } from "../transactions/transaction";
import { ITransportation, Transportation } from "../transportations/transportation";
import { IRequester, Requester } from "./requester";
import { IVolunteer, Volunteer } from "./volunteer";

export interface IRequest {
    uuId: string;
    agent: IAgent;
    cancelRemark: string;
    centerDonate: ITransaction;
    checkInTime: Date;
    checkOutTime: Date;
    createdAt: Date;
    destination: IAddress;
    endTime: Date;
    id: number;
    numberOfVolunteer: number;
    remark: string;
    requester: IRequester;
    requestStatus: string;
    service: IService;
    staffComment: string;
    startTime: Date;
    transportation: ITransportation;
    venue: IAddress;
    volunteers: IVolunteer[];
}

export class Request {
    uuId: string;
    agent: Agent | null;
    cancelRemark: string;
    centerDonate: Transaction | null;
    checkInTime: Date | null;
    checkOutTime: Date | null;
    createdAt: Date;
    destination: Address;
    endTime: Date;
    id: number;
    numberOfVolunteer: number;
    remark: string;
    requester: Requester;
    requestStatus: string;
    service: Service;
    staffComment: string;
    startTime: Date;
    transportation: Transportation | null;
    venue: Address;
    volunteers: Volunteer[];

    form: FormGroup;
    // editing: boolean;
    // remarkEdit: boolean;

    constructor(request: IRequest) {
        this.uuId = request.uuId;
        this.agent = request.agent ? new Agent(request.agent) : null;
        this.cancelRemark = request.cancelRemark;
        this.centerDonate = request.centerDonate ? new Transaction(request.centerDonate) : null;
        this.checkInTime = request.checkInTime ? new Date(request.checkInTime + 'Z') : null;
        this.checkOutTime = request.checkOutTime ? new Date(request.checkOutTime + 'Z') : null;
        this.createdAt = new Date(request.createdAt + 'Z');
        this.destination = new Address(request.destination);
        this.endTime = new Date(request.endTime + 'Z');
        this.id = request.id;
        this.numberOfVolunteer = request.numberOfVolunteer;
        this.remark = request.remark;
        this.requester = new Requester(request.requester);
        this.requestStatus = request.requestStatus;
        this.service = new Service(request.service);
        this.staffComment = request.staffComment;
        this.startTime = new Date(request.startTime + 'Z');
        this.transportation = request.transportation ? new Transportation(request.transportation) : null;
        this.venue = new Address(request.venue);
        this.volunteers = request.volunteers.map(volunteer => new Volunteer(volunteer)).sort(VolunteerSorting);

        this.form = new FormGroup({
            actualStart: new FormControl(this.checkInTime ? new Date(this.checkInTime) : null, [Validators.required]),
            actualEnd: new FormControl(this.checkOutTime ? new Date(this.checkOutTime) : null, [Validators.required]),
            transportation: new FormControl(this.transportation?.uuId),
        });
        // this.editing = false;
        // this.remarkEdit=false;
    }

    get matchingOvertime(): boolean {
        var date = new Date();
        date.setDate(date.getDate() + 2);
        return this.requestStatus == 'Pending' && this.startTime < date;
    }

    get startOvertime(): boolean {
        return this.requestStatus == 'Ready' && this.startTime < new Date();
    }

    get endOvertime(): boolean {
        return this.requestStatus == 'Started' && this.endTime < new Date();
    }

    get isAccepted(): boolean {
        return this.volunteers.some(volunteer => volunteer.volunteerStatus == "Interested")
    }

    get numberOfMatchedVolunteers(): number {
        return this.volunteers.filter(volunteer => volunteer.volunteerStatus == "Confirmed").length;
    }

    get numberOfCheckedInVolunteers(): number {
        return this.volunteers.filter(volunteer => volunteer.volunteerStatus == "CheckedIn" || volunteer.volunteerStatus == "CheckedOut").length;
    }

    get latestCheckedInTime(): Date | null {
        let checkedInVolunteers = this.volunteers.filter(volunteer => volunteer.checkedInTime != null);

        if (checkedInVolunteers.length == 0)
            return null;

        return checkedInVolunteers
            .map(volunteer => volunteer.checkedInTime)
            .reduce((previous, current) => (previous as Date) > (current as Date) ? previous : current);
    }

    get actualStartForm(): AbstractControl {
        return this.form.controls['actualStart'];
    }

    get actualEndForm(): AbstractControl {
        return this.form.controls['actualEnd'];
    }

    get transportationForm(): AbstractControl {
        return this.form.controls['transportation'];
    }

    get remarkForm(): AbstractControl {
        return this.form.controls['remark'];
    }
}

function VolunteerSorting(a: Volunteer, b: Volunteer): number {
    return a.volunteerStatus == "Rejected" ? 1 :
        b.volunteerStatus == "Rejected" ? -1 :
            a.volunteerStatus == 'Asked' || a.volunteerStatus == 'Invited' ? 2 :
                b.volunteerStatus == 'Asked' || b.volunteerStatus == 'Invited' ? -2 :
                    a.volunteerStatus == 'Interested' ? 3 :
                        b.volunteerStatus == 'Interested' ? -3 :
                            a.volunteerStatus == 'Confirmed' ? 4 :
                                b.volunteerStatus == 'Confirmed' ? -4 :
                                    a.volunteerStatus == 'CheckedIn' ? 5 :
                                        b.volunteerStatus == 'CheckedIn' ? -5 :
                                            a.volunteerStatus == 'CheckedOut' ? 6 :
                                                b.volunteerStatus == 'CheckedOut' ? -6 : 0;

}
