<div class="tab-body">
    <div class="inline-flex-row align-items-center ml-4 text-lightgrey">
        <p translate>已選擇</p>
        <p class="text-lightblue ml-2">{{ selectedUsers.length }}</p>
        <p class="mr-2">/{{ users.length }}</p>
        <p translate>位用戶，確認完成後時分將會交易至帳戶</p>

        <input class="ml-8" type="checkbox" [(ngModel)]="changeAll">
        <p class="ml-4" translate>默認每個帳戶將會支出</p>
        <input class="user-input ml-4 mr-4" style="width: 4.3125rem;" type="number" [formControl]="form">
        <p translate>時分</p>

        <div class="flex-1"></div>

        <div class="inline-flex-row align-items-center" style="cursor: pointer;" routerLink="select-participants">
            <i class="add-icon material-icons mr-4">add_circle</i>
            <p class="text-grey" style="font-size: 1.125rem;line-height: 1.125rem;">新增用戶</p>
        </div>
    </div>

    <table class="user-table mt-8">
        <div class="user-table-row header">
            <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 4;">
                <input type="checkbox" [ngModel]="isSelectedAll" (click)="selectAll()">
            </div>
            <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                <p translate>會員編號</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>中文姓名</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>中心代碼</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                <p translate>性別</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                <p translate>年齡</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                <p translate>電話</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                <p translate>狀態</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>時分餘額</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                <p translate>交易時分</p>
            </div>
            <div class="user-table-cell" style="flex-basis: 13.1875rem;flex-grow: 19;">
                <p translate>操作</p>
            </div>
        </div>

        <cdk-virtual-scroll-viewport itemSize="36" style="height: 25rem;">
            <div class="user-table-row" *cdkVirtualFor="let user of users">
                <div class="user-table-cell" style="flex-basis: 2.75rem;flex-grow: 4;">
                    <input type="checkbox" [(ngModel)]="user.selected" *ngIf="user.state == 'Confirmed' && user.transaction == null">
                </div>
                <div class="user-table-cell" style="flex-basis: 8.25rem;flex-grow: 12;">
                    <p>{{ user.memberId }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{ user.chineseName }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{ user.center.centerCode }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                    <p>{{ user.gender | translate }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 4.125rem;flex-grow: 6;">
                    <p>{{ user.age }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                    <p>{{ user.phone }}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 6.875rem;flex-grow: 10;">
                    <p class="text-green" *ngIf="user.state == 'Confirmed' && user.transaction == null" translate>確認參加</p>
                    <p *ngIf="user.state == 'Confirmed' && user.transaction" translate>已完成</p>
                    <p class="text-red" *ngIf="user.state == 'Absent'">已缺席</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;">
                    <p>{{user.timeBalance}}</p>
                </div>
                <div class="user-table-cell" style="flex-basis: 5.5rem;flex-grow: 8;" [ngClass]="{editing: user.editing == true}">
                    <ng-container *ngIf="user.state == 'Confirmed' && user.transaction">
                        <p *ngIf="user.editing == false">{{ user.transaction.form.value }}</p>
                        <input *ngIf="user.editing == true" style="width: 100%;height: 100%;border: none;" type="number" [formControl]="user.transaction.form">
                    </ng-container>
                    <ng-container *ngIf="user.state == 'Confirmed' && user.transaction == null">
                        <p *ngIf="user.editing == false">{{ user.form.value }}</p>
                        <input *ngIf="user.editing == true" type="number" style="width: 100%;height: 100%;border: none;" [formControl]="user.form">
                    </ng-container>
                    <ng-container *ngIf="user.state == 'Absent'">
                        <p>-</p>
                    </ng-container>
                </div>
                <div class=" user-table-cell" style="flex-basis: 13.1875rem;flex-grow: 19;">
                    <p class="click-text-lightblue" *ngIf="user.editing == false" [ngClass]="{disabled: user.state == 'Absent'}" (click)="user.editing = true" translate>修改時分</p>
                    <p class="click-text-lightblue" *ngIf="user.editing == true" (click)="update(user)" translate>確認</p>
                    <p class="click-text-lightblue ml-8" *ngIf=" user.editing==false" (click)="remove(user.uuId)" translate>移除</p>
                    <p class="click-text-lightblue ml-8" *ngIf="user.editing == true" (click)="user.editing = false" translate>取消</p>
                    <p class="click-text-lightblue ml-8" (click)="absent(user.uuId)" translate>缺席</p>
                    <p class="click-text-lightblue ml-8" [routerLink]="['/main/user/overview', user.uuId]" translate>檢視</p>
                </div>
            </div>

            <div class="user-table-row" *ngIf="users.length == 0">
                <div class="user-table-cell">
                    <p style="color: #4A4A4A;" translate>暫無確認參加的用戶，請在接受邀請的用戶中選擇</p>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </table>
</div>

<div class="footer">
    <button class="button outline" (click)="closeAction()" translate>返 回</button>
    <button class="button fill blue" style="margin-left: 11.5rem;" (click)="submut()" translate>確認完成</button>
</div>