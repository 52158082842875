import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppSetting } from 'src/app/backend/app-setting';
import { PopupMessages } from 'src/app/backend/popup-message';
import { RequestListItem } from 'src/app/models/requests/request-list-item';
import { Service } from 'src/app/models/services/service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { RequestService } from 'src/app/services/request/request.service';

@Component({
  selector: 'app-request-trash-bin',
  templateUrl: './request-trash-bin.component.html',
  styleUrls: ['./request-trash-bin.component.scss']
})
export class RequestTrashBinComponent implements OnInit {

  remainDays: number = AppSetting.remainDays;

  requests: RequestListItem[] = [];
  caches: RequestListItem[] = [];
  count: number = 0;
  pages: number = 0;

  filters: any = {
    page: 1,
    itemsPerPage: 20,
    searchInput: null,
    services: [],
    districts: [],
    subDistricts: [],
    from: null,
    to: null,
    statuses: [],
    isDeleted: true,
    isUrgent: null,
    idOrder: true,
    dateOrder: null
  }

  constructor(
    private requestService: RequestService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.filters.page = parseInt(value.page) || 1;
      this.filters.itemsPerPage = parseInt(value.itemsPerPage) || 20;
      this.fetch();
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    this.requestService.getRequests(this.getQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.requests = value.list;
        this.count = value.count;
        this.pages = value.count != 0 ? Math.ceil(value.count / this.filters.itemsPerPage) : 0;
        this.requests.forEach(request => {
          if (this.caches.find(r => r.uuId == request.uuId))
            request.selected = true;
        });
      });
  }

  searchFilterAction: (input: string) => void = ((input: string) => {
    this.filters.searchInput = input;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  addressFilterAction = ((value: string[], subDistricts: string[] | any) => {
    this.filters.districts = value;
    this.filters.subDistricts = subDistricts;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  serviceFilterAction = ((value: Service[] | any) => {
    this.filters.services = value;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  dateFilterAction = ((start: Date, end: Date) => {
    this.filters.from = start;
    this.filters.to = end;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  statusFilterAction = ((statuses: string[], urgent: boolean) => {
    this.filters.statuses = statuses;
    this.filters.isUrgent = urgent;
    this.filters.page = 1;
    this.fetch();
  }).bind(this);

  changePage(): void {
    this.router.navigate(['/main/trash/request'], { queryParams: { page: this.filters.page, itemsPerPage: this.filters.itemsPerPage } })
  }

  restoreRequest(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.caches = [];
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.RestoreMessage(this.requestService.restoreRequests({ list: [uuId] })))
  }

  restoreRequests(): void {
    if (this.caches.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.NoRecordSelectedMessage);
      return;
    }

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.caches = [];
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.RestoreListMessage(this.requestService.restoreRequests({ list: this.caches.map(request => request.uuId) })));
  }

  deleteRequestPermanently(uuId: string): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeletePermanentlyMessage(this.requestService.deleteRequestsPermanently({ list: [uuId] })));
  }

  deleteRequestListPermanently(): void {
    if (this.caches.length == 0) {
      this.popupMessageService.messageSignal.emit(PopupMessages.NoRecordSelectedMessage);
      return;
    }

    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      this.caches = [];
      this.fetch();
      subscriber.unsubscribe();
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteListPermanentlyMessage(this.requestService.deleteRequestsPermanently({ list: this.caches.map(request => request.uuId) })));
  }

  deleteAllPermanently(): void {
    this.loadingService.startLoading();
    this.requestService.getRequests(this.getAllQueryString())
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
            this.caches = [];
            this.fetch();
            subscriber.unsubscribe();
          });

          this.popupMessageService.messageSignal.emit(PopupMessages.DeleteListPermanentlyMessage(this.requestService.deleteRequestsPermanently({ list: value.list.map(request => request.uuId) })));
        }
      });
  }

  cacheRequest(request: RequestListItem): void {
    if (request.selected)
      this.caches.push(request);
    else
      this.caches.splice(this.caches.findIndex(r => r.uuId == request.uuId), 1);
  }

  selectAll(): void {
    if (this.isSelectedAll) {
      this.requests.forEach(r => {
        r.selected = false;
        this.caches.splice(this.caches.findIndex(u => u.uuId == r.uuId), 1);
      });
    }
    else {
      this.caches = this.caches.concat(this.requests.filter(request => request.selected == false));
      this.requests.forEach(r => r.selected = true);
    }
  }

  get isSelectedAll(): boolean {
    return this.requests.every(request => request.selected == true);
  }

  private getQueryString(): string {
    return "?start=" + (this.filters.page - 1) * this.filters.itemsPerPage
      + "&limit=" + this.filters.itemsPerPage
      + (this.filters.searchInput ? "&filterString=" + this.filters.searchInput : "")
      + (this.filters.services.map((service: Service) => "&services=" + service.uuId).join(""))
      + (this.filters.districts.map((district: string) => "&districts=" + district).join(""))
      + (this.filters.subDistricts.map((subDistrict: string) => "&subDistricts=" + subDistrict).join(""))
      + (this.filters.statuses.map((s: string) => "&statuses=" + s).join(""))
      + (this.filters.from ? "&from=" + this.filters.from.toISOString() : "")
      + (this.filters.to ? "&to=" + this.filters.to.toISOString() : "")
      + (this.filters.idOrder != null ? "&orderById=" + String(this.filters.idOrder) : "")
      + (this.filters.dateOrder != null ? "&orderByDate=" + String(this.filters.dateOrder) : "")
      + (this.filters.isDeleted ? "&isDeleted=" + String(this.filters.isDeleted) : "")
      + (this.filters.isUrgent ? "&isUrgent=" + String(this.filters.isUrgent) : "");
  }

  private getAllQueryString(): string {
    return "?start=0&limit=10000&isDeleted=true";
  }
}
