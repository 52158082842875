import { Component, OnInit } from '@angular/core';
import {  FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { Request } from 'src/app/models/requests/request';
import { Transportation } from 'src/app/models/transportations/transportation';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { RequestService } from 'src/app/services/request/request.service';
import { TransportationService } from 'src/app/services/transportation/transportation.service';

@Component({
  selector: 'app-request-detail-page',
  templateUrl: './request-detail-page.component.html',
  styleUrls: ['./request-detail-page.component.scss']
})
export class RequestDetailPageComponent implements OnInit {

  request!: Request;
  transportations: Transportation[] = [];
  lang: string = this.translateService.currentLang;
  editing = false;
  remarkEdit=false;
  worker!: any;
  remark=new FormControl();


  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private transportationService: TransportationService,
    private router: Router,
    private popupMessageService: PopupMessageService
  ) { }

  ngOnInit(): void {
    this.fetch()
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });

    this.router.events.subscribe(() => {
      clearInterval(this.worker);
    })

    this.worker = setInterval(() => {
      if (this.request.requestStatus == 'Finished')
        return;
      this.refresh();
    }, 10000)
  }

  fetch(): void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading()
      forkJoin({
        request: this.requestService.getRequestByUUId(value.id),
        transportations: this.transportationService.getTransportationList()
      }).pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(value => {

          this.request = value.request;
          this.transportations = value.transportations;
          this.remark=new FormControl(this.request.remark);
        });
    })
  }

  refresh(): void {
    this.route.params.subscribe(value => {
      this.requestService.getRequestByUUId(value.id)
        .subscribe(value => {
          this.request = value;
        });
    })
  }

  approve(): void {
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.requestService.approveRequest(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => this.fetch());
    });
  }

  finish(): void {
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.requestService.finishRequest(value.id)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => this.fetch());
    });
  }


  reloadAction: () => void = (() => {
    this.fetch();
  }).bind(this)

  saveCancelDetail():void{
    this.route.params.subscribe(value => {
    this.loadingService.startLoading();
    this.requestService.cancelRequestReason(value.id, { CancelRequestReason: this.request.cancelRemark})
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(value => {
        this.popupMessageService.messageSignal.emit(PopupMessages.CancelRequestSuccessMessage);
        this.reloadAction()
      });
    });
  }
  
  updateRequest(): void {
    if (this.request.actualStartForm.value == null || this.request.actualStartForm.value == null) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidInformationMessage);
      return;
    }

    if (this.request.actualStartForm.value > this.request.actualEndForm.value) {
      this.popupMessageService.messageSignal.emit(PopupMessages.InvalidTimeMessage);
      return;
    }

    var cot = new Date(this.request.actualStartForm.value as Date);
    cot.setHours((this.request.actualEndForm.value as Date).getHours());
    cot.setMinutes((this.request.actualEndForm.value as Date).getMinutes());

    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      forkJoin({
        request: this.requestService.editCheckInCheckOutTime(value.id, {
          CheckInTime: (this.request.actualStartForm.value as Date).toJSON(),
          CheckOutTime: cot.toJSON()
        }),
        transportation: this.transportationService.editRequestTransportation(value.id, this.request.transportationForm.value)
      }).pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.editing=false;
          this.popupMessageService.messageSignal.emit(PopupMessages.EditRequestSuccessMessage);
          this.fetch();
        })
    })
  }
  updateRequestRemark():void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.requestService.editRemark(value.id,{Remark:this.remark.value})
          .pipe(finalize(() => this.loadingService.stopLoading()))
          .subscribe(() => {
            this.remarkEdit=false;
            this.popupMessageService.messageSignal.emit(PopupMessages.EditRequestSuccessMessage);
            this.fetch();
          })
    });
  }
  updateRequestTransportation(): void {
    this.route.params.subscribe(value => {
      this.loadingService.startLoading();
      this.transportationService.editRequestTransportation(value.id, this.request.transportationForm.value)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe(() => {
          this.editing=false;
          this.popupMessageService.messageSignal.emit(PopupMessages.EditRequestSuccessMessage);
          this.fetch();
        })
    })
  }
}
